// 中级工题库
const middle = [
    { "id": 1, "type": "判断题", "content": "催化裂化工艺由反应系统、再生系统、分馏系统、能量回收系统组成。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 2, "type": "判断题", "content": "对于同一套催化裂化装置，原料油喷嘴的好坏对装置生焦率没有影响。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 3, "type": "判断题", "content": "金属钝化剂的加注点，应尽量靠近提升管入口处的油浆管线上。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 4, "type": "判断题", "content": "流化床的气体和固体颗粒激烈运动，因为固体颗粒很小，所以气固之间的传质速率很高，从而提高了反应过程扩散步骤的速率。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 5, "type": "判断题", "content": "稀相输送是大量高速运动的气体把能量传递给固体颗粒，推动固体颗粒加速运动。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 6, "type": "判断题", "content": "稀相输送不但有稀、密相分界面，还存在气泡相和乳化相。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 7, "type": "判断题", "content": "密相输送时，固体颗粒是在少量气体流动的条件下，在松动的流化状态下进行集体运动，固体的移动是靠静压差来推动。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 8, "type": "判断题", "content": "提升管反应器属于稀相垂直输送类型，在噎塞速度附近操作，有利于反应正常进行。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 9, "type": "判断题", "content": "汽油产率越高，气体和焦炭产率越低，催化剂的选择性一定好。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 10, "type": "判断题", "content": "对于催化裂化装置，催化剂活性越高越好。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 11, "type": "判断题", "content": "磨损指数是新鲜催化剂的物理性质之一。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 12, "type": "判断题", "content": "新鲜催化剂的灼烧减量，是指催化剂经高温灼烧后跑损的水份以及挥发性盐分的量。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 13, "type": "判断题", "content": "催化裂化新鲜催化剂的物理组成分析包括：灼烧减量、Al2O3含量、Na2OH含量、SO2-4含量、Fe2O3含量和Re2O3含量。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 14, "type": "判断题", "content": "平衡催化剂的物理性质分析包括：Ni含量、Cu含量、V含量、Fe含量、炭含量。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 15, "type": "判断题", "content": "水碰到高温催化剂会迅速汽化，从而使催化剂崩裂的现象称为催化剂的热崩。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 16, "type": "判断题", "content": "催化剂Ni、V中毒会导致催化剂选择性变差、活性下降、干气产率上升、轻质油收率下降。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 17, "type": "判断题", "content": "硫转移剂主要是解决再生烟气SOX含量高的问题。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 18, "type": "判断题", "content": "渣油中的中性氮化物有喹啉、吡咯，极易吸附于酸性中心，使催化剂活性降低。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 19, "type": "判断题", "content": "反应速度最快的步骤对整个反应起着决定性作用，而成为控制因素。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 20, "type": "判断题", "content": "板式塔流体流动返混的型式，包括液体返混和气体返混两种。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 21, "type": "判断题", "content": "塔盘上液体返混属于液体返混型式。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 22, "type": "判断题", "content": "锅炉给水不纯，会造成炉管结垢和腐蚀，但不会造成汽水共腾。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 23, "type": "判断题", "content": "硬度对锅炉运行的影响表现在，水垢、水渣会堵塞炉管，恶化锅炉的水循环。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 24, "type": "判断题", "content": "炉水碱度过大，会恶化蒸汽品质，严重时会造成汽水共腾。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 25, "type": "判断题", "content": "炉水碱度过大，会造成锅炉结垢后出现局部过热，烧坏管子，有碍于安全运行。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 26, "type": "判断题", "content": "给水经过除氧后，PH值对腐蚀起显著作用，PH值小，腐蚀性大。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 27, "type": "判断题", "content": "为了缩短开工周期，开工时没有必要建立开路大循环。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 28, "type": "判断题", "content": "开工时建立开路大循环的目的是，发现泄漏部位及时处理，同时进一步调校仪表，考验其性能。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 29, "type": "判断题", "content": "开工时，顶循环回流充汽油的目的之一是将管道中的存水赶走。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 30, "type": "判断题", "content": "开工时，顶循环回流充汽油可以使顶回流泵上量快，尽快建立顶回流。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 31, "type": "判断题", "content": "开工时一中段回流管道充满轻柴油，可使一中段泵尽快上量，以平稳稳定塔操作。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 32, "type": "判断题", "content": "开工时用轻柴油充满一中段回流管道，可将管道中的冷凝水赶走，防止这部分水进入塔中，以防止水遇高温突沸，使反应压力骤升。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 33, "type": "判断题", "content": "装新鲜催化剂时，催化剂罐要冲压。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 34, "type": "判断题", "content": "主风机开机前，润滑油系统必须要试油压大小。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 35, "type": "判断题", "content": "提升管喷油过程中，要注意反应温度，若低于自保值，可将自保切手动。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 36, "type": "判断题", "content": "催化剂循环量与新鲜进料量之比，叫做剂油比。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 37, "type": "判断题", "content": "金属钝化剂热稳定性较差，使用时一般要求进料温度控制在250℃左右。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 38, "type": "判断题", "content": "金属钝化剂加入量越多越好。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 39, "type": "判断题", "content": "使用CO助燃剂后，氢转移性能加快，汽油烯烃含量增加，诱导期缩短。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 40, "type": "判断题", "content": "控制再生烟气氧含量的方法可以往主风管道通入氧气来实现。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 41, "type": "判断题", "content": "原料油性质和处理量变化不会引起汽油干点变化。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 42, "type": "判断题", "content": "解吸塔底温度过高，对干气质量没有影响。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 43, "type": "判断题", "content": "稳定塔顶气体热旁路调节幅度大，液态烃中C5含量会下降。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 44, "type": "判断题", "content": "吸收塔冲塔对反应压力没有影响。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 45, "type": "判断题", "content": "原料油性质变轻，会引起反应温度降低。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 46, "type": "判断题", "content": "干气带凝缩油会影响炉子燃烧，使炉子冒黑烟。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 47, "type": "判断题", "content": "带有干气膨胀机的装置，干气带凝缩油会打坏膨胀机叶轮。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 48, "type": "判断题", "content": "液态烃含C5较多时，如果作燃料用，会因C5不易气化造成瓦斯带油。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 49, "type": "判断题", "content": "单程转化率是指总进料一次通过反应器的转化率。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 50, "type": "判断题", "content": "总程转化率是指总进料一次通过反应器的转化率。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 51, "type": "判断题", "content": "返混是指不同时刻进入反应器的物料之间的混合，或者说是具有不同年龄的物料相互之间的混合。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 52, "type": "判断题", "content": "通入原料油的雾化蒸汽，可使油、气和催化剂混合均匀。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 53, "type": "判断题", "content": "原料油雾化蒸汽可以降低油、气分压，避免催化剂迅速结焦。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 54, "type": "判断题", "content": "当原料油中断时，雾化蒸汽可以防止喷嘴堵塞。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 55, "type": "判断题", "content": "催化剂汽提的作用是用水蒸汽将催化剂颗粒之间和颗粒空隙内充满的油气置换出来。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 56, "type": "判断题", "content": "汽提蒸汽可以减少焦炭产率。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 57, "type": "判断题", "content": "催化剂汽提后可以提高油品收率。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 58, "type": "判断题", "content": "催化剂汽提主要是将生成剂油比焦的油气置换出来。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 59, "type": "判断题", "content": "反应器喷汽油能降温的原因之一是汽油汽化成油气要吸热。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 60, "type": "判断题", "content": "反应器喷汽油能降温的原因之一是油气进行催化裂化反应要吸收大量的热。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 61, "type": "判断题", "content": "发生二次燃烧时，烟气中氧含量会突然下降，甚至降低到零。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 62, "type": "判断题", "content": "分馏系统操作，应掌握好全塔的压力和物料平衡，保持良好的分馏效果，避免相邻产品之间出现重叠。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 63, "type": "判断题", "content": "为了充分利用温位较高的热能，同时使分馏塔各段热负荷均匀，因此分馏塔采用较多循环回流。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 64, "type": "判断题", "content": "催化裂化分馏塔顶回流具有回收余热的作用。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 65, "type": "判断题", "content": "催化裂化分馏塔顶回流具有使塔中气液相负荷均匀的作用。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 66, "type": "判断题", "content": "催化裂化分馏塔二中段回流的作用主要是补充取热，以减少一中段回流的取热量，做到全塔取热平衡", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 67, "type": "判断题", "content": "催化裂化分馏塔的二中段物料抽出温度较低，可回收较低温位的能量。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 68, "type": "判断题", "content": "分馏塔底油浆循环量越大越好。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 69, "type": "判断题", "content": "增加反应深度，可以提高轻柴油凝固点。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 70, "type": "判断题", "content": "吸收过程是双向传质，而蒸馏过程是单向传质。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 71, "type": "判断题", "content": "因低压有利于解吸，故实际操作中解吸塔压力低于吸收塔压力。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 72, "type": "判断题", "content": "解吸过程与吸收过程正好相反。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 73, "type": "判断题", "content": "C3吸收率，通常用富气中C3的量减去干气中C3的量再除以富气中C3的量来表示。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 74, "type": "判断题", "content": "机泵切换至工频机泵时逐渐关小变频机泵出口阀门，变频机泵转速会逐渐减小。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 75, "type": "判断题", "content": "工频机泵切换至变频机泵时，应逐渐开大变频机泵出口阀门，使变频机泵转速逐渐增大。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 76, "type": "判断题", "content": "工频机泵出口阀开大，出口流量增加，但也有极限点。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 77, "type": "判断题", "content": "塔板上的非理想流动不利于传质过程的进行，影响传质效果，不能够维持塔的正常操作。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 78, "type": "判断题", "content": "日常操作时，大型加料与小型加料相比，可以降低催化剂损耗。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 79, "type": "判断题", "content": "变频机泵切换前不需要盘车。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 80, "type": "判断题", "content": "变频机泵之间切换，出口流量控制阀始终是全开。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 81, "type": "判断题", "content": "停工前，渣油催化裂化装置应将渣油切换成蜡油。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 82, "type": "判断题", "content": "停工稳定退油时，稳定塔回流罐应保存部分液态烃，以维持系统压力。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 83, "type": "判断题", "content": "氮气管线加盲板，应该加在靠近设备本体一侧。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 84, "type": "判断题", "content": "塔顶油气系统顶水时，分馏塔各重油系统不能向塔内扫线。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 85, "type": "判断题", "content": "停工时，外管道进行确认的目的是保证管道已经吹扫干净，防止检修用火发生事故。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 86, "type": "判断题", "content": "汽轮机是一种利用蒸汽带动的原动机。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 87, "type": "判断题", "content": "压缩机喘振是风机与管网彼此作用，发生流量与压力振荡循环而产生的。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 88, "type": "判断题", "content": "分馏塔底设置过滤网的原因是避免催化剂进入油浆泵。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 89, "type": "判断题", "content": "快速切断阀一般安装在机泵进出口位置，可以实现远程控制开关，在事故应急状态下，可不用靠近危险源关闭阀门切断系统。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 90, "type": "判断题", "content": "快速切断阀自动切手动时，将阀门上手轮旁的手动操作杆拉向手动位置，此时可以用手轮开关。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 91, "type": "判断题", "content": "一旦原料中断，进料雾化蒸汽可以防止喷嘴堵塞。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 92, "type": "判断题", "content": "在再生器密相床层内，安装盘管取热，叫内取热器。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 93, "type": "判断题", "content": "外取热器的取热量不可调节。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 94, "type": "判断题", "content": "变频调速的缺点之一是需配备技术较高的维修工人。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 95, "type": "判断题", "content": "变频调速的优点之一是投资小。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 96, "type": "判断题", "content": "翼阀的作用是为了保证料腿内有一定流速。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 97, "type": "判断题", "content": "料腿的作用是存放旋风器分离下来的催化剂粉末。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 98, "type": "判断题", "content": "快速切断阀只可全开或全关不做调节作用。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 99, "type": "判断题", "content": "快速切断阀达到所需要的全开或全关位置，阀门运行时会发出声音，可就声音判断阀门是否动作。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 100, "type": "判断题", "content": "蒸汽抽空器是由喷嘴、扩压室和混合室构成。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 101, "type": "判断题", "content": "限流孔板能降低流体的压力。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 102, "type": "判断题", "content": "汽轮机调速系统的作用是使输出功率与流量保持平衡。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 103, "type": "判断题", "content": "离心式气压机当气体流经叶轮时，气体获得速度。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 104, "type": "判断题", "content": "离心式气压机是依靠高速旋转的叶轮所产生的离心力来压缩气体的。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 105, "type": "判断题", "content": "压缩机喘振只与压缩机内部流动特性有关。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 106, "type": "判断题", "content": "空气通过空冷迎风面的速度简称为迎风速。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 107, "type": "判断题", "content": "按照工艺作用分，压力容器可以分为反应容器，再生容器，储存容器，换热容器和分离容器。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 108, "type": "判断题", "content": "盛装高温，强腐蚀及凝结性介质的容器，在压力表与容器之间应装有隔离缓冲装置。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 109, "type": "判断题", "content": "低压容器使用的压力表，精度应不低于2.5级。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 110, "type": "判断题", "content": "在用压力容器均应安装安全阀，但若压力来源于压力容器的外部且得到可靠控制时，可以不直接装在压力容器上。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 111, "type": "判断题", "content": "爆破膜主要由爆破片和夹持器组成，属于一种断裂型安全泄压装置。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 112, "type": "判断题", "content": "压力容器发生韧性破裂的原因，是容器本身材料或结构存在缺陷造成。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 113, "type": "判断题", "content": "压力容器，因物理原因引起容器内压力升高，使器壁强度不足而导致的破裂爆炸是属于物理性爆炸。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 114, "type": "判断题", "content": "按照《锅炉压力容器压力管道设备事故处理规定》中的事故分类办法，压力容器使用时，由于受压部件、安全附件损坏等导致设备停止运行而必须修理的，以及压力容器因泄漏而引起的火灾、人员中毒、设备遭到破坏的事故属于严重损坏事故。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 115, "type": "判断题", "content": "属于“容规”安全监察的压力容器，是指所有承受压力的密闭容器。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 116, "type": "判断题", "content": "压力容器的操作人员必须具有压力容器的基本知识，熟悉和掌握常见生产工艺及压力容器在生产工艺中的主要作用原理。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 117, "type": "判断题", "content": "压力容器是指所有承受压力载荷的密闭容器。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 118, "type": "判断题", "content": "润滑油温度过低，粘度太大是造成气压机振动过大的原因之一。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 119, "type": "判断题", "content": "快速切断阀日常检查维护时，要注意风压是否正常。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 120, "type": "判断题", "content": "快速切断阀有时会发生由于长时间关闭，前后压差比较大，难以开启。需要提高风压。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 121, "type": "判断题", "content": "沉降器压力过低不会引起催化剂带油。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 122, "type": "判断题", "content": "引起碳堆积的根本原因是生焦量小于烧焦量。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 123, "type": "判断题", "content": "压缩机喘振会引起逆流。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 124, "type": "判断题", "content": "压缩机喘振给生产操作带来很大的混乱。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 125, "type": "判断题", "content": "外界用汽量增加后，未能及时加强给水，会引起锅炉汽包缺水。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 126, "type": "判断题", "content": "机械密封安装不当，会引起机泵轴承温度超高。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 127, "type": "判断题", "content": "蒸汽窜入给水管道，锅炉在给水时就会产生水击。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 128, "type": "判断题", "content": "分馏塔底液面波动，会影响到塔上部热平衡和气液相负荷。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 129, "type": "判断题", "content": "生产过程中遇到的液泛以气速过大所造成的居多。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 130, "type": "判断题", "content": "水冲击会对汽轮机产生制动作用。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 131, "type": "判断题", "content": "润滑油温度过低，会引起压缩机运转振动过大。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 132, "type": "判断题", "content": "汽包给水流量不正常，如大于蒸汽流量是取热器发生漏水的现象之一。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 133, "type": "判断题", "content": "催化剂上含碳量不论多少，都有可能发生二次燃烧。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 134, "type": "判断题", "content": "因液面过低引起油浆泵不上量，可向分馏塔底补油。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 135, "type": "判断题", "content": "锅炉严重缺水时，应严禁向锅炉给水。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 136, "type": "判断题", "content": "发生碳堆积，采取一定措施后，若再生剂含碳量无下降趋势，则切断反应进料，进行两器流化烧焦。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 137, "type": "判断题", "content": "原料油少量带水，应控制好各参数，加强与调度和罐区联系。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 138, "type": "判断题", "content": "当催化剂带油时，应提高沉降器压力。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 139, "type": "判断题", "content": "在润滑油及冷却水均正常的情况下，机泵轴承温度超标应切换机泵，请钳工检查。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 140, "type": "判断题", "content": "在采取一定措施后，油浆固含量仍维持较高，应停工检查。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 141, "type": "判断题", "content": "催化裂化装置中最简便的反喘振方法是主风机出口放空。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 142, "type": "判断题", "content": "锅炉严重满水时，应立即停炉处理。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 143, "type": "判断题", "content": "再生器发生二次燃烧时，应尽量提高再生器密相温度到正常控制范围。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 144, "type": "判断题", "content": "冷却器一般都用E-×××来标识。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 145, "type": "判断题", "content": "摩尔百分比只适合气相混合物，液相混合物，固相混合物只能用质量百分比来表示。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 146, "type": "判断题", "content": "气体混合物的体积百分比就等于它的摩尔百分比。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 147, "type": "判断题", "content": "某催化裂化装置提升管不进行回炼，该装置回炼比为1。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 148, "type": "判断题", "content": "平均分子量是针对混合物来讲的。对单一物质是没有平均分子量的概念的。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 149, "type": "判断题", "content": "最小回流比计算可以用公式Rmin＝（xD－yδ）/（yδ－xδ）计算得到。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 150, "type": "判断题", "content": "在正常生产中，回流比的控制是优质、高产、低消耗的重要因素之一。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 151, "type": "判断题", "content": "精馏塔进料状态发生变化，不会引起理论塔板数的变化。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 152, "type": "单选题", "content": "快速切断阀自动切手动时，将阀门上手轮旁的手动操作杆拉向手动位置，此时快速切断阀风压为(　　)MPA改手轮操作。", "optionA": 0, "optionB": 0.04, "optionC": 0.1, "optionD": 0.4, "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 153, "type": "单选题", "content": "已知零件尺寸为50±0.008，零件公差应该是(　　)。", "optionA": 0.008, "optionB": 0, "optionC": 0.16, "optionD": 0.016, "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 154, "type": "单选题", "content": "开工稳定系统充瓦斯至管网压力后，应采样分析氧含量不大于(　　)。", "optionA": '1%', "optionB": '2%', "optionC": '3%', "optionD": '4%', "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 155, "type": "单选题", "content": "某催化裂化装置新鲜原料流量为100t/h，总回炼量为10t/h，回炼油浆流量为2t/h，外排油浆流量为6t/h，循环油浆流量为200t/h，该装置油浆收率为(　　)。", "optionA": '2%', "optionB": '3%', "optionC": '6%', "optionD": '8%', "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 156, "type": "单选题", "content": "快速切断阀手动切自动前，应先观察是否有足够气源，要求风压不小于(　　)MPa。若无风，则打开进口针型阀。", "optionA": 0.02, "optionB": 0.04, "optionC": 0.2, "optionD": 0.4, "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 157, "type": "单选题", "content": "某催化裂化装置新鲜原料流量为100t/h，回炼油流量为5t/h，回炼油浆流量为3t/h，分馏回炼污汽油流量为2t/h，该装置回炼比为(　　)。", "optionA": 0.02, "optionB": 0.05, "optionC": 0.08, "optionD": 0.1, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 158, "type": "单选题", "content": "某催化裂化装置提升管新鲜原料流量为100t/h，回炼油浆流量为5t/h，循环油浆为200t/h，该装置回炼比为(　　)。", "optionA": 0.05, "optionB": 0.1, "optionC": 0.5, "optionD": "缺少条件，无法计算", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 159, "type": "单选题", "content": "某催化裂化装置提升管总进料流量为110t/h，回炼油流量为8t/h，回炼油浆流量为2t/h，该装置回炼比为(　　)。", "optionA": 0.08, "optionB": 0.09, "optionC": 0.1, "optionD": "缺少条件，无法计算", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 160, "type": "单选题", "content": "保证汽油具有良好启动性能的是汽油的(　　)馏出温度。", "optionA": '10%', "optionB": '30%', "optionC": '50%', "optionD": '90%', "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 161, "type": "单选题", "content": "有一混合物由A、B两种物质组成，混合物中A摩尔分率为40％，则A的质量分率为(　　)。（A的分子量为80，B的分子量为30）", "optionA": '12%', "optionB": '32%', "optionC": '64%', "optionD": "缺少数据无法计算", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 162, "type": "单选题", "content": "某催化裂化装置提升管新鲜原料流量为100t/h，总回炼量为20t/h，轻柴油出装置环量为30t/h，该装置柴油收率为(　　)。", "optionA": '20%', "optionB": '25%', "optionC": '30%', "optionD": "缺少条件，无法计算", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 163, "type": "单选题", "content": "某催化裂化装置稳定塔进料流量为100t/h，液态烃流量为20t/h，塔顶回流量为40t/h，该塔回流比为(　　)。", "optionA": 0.2, "optionB": 0.4, "optionC": 0.5, "optionD": 2, "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 164, "type": "单选题", "content": "有一混合物由A、B两种物质组成，混合物中A质量分率为40％，则A的摩尔分率为(　　)。（A的分子量为80，B的分子量为30）", "optionA": '20%', "optionB": '40%', "optionC": '50%', "optionD": "缺少数据无法计算", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 165, "type": "单选题", "content": "有一混合物由A、B两种物质组成，120kg混合物中A有30kg，则B的质量分率为(　　)。", "optionA": "25.00%", "optionB": "33.33%", "optionC": 75, "optionD": "75.00%", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 166, "type": "单选题", "content": "某催化裂化装置新鲜原料流量为90t/h，总回炼量为10t/h，粗汽油流量为42t/h，稳定汽油出装置流量为45t/h，该装置汽油收率为(　　)。", "optionA": '42%', "optionB": '45%', "optionC": '47%', "optionD": '50%', "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 167, "type": "单选题", "content": "在设计中，适宜回流比选择了最小回流比的2倍，已知适宜回流比为4，则最小回流比是(　　)。", "optionA": 0.5, "optionB": 1, "optionC": 2, "optionD": 4, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 168, "type": "单选题", "content": "某催化裂化装置提升管新鲜原料流量为100t/h，总回炼量为20t/h，轻柴油收率为30％，汽油收率为45％，油浆不外排，该装置总转化率为(　　)。", "optionA": '55%', "optionB": '70%', "optionC": '75%', "optionD": "缺少条件，无法计算", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 169, "type": "单选题", "content": "某催化裂化装置提升管新鲜原料流量为100t/h，总回炼量为20t/h，轻柴油收率为30％，汽油收率为45％，油浆不外排，该装置单程转化率为(　　)。", "optionA": '55%', "optionB": '70%', "optionC": '75%', "optionD": "缺少条件，无法计算", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 170, "type": "单选题", "content": "直径小于或等于150mm管线的盲板厚度，按用火管理制度规定应不小于(　　)mm。", "optionA": 1, "optionB": 2, "optionC": 3, "optionD": 4, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 171, "type": "单选题", "content": "多管式三旋根据旋风管的安装方式分类，可分为(　　)。", "optionA": 1, "optionB": 2, "optionC": 3, "optionD": 4, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 172, "type": "单选题", "content": "一氧化碳助燃剂的加入量，通常为每吨藏量催化剂含(　　)千克助燃剂。", "optionA": 1, "optionB": '2～5', "optionC": 10, "optionD": '10～20', "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 173, "type": "单选题", "content": "膨胀节按其形状可以分为(　　)类。", "optionA": 2, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 174, "type": "单选题", "content": "按照工艺作用分，压力容器可以分为(　　)种。", "optionA": 2, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 175, "type": "单选题", "content": "按照使用位置分，压力容器可以分为(　　)种。", "optionA": 2, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 176, "type": "单选题", "content": "按照安全监察管理分，压力容器可以分为(　　)种。", "optionA": 2, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 177, "type": "单选题", "content": "按照设计压力分，压力容器可以分为(　　)种。", "optionA": 2, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 178, "type": "单选题", "content": "压力容器按照损坏程度可以分为(　　)类。", "optionA": 2, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 179, "type": "单选题", "content": "压力容器爆炸的性质分为(　　)类。", "optionA": 2, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 180, "type": "单选题", "content": "压力容器爆炸事故原因一般存在(　　)个方面。", "optionA": 2, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 181, "type": "单选题", "content": "相平衡关系虽然是在恒压下测定的，但试验表明，总压对平衡曲线的影响不大。若总压变化范围为20-30％，相平衡曲线变动不超过(　　)％。", "optionA": 2, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 182, "type": "单选题", "content": "压力容器的破裂失效按混合方式分类，可以分为(　　)种。", "optionA": 3, "optionB": 4, "optionC": 5, "optionD": 6, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 183, "type": "单选题", "content": "直径250－300mm的管线盲板厚度，按用火管理制度规定应不小于(　　)mm。", "optionA": 3, "optionB": 5, "optionC": 6, "optionD": 7, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 184, "type": "单选题", "content": "需要配置质量浓度为5％的磷酸三钠溶液100kg，需固体磷酸三钠（分子式为Na3PO4.12H2O）(　　)kg。", "optionA": 4.7, "optionB": 9.7, "optionC": 14.7, "optionD": "缺少条件，无法计算", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 185, "type": "单选题", "content": "停工时外管道顶水，顶水泵出口流量为10吨/时，在此电流情况下，若输送液化气应该是(　　)吨/时左右。", "optionA": 5, "optionB": 10, "optionC": 12, "optionD": 20, "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 186, "type": "单选题", "content": "需要配置质量浓度为5％的磷酸三钠溶液100kg，需磷酸三钠质量浓度为50％浓度的磷酸三钠(　　)kg。", "optionA": 5, "optionB": 10, "optionC": 50, "optionD": 100, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 187, "type": "单选题", "content": "直径大于400mm的管线盲板厚度，按用火管理制度规定应不小于(　　)mm。", "optionA": 6, "optionB": 7, "optionC": 9, "optionD": 10, "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 188, "type": "单选题", "content": "稳定塔进料量为10吨/时，塔顶回流量为90吨/时，产品抽出量为8吨/时，稳定塔回流比是(　　)。", "optionA": 8, "optionB": 9, "optionC": 11.25, "optionD": 12, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 189, "type": "单选题", "content": "某催化裂化装置提升管新鲜原料流量为100t/h，装置不回炼，催化剂循环量为800t/h，提升管催化剂藏量为8t，该装置剂油比为(　　)。", "optionA": 8, "optionB": 12.5, "optionC": 100, "optionD": "缺少条件，无法计算", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 190, "type": "单选题", "content": "某催化裂化装置新鲜原料流量为90t/h，总回炼量为10t/h，催化剂循环量为900t/h，提升管催化剂藏量为5t，该装置剂油比为(　　)。", "optionA": 9, "optionB": 10, "optionC": 18, "optionD": 20, "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 191, "type": "单选题", "content": "有一混合物，有A、B两种物质组成（已知：A的分子量为20，B的分子量为30），A在混合物中质量比是40％，混合物平均分子量为(　　)。", "optionA": 23, "optionB": 24, "optionC": 25, "optionD": 26, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 192, "type": "单选题", "content": "有一混合物，有A、B两种物质组成（已知：A的分子量为30，B的分子量为20），A在混合物中摩尔比是40％，混合物平均分子量为(　　)。", "optionA": 23, "optionB": 24, "optionC": 25, "optionD": 26, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 193, "type": "单选题", "content": "有一气相混合物，有A、B两种气相物质组成（已知：A的分子量为32，B的分子量为28），A在混合物中体积比是21％，混合物平均分子量为(　　)。", "optionA": 25.84, "optionB": 28.84, "optionC": 30, "optionD": 31.16, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 194, "type": "单选题", "content": "有一混合物由A、B两种物质组成，80kg混合物中A的摩尔分率为40％，则B的摩尔分率为(　　)。", "optionA": 40, "optionB": 50, "optionC": 60, "optionD": "缺少条件不能计算", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 195, "type": "单选题", "content": "有一混合物由A、B两种物质组成，100kg混合物中A的摩尔分率为40％，则B的质量分率为(　　)。", "optionA": 40, "optionB": 50, "optionC": 60, "optionD": "缺少条件无法计算", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 196, "type": "单选题", "content": "已知零件尺寸为50±0.008，零件最大极限尺寸应该是(　　)。", "optionA": 49.992, "optionB": 50.016, "optionC": 50.008, "optionD": 49.984, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 197, "type": "单选题", "content": "已知零件尺寸为50±0.008，零件最小极限尺寸应该是(　　)。", "optionA": 49.992, "optionB": 50.016, "optionC": 50.008, "optionD": 49.984, "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 198, "type": "单选题", "content": "辅助燃烧室炉温调节时，要求分布管前温度≯(　　)℃。", "optionA": 600, "optionB": 650, "optionC": 700, "optionD": 750, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 199, "type": "单选题", "content": "辅助燃烧室炉温调节时，要求炉膛温度≯(　　)℃。", "optionA": 800, "optionB": 850, "optionC": 900, "optionD": 950, "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 200, "type": "单选题", "content": "当新鲜催化剂中(　　)μm的细粉含量在（    ）％时，流化和再生性能好，气体夹带损失也不大。", "optionA": "＜40，20～30", "optionB": "＜40，15～20", "optionC": "＞40，20～30", "optionD": "＞40，15～20", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 201, "type": "单选题", "content": "当PH值(　　)时，水中硅盐大部都是SiO2（H2SiO3）。", "optionA": "≤8", "optionB": "＞8", "optionC": "≮11", "optionD": "＞11", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 202, "type": "单选题", "content": "在精馏塔的工艺设计中，回流比通常取最小回流比的(　　)倍。", "optionA": "1.1-2", "optionB": "2月4日", "optionC": "1.1-3", "optionD": "3月5日", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 203, "type": "单选题", "content": "平推流理想反应器模型返混参数是(　　)。", "optionA": "1～+∞", "optionB": "0～+∞", "optionC": "-∞～+∞", "optionD": 0, "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 204, "type": "单选题", "content": "外取热器汽包就地液位计应(　　)冲洗一次。", "optionA": "2小时", "optionB": "4小时", "optionC": "6小时", "optionD": "8小时", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 205, "type": "单选题", "content": "有一混合物由A、B、C三种物质组成，80kg混合物中A的摩尔分率为40％，C的质量分率为30％，还必须知道(　　)，就可以计算B的摩尔分率。", "optionA": "ABC的分子量", "optionB": "A的分子量，混合物的平均分子量", "optionC": "C的分子量，混合物的平均分子量", "optionD": "B的分子量，混合物的平均分子量", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 206, "type": "单选题", "content": "有一混合物由A、B两种物质组成，100kg混合物中A摩尔分率为30％，还需要知道(　　)就可以计算出A的质量分率。", "optionA": "A的分子量", "optionB": "B的分子量", "optionC": "混合物平均分子量", "optionD": "AB以及混合物分子量中任何两个", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 207, "type": "单选题", "content": "国外公司开发的进料喷嘴的类型有(　　)。", "optionA": "BX", "optionB": "BP", "optionC": "ATOMAX", "optionD": "KBZ", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 208, "type": "单选题", "content": "再吸收塔吸收剂量过小或温度高，会造成干气中带(　　)。", "optionA": "C2", "optionB": "C3", "optionC": "C4", "optionD": "C4以上", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 209, "type": "单选题", "content": "吸收塔吸收效果差，会引起干气中(　　)含量增加。", "optionA": "C2", "optionB": "C3", "optionC": "C4", "optionD": "C5", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 210, "type": "单选题", "content": "吸收塔吸收剂量太大，会造成液态烃中(　　)含量超标。", "optionA": "C2", "optionB": "C3", "optionC": "C4", "optionD": "C5", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 211, "type": "单选题", "content": "解吸塔底温度过低，解吸效果不好，会造成液态烃中(　　)含量超标。", "optionA": "C2", "optionB": "C3", "optionC": "C4", "optionD": "C5", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 212, "type": "单选题", "content": "增加气压机中间冷却器后，可使部分(　　)组分冷凝。", "optionA": "C2", "optionB": "C3", "optionC": "C4", "optionD": "C5", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 213, "type": "单选题", "content": "控制金属钝化剂的加入量可根据干气中的(　　)来确定。", "optionA": "CH4", "optionB": "H2", "optionC": "CO2", "optionD": "H2O", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 214, "type": "单选题", "content": "烟气中的水蒸气和(　　)一起对锅炉进行化学腐蚀，是产生锅炉露点腐蚀的原因。", "optionA": "CO2", "optionB": "SO2", "optionC": "H2", "optionD": "NH3", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 215, "type": "单选题", "content": "下列选项中，不属于常用绘图工具的是(　　)。", "optionA": "HB铅笔", "optionB": "圆规", "optionC": "直尺", "optionD": "圆珠笔", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 216, "type": "单选题", "content": "自动控制流程图中，表示控制的符号是(　　)。", "optionA": "L", "optionB": "C", "optionC": "R", "optionD": "I", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 217, "type": "单选题", "content": "自动控制流程图上，表示液位的符号是(　　)。", "optionA": "L", "optionB": "F", "optionC": "P", "optionD": "T", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 218, "type": "单选题", "content": "自动控制流程图中，表示流量的符号是(　　)。", "optionA": "L", "optionB": "F", "optionC": "Q", "optionD": "T", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 219, "type": "单选题", "content": "自动控制流程图中，表示指示的符号是(　　)。", "optionA": "L", "optionB": "R", "optionC": "T", "optionD": "I", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 220, "type": "单选题", "content": "DCS仪表中液位控制是(　　)表示的。", "optionA": "LC", "optionB": "LD", "optionC": "LPC", "optionD": "LPD", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 221, "type": "单选题", "content": "回流比为R，塔顶产品量为D，塔回流量为L，则R等于(　　)。", "optionA": "LD", "optionB": "L/D", "optionC": "D/L", "optionD": "1/LD", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 222, "type": "单选题", "content": "摩尔分率的单位是(　　)。", "optionA": "mol％mol", "optionB": "ppm", "optionC": "g％g", "optionD": "m3％m3", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 223, "type": "单选题", "content": "如果用N表示塔板效率，N1表示理论塔板数，N2表示实际塔板数，则N等于(　　)。", "optionA": "N1×N2", "optionB": "N2/N1", "optionC": "N1/N2", "optionD": "1/（N1×N2）", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 224, "type": "单选题", "content": "渣油的金属含量大大高于减压瓦斯油，其中(　　)的含量对催化剂影响较大。", "optionA": "Ni", "optionB": "S", "optionC": "N", "optionD": "Cu", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 225, "type": "单选题", "content": "DCS仪表符号中压差显示表示正确的是(　　)。", "optionA": "PDC×××", "optionB": "PDD×××", "optionC": "PD×××", "optionD": "PCD×××", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 226, "type": "单选题", "content": "硫转移剂的作用是将烟气中的硫化物（SOx）转移到反应器，转化为(　　)。", "optionA": "S", "optionB": "SO2", "optionC": "H2SO4", "optionD": "H2S", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 227, "type": "单选题", "content": "当PH值>11时，水中硅盐大部都是(　　)。", "optionA": "SiO2（H2SiO3）", "optionB": "HSiO3-", "optionC": "SiO3", "optionD": "Na2SiO3", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 228, "type": "单选题", "content": "高低并列式催化裂化，两器间催化剂的循环输送是采用(　　)输送。", "optionA": "U型管", "optionB": "直管", "optionC": "斜管", "optionD": "水平管", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 229, "type": "单选题", "content": "下列选项中，不属于电脑绘图工具软件的是(　　)。", "optionA": "word自带的绘图工具", "optionB": "Autocad", "optionC": "PowerPoint", "optionD": "画笔", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 230, "type": "单选题", "content": "下列选项中，不属于压力容器安全泄压装置的是(　　)。", "optionA": "安全阀", "optionB": "爆破膜", "optionC": "易熔塞", "optionD": "呼吸阀", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 231, "type": "单选题", "content": "气体返混型式有(　　)。", "optionA": "板上液体返混", "optionB": "漏液", "optionC": "雾沫夹带", "optionD": "气泡夹带", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 232, "type": "单选题", "content": "当危急保安器动作后，汽轮机调速系统应(　　)。", "optionA": "保证一定转速", "optionB": "保证主汽门关闭严密", "optionC": "保证主汽门不能关死", "optionD": "保持主汽门原来开度", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 233, "type": "单选题", "content": "临界喷嘴的作用是(　　)。", "optionA": "保证再生器一级旋风分离器分离效果", "optionB": "保证再生器二级旋风分离器分离效果", "optionC": "保证三级旋风分离器分离效果", "optionD": "保证四级旋风分离器分离效果", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 234, "type": "单选题", "content": "下列选项中，不会导致机泵电机温度偏高的原因是(　　)。", "optionA": "泵超负荷", "optionB": "润滑油不足", "optionC": "冷却水不足", "optionD": "电源电流超负荷", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 235, "type": "单选题", "content": "变频机泵之间切换，先开备用泵，打开备用泵出口阀门，同时内操将备泵机泵电流相应调大，主泵机泵电流相应调小，直至为0。然后关闭主泵出口，内操用(　　)控制流量。", "optionA": "泵出口流量控制阀", "optionB": "泵电机电流", "optionC": "泵电机电压", "optionD": "泵出口闸阀", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 236, "type": "单选题", "content": "选择吸收剂一般选用的是(　　)吸收剂。", "optionA": "比热大", "optionB": "相对密度较大", "optionC": "粘度大", "optionD": "密度与分子量比值较大", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 237, "type": "单选题", "content": "工频机泵切换至变频机泵时，先开变频泵，打开工频泵出口，逐渐关小工频泵出口，同时内操将变频机泵电流相应调大。然后关闭工频泵出口，内操将流量控制阀全开，用(　　)控制流量。", "optionA": "变频泵出口流量控制阀", "optionB": "变频泵电机电流", "optionC": "变频泵电机电压", "optionD": "变频泵出口闸阀", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 238, "type": "单选题", "content": "下列选项中，属于《压力容器安全技术监察规程》监察范围的换热器是(　　)。", "optionA": "波纹板式换热器", "optionB": "螺旋板式换热器", "optionC": "空冷", "optionD": "无壳体的套管换热器", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 239, "type": "单选题", "content": "安全阀与爆破膜相比，优点是(　　)。", "optionA": "不会中断生产", "optionB": "密封性好", "optionC": "灵敏度高", "optionD": "适用于黏性大、毒性大、腐蚀性强的介质", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 240, "type": "单选题", "content": "原料油管线里的流动状态为(　　)，以保证金属钝化剂和原料油混合均匀。", "optionA": "层流", "optionB": "过渡流", "optionC": "湍流", "optionD": "载流", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 241, "type": "单选题", "content": "板上液体返混是由于气流的搅动，使板上从塔板入口端流向出口端的液体主流有部分液体产生(　　)现象，从而影响传质效率。", "optionA": "层流", "optionB": "湍流", "optionC": "过渡流", "optionD": "倒流", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 242, "type": "单选题", "content": "危急保安器是防止汽轮机(　　)。", "optionA": "超压", "optionB": "超速", "optionC": "超电流", "optionD": "振动", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 243, "type": "单选题", "content": "提升管出口设置快速分离装置能减少(　　)的结焦。", "optionA": "沉降器", "optionB": "提升管", "optionC": "再生器", "optionD": "烧焦罐", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 244, "type": "单选题", "content": "催化剂装入量筒内经摇动后，待催化剂刚刚全部落下时，立即读取的体积所计算的密度，叫做(　　)。", "optionA": "充气密度", "optionB": "沉降密度", "optionC": "自由堆积密度", "optionD": "压紧密度", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 245, "type": "单选题", "content": "泵使用变频技术的目的是改变泵的(　　)以达到调节泵的流量。", "optionA": "出口阀开度", "optionB": "电机电压", "optionC": "叶轮尺寸", "optionD": "转速", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 246, "type": "单选题", "content": "下列选项中，属于压缩机喘振影响因素的是(　　)。", "optionA": "出口压力", "optionB": "进口压力", "optionC": "流量", "optionD": "管网特性", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 247, "type": "单选题", "content": "下列选项中，不属于班组经济核算内容的是(　　)。", "optionA": "处理量", "optionB": "成本", "optionC": "水电耗量", "optionD": "进料组成", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 248, "type": "单选题", "content": "油雾润滑系统是一种能够产生、(　　)、并自动为工业机械和设备中的轴承、齿轮箱等提供润滑油的集中润滑系统。", "optionA": "传输", "optionB": "回收", "optionC": "过滤", "optionD": "更新", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 249, "type": "单选题", "content": "如果振动频率与转速频率不同步，可以判断振动过大的原因是(　　)。", "optionA": "喘振", "optionB": "转子与定子碰撞", "optionC": "转子不平衡", "optionD": "产生油膜振荡", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 250, "type": "单选题", "content": "压缩机发生喘振，处理不当会造成(　　)。", "optionA": "催化剂跑损", "optionB": "催化剂带油", "optionC": "催化剂倒流", "optionD": "催化剂热崩", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 251, "type": "单选题", "content": "主风、烟气管线的特点是(　　)。", "optionA": "大压降、小流量", "optionB": "低压降、小流量", "optionC": "低压降、大流量", "optionD": "大压降、大流量", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 252, "type": "单选题", "content": "防止油膜振荡的方法，可以采用使轴的工作转速(　　)轴的临界转速来实现。", "optionA": "大于", "optionB": "等于", "optionC": "小于", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 253, "type": "单选题", "content": "如果气压机没有中间冷却器，容易造成(　　)。", "optionA": "带凝缩油", "optionB": "气体分解", "optionC": "降低出口温度", "optionD": "降低轴功率", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 254, "type": "单选题", "content": "停工时外管道顶水泵启动时，出口压力为1.0MPA打通流程以后，泵出口压力应(　　)。", "optionA": "等于1.0Mpa", "optionB": "大于1.0Mpa", "optionC": "小于1.0Mpa", "optionD": "不能确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 255, "type": "单选题", "content": "催化剂活性高，选择性(　　)。", "optionA": "低", "optionB": "高", "optionC": "一定好", "optionD": "不一定好", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 256, "type": "单选题", "content": "催化剂活性越高，转化率越(　　)。", "optionA": "低", "optionB": "中", "optionC": "高", "optionD": "不变", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 257, "type": "单选题", "content": "汽提塔液面控制(　　)，闪点会降低。", "optionA": "低", "optionB": "中间", "optionC": "高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 258, "type": "单选题", "content": "当主汽门完全开启时，调速系统应能维持汽轮机(　　)。", "optionA": "低负荷", "optionB": "满负荷", "optionC": "空负荷", "optionD": "超负荷", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 259, "type": "单选题", "content": "压缩机在(　　)情况下运行，易产生喘振。", "optionA": "低压缩比、高流量", "optionB": "低压缩比、低流量", "optionC": "高压缩比、高流量", "optionD": "高压缩比、低流量", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 260, "type": "单选题", "content": "对背压式汽轮机除常规保护装置，应还有(　　)保护装置。", "optionA": "低油压", "optionB": "轴向位移", "optionC": "背压安全阀", "optionD": "低真空", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 261, "type": "单选题", "content": "对凝气式汽轮机除常规保护装置，应还有(　　)保护装置。", "optionA": "低油压", "optionB": "轴向位移", "optionC": "背压安全阀", "optionD": "低真空", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 262, "type": "单选题", "content": "变频机泵电流调小，机泵(　　)。", "optionA": "电压减小", "optionB": "电压增大", "optionC": "转速变小", "optionD": "转速变大", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 263, "type": "单选题", "content": "主风、烟气系统通常采用(　　)控制流量和压力。", "optionA": "蝶阀", "optionB": "滑阀", "optionC": "球阀", "optionD": "闸阀", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 264, "type": "单选题", "content": "催化裂化分馏塔的(　　)具有调节粗汽油干点的任务。", "optionA": "顶回流", "optionB": "一中回流", "optionC": "二中回流", "optionD": "油浆回流", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 265, "type": "单选题", "content": "催化裂化分馏塔(　　)具有调节柴油凝固点的任务。", "optionA": "顶回流", "optionB": "一中回流", "optionC": "二中回流", "optionD": "油浆回流", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 266, "type": "单选题", "content": "催化裂化分馏塔(　　)可以取出大量高温位热量供回收利用。", "optionA": "顶回流", "optionB": "一中回流", "optionC": "二中回流", "optionD": "油浆回流", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 267, "type": "单选题", "content": "油雾润滑发生系统不包括(　　)。", "optionA": "动力供给系统", "optionB": "润滑油回收系统", "optionC": "操作控制系统", "optionD": "油雾发生器系统", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 268, "type": "单选题", "content": "气体获得动能是在轴流式主风机的(　　)。", "optionA": "动叶片上", "optionB": "静叶片上", "optionC": "整流器中", "optionD": "出口", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 269, "type": "单选题", "content": "气体获得压力能是在轴流式主风机的(　　)。", "optionA": "动叶片上", "optionB": "静叶片上", "optionC": "整流器中", "optionD": "出口", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 270, "type": "单选题", "content": "目前我国催化裂化大多采用(　　)三旋。", "optionA": "杜康型", "optionB": "布埃尔型", "optionC": "多管式", "optionD": "旋流式", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 271, "type": "单选题", "content": "下列选项中，对于疲劳破裂的断口形貌描述正确的是(　　)。", "optionA": "断口无金属光泽，呈深暗色，有腐蚀产物覆盖", "optionB": "断口为无金属光泽、暗灰色的纤维状", "optionC": "断口平齐，呈金属光泽的结晶状", "optionD": "断口有较明显的裂纹扩展区和最终断裂两个区", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 272, "type": "单选题", "content": "提升管出口设置快速分离装置是避免(　　)。", "optionA": "二次裂化反应", "optionB": "氢转移反应", "optionC": "二次反应", "optionD": "缩合反应", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 273, "type": "单选题", "content": "下列选项中，不属于催化裂化工艺组成部分的是(　　)。", "optionA": "反再系统", "optionB": "稳定系统", "optionC": "分馏系统", "optionD": "气压机组系统", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 274, "type": "单选题", "content": "分馏塔底设置过滤网的原因是(　　)。", "optionA": "防止油浆泵抽空", "optionB": "防止油浆结焦", "optionC": "防止油浆泵磨损", "optionD": "控制油浆固含量固含量", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 275, "type": "单选题", "content": "换热器扫线前，不扫的壳程或管程应(　　)。", "optionA": "放空放净", "optionB": "进出口阀关闭", "optionC": "进出口阀保持一定开度", "optionD": "放空打开", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 276, "type": "单选题", "content": "吸收是利用混合物中各组分在溶剂中的(　　)不同，而达到分离的目的。", "optionA": "沸点", "optionB": "饱和度", "optionC": "挥发度", "optionD": "溶解度", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 277, "type": "单选题", "content": "蒸馏是利用混合物中各组分的(　　)不同，而达到分离的目的。", "optionA": "沸点", "optionB": "饱和度", "optionC": "挥发度", "optionD": "溶解度", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 278, "type": "单选题", "content": "下列选项中，不属于分馏系统的是(　　)。", "optionA": "分馏塔", "optionB": "轻柴油汽提塔", "optionC": "再吸收塔", "optionD": "回炼油罐", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 279, "type": "单选题", "content": "分馏塔液面过高，会导致(　　)。", "optionA": "分馏塔压力增高", "optionB": "分馏塔压力下降", "optionC": "反应压力增高", "optionD": "反应压力下降", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 280, "type": "单选题", "content": "稳定塔回流比小，则(　　)。", "optionA": "分馏效果好，液态烃带轻组分。", "optionB": "分馏效果好，液态烃带重组分。", "optionC": "分馏效果差，液态烃带轻组分。", "optionD": "分馏效果差，液态烃带重组分。", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 281, "type": "单选题", "content": "下列选项中，由于封油造成泵抽空错误的原因是(　　)。", "optionA": "封油量小", "optionB": "封油量大", "optionC": "封油带水", "optionD": "封油组分轻", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 282, "type": "单选题", "content": "开工初期，两器烘衬里时，再生温度由(　　)控制。", "optionA": "辅助燃烧室", "optionB": "喷燃烧油", "optionC": "外取热器", "optionD": "掺渣比", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 283, "type": "单选题", "content": "两器装完催化剂进行流化升温时，再生温度由(　　)控制。", "optionA": "辅助燃烧室", "optionB": "喷燃烧油", "optionC": "外取热器", "optionD": "掺渣比", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 284, "type": "单选题", "content": "装置生产时，对于热量过剩的装置，再生温度由(　　)控制。", "optionA": "辅助燃烧室", "optionB": "喷燃烧油", "optionC": "外取热器", "optionD": "掺渣比", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 285, "type": "单选题", "content": "装置正常生产时，热平衡通过调节(　　)，来控制生焦率。", "optionA": "辅助燃烧室", "optionB": "喷燃烧油", "optionC": "外取热器", "optionD": "掺渣比", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 286, "type": "单选题", "content": "装置切断进料时，用(　　)控制再生温度不低于600℃。", "optionA": "辅助燃烧室", "optionB": "喷燃烧油", "optionC": "外取热器", "optionD": "掺渣比", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 287, "type": "单选题", "content": "盐类中的(　　)是造成锅炉结垢的主要成分，影响锅炉安全运行。", "optionA": "钙盐", "optionB": "镁盐", "optionC": "钠盐", "optionD": "铁盐", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 288, "type": "单选题", "content": "喉管式喷嘴的雾化机理是利用高速喷射的(　　)把液体冲击破碎，并使进料在进入提升管时形成强烈的紊流脉动的喷射流，并与周围介质发生碰撞打击而破碎。", "optionA": "干气", "optionB": "中压蒸汽", "optionC": "低压蒸汽", "optionD": "氮气", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 289, "type": "单选题", "content": "锅炉露点腐蚀产生的原因是烟气温度(　　)露点温度。", "optionA": "高于", "optionB": "等于", "optionC": "低于", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 290, "type": "单选题", "content": "变频机泵切换至工频机泵时，先开工频泵，缓慢打开工频泵出口，同时内操将变频机泵电流相应调小，直至为0。然后关闭变频泵出口，内操用(　　)控制流量。", "optionA": "工频泵出口流量控制阀", "optionB": "工频泵电机电流", "optionC": "工频泵电机电压", "optionD": "工频泵出口闸阀", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 291, "type": "单选题", "content": "催化剂堆积时，把微孔体积计算在内的密度叫做催化剂的(　　)。", "optionA": "骨架密度", "optionB": "颗粒密度", "optionC": "真实密度", "optionD": "堆积密度", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 292, "type": "单选题", "content": "固体颗粒脱离接触，但颗粒均匀分布，颗粒间充满流体，无颗粒与流体的集聚状态，此时已具有一些流体特性。这种反应床层叫(　　)。", "optionA": "鼓泡床", "optionB": "湍动床", "optionC": "快速床", "optionD": "散式流化床", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 293, "type": "单选题", "content": "整个床层中，颗粒形成悬浮状态的稀相，靠循环量也无法维持床层，已达到气力输送状态，称为(　　)。", "optionA": "鼓泡床", "optionB": "湍动床", "optionC": "输送床", "optionD": "快速床", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 294, "type": "单选题", "content": "吸收过程只包括被吸收组分自(　　)进入吸收剂的传质过程。", "optionA": "固体", "optionB": "液相", "optionC": "气相", "optionD": "气液混合", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 295, "type": "单选题", "content": "顶循环系统顶水时，应(　　)。", "optionA": "关闭分馏塔器壁阀，关闭进出口连通阀", "optionB": "关闭分馏塔器壁阀，打开进出口连通阀", "optionC": "打开分馏塔器壁阀，关闭进出口连通阀", "optionD": "打开分馏塔器壁阀，打开进出口连通阀", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 296, "type": "单选题", "content": "分馏塔轻柴油上抽出阀开度(　　)，轻柴油凝固点降低。", "optionA": "关小", "optionB": "不变", "optionC": "开大", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 297, "type": "单选题", "content": "分馏塔底循环油浆下返塔阀开度(　　)，会使塔底温度下降。", "optionA": "关小", "optionB": "不变", "optionC": "开大", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 298, "type": "单选题", "content": "停工扫线时，不准向(　　)通入含水的蒸汽。", "optionA": "管线", "optionB": "热油管线", "optionC": "容器", "optionD": "机泵", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 299, "type": "单选题", "content": "分子筛催化剂的选择性比无定形催化剂的选择性(　　)。", "optionA": "好", "optionB": "相当", "optionC": "差", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 300, "type": "单选题", "content": "X型催化剂的选择性比Y型催化剂的选择性(　　)。", "optionA": "好", "optionB": "相当", "optionC": "差", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 301, "type": "单选题", "content": "泵使用变频技术后，流量随转速下降而(　　)，动力消耗也相应降低，等于节省了泵出口阀及调节阀上的阻力降。", "optionA": "恒定", "optionB": "上升", "optionC": "减小", "optionD": "不变", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 302, "type": "单选题", "content": "背压式汽轮机本体结构组成中，后支架部分不包括(　　)。", "optionA": "后座架", "optionB": "径向轴承", "optionC": "推力轴承", "optionD": "后轴承座", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 303, "type": "单选题", "content": "发生二次燃烧时，再生器过渡段以上温度(　　)。", "optionA": "缓慢下降", "optionB": "明显下降", "optionC": "缓慢上升", "optionD": "明显上升", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 304, "type": "单选题", "content": "提升管出口设置快速分离装置能减少(　　)产率。", "optionA": "回炼油", "optionB": "轻柴油", "optionC": "油浆", "optionD": "干气", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 305, "type": "单选题", "content": "提升管出口设置快速分离装置的作用是使油气与催化剂(　　)。", "optionA": "混合均匀", "optionB": "快速分离", "optionC": "充分接触", "optionD": "充分反应", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 306, "type": "单选题", "content": "高速度区的气体流动具有相当程度的(　　)特性。", "optionA": "活塞流", "optionB": "非活塞流", "optionC": "湍流", "optionD": "高度湍流        （活塞流即为平推流）", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 307, "type": "单选题", "content": "将进料转化为目的产品的能力，称为催化剂的(　　)。", "optionA": "活性", "optionB": "稳定性", "optionC": "选择性", "optionD": "水热稳定性", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 308, "type": "单选题", "content": "下列选项中，能够加快催化剂反应速度性能是(　　)。", "optionA": "活性", "optionB": "选择性", "optionC": "稳定性", "optionD": "水热稳定性", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 309, "type": "单选题", "content": "只有当(　　)情况时，高位油箱才起作用。", "optionA": "机组停机", "optionB": "润滑油压力低", "optionC": "润滑油差压高", "optionD": "润滑油中断", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 310, "type": "单选题", "content": "空冷水洗时应注意(　　)水洗。", "optionA": "几个空冷同时", "optionB": "与系统一起", "optionC": "单独分片", "optionD": "高压", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 311, "type": "单选题", "content": "催化裂化反应的反应深度以(　　)表示。", "optionA": "剂油比", "optionB": "转化率", "optionC": "反应时间", "optionD": "空速", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 312, "type": "单选题", "content": "对于外取热器已出现漏水的取热管，应采取的措施是(　　)。", "optionA": "继续使用", "optionB": "停用外取热器", "optionC": "切除系统", "optionD": "更换", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 313, "type": "单选题", "content": "为防止油膜振荡，可以通过(　　)润滑油粘性系数。", "optionA": "减少", "optionB": "保持", "optionC": "增加", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 314, "type": "单选题", "content": "为防止油膜振荡，可以通过(　　)轴承静载荷，来(　　)轴承平均单位面积载荷。", "optionA": "减少", "optionB": "保持", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 315, "type": "单选题", "content": "通过(　　)油上、下压差，可以提高油膜稳定性，延缓油膜振荡发生。", "optionA": "减少", "optionB": "保持", "optionC": "增加", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 316, "type": "单选题", "content": "通过(　　)轴承宽度，来提高轴承平均单位面积载荷，从而防止油膜振荡。", "optionA": "减少", "optionB": "保持", "optionC": "增加", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 317, "type": "单选题", "content": "气压机中间冷却器的作用是(　　)压缩机的轴功率。", "optionA": "减少", "optionB": "保持", "optionC": "增加", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 318, "type": "单选题", "content": "汽提蒸汽量(　　)，轻柴油闪点下降。", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 319, "type": "单选题", "content": "汽提蒸汽量(　　)，轻柴油闪点上升。", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 320, "type": "单选题", "content": "分馏塔一中段回流返塔量(　　)，轻柴油凝固点降低。", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 321, "type": "单选题", "content": "分馏塔富吸收油返塔量(　　)，轻柴油凝固点降低。", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 322, "type": "单选题", "content": "分馏塔顶回流返塔流量(　　)，粗汽油干点降低。", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 323, "type": "单选题", "content": "分馏塔顶冷回流量(　　)，粗汽油干点降低。（与上题重复）", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 324, "type": "单选题", "content": "反应及分馏塔气量(　　)，粗汽油干点上升。", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 325, "type": "单选题", "content": "原料油处理量(　　)，会造成分馏塔底液面上升。", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 326, "type": "单选题", "content": "分馏塔底循环油浆热流(　　)，会使塔底温度下降。", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 327, "type": "单选题", "content": "分馏塔底油浆循环上返塔流量(　　)，会使塔底温度下降。", "optionA": "减少", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 328, "type": "单选题", "content": "流化床床层高度和空隙率随流速增大而(　　)。", "optionA": "减小", "optionB": "不变", "optionC": "增大", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 329, "type": "单选题", "content": "流化床床层压降随流速增大而(　　)。", "optionA": "减小", "optionB": "不变", "optionC": "增大", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 330, "type": "单选题", "content": "凝汽式汽轮机凝汽器运行中，端差(　　)说明传热系数下降。", "optionA": "减小", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 331, "type": "单选题", "content": "当锅炉轻微缺水时，下列操作错误的是(　　)。", "optionA": "减小锅炉负荷", "optionB": "开大排污", "optionC": "关闭排污", "optionD": "缓慢增加给水", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 332, "type": "单选题", "content": "气压机中间冷却器的作用是(　　)气压机出口温度。", "optionA": "降低", "optionB": "保持", "optionC": "升高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 333, "type": "单选题", "content": "分馏塔顶回流返塔温度下降，会造成汽油干点(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 334, "type": "单选题", "content": "分馏塔压力降低，会造成汽油干点(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 335, "type": "单选题", "content": "反应系统及分馏系统蒸汽量大，会造成汽油干点(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 336, "type": "单选题", "content": "反应深度增加，会引起柴油凝固点(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 337, "type": "单选题", "content": "汽提塔汽提蒸汽流量增加，会引起柴油闪点(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 338, "type": "单选题", "content": "汽提塔液面高，停留时间长，会引起柴油闪点(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 339, "type": "单选题", "content": "富吸收油返塔流量增加，会引起柴油凝固点(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 340, "type": "单选题", "content": "吸收塔中段回流小，而回流温度高，会引起干气中C3含量(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 341, "type": "单选题", "content": "稳定塔进料位置采用上进口，液态烃中C5含量会(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 342, "type": "单选题", "content": "提升管总进料量增加，会引起反应压力(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 343, "type": "单选题", "content": "原料油组分易裂解，会引起反应压力(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 344, "type": "单选题", "content": "反应汽提蒸汽量增加，会引起反应压力(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 345, "type": "单选题", "content": "主风量增大，会引起再生压力(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 346, "type": "单选题", "content": "烟机入口阀突然开大，会引起再生压力(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 347, "type": "单选题", "content": "烟机旁路阀突然开大，会引起再生压力(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 348, "type": "单选题", "content": "内、外取热器取热管破裂，会引起再生压力(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 349, "type": "单选题", "content": "原料油带水会导致反应温度(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 350, "type": "单选题", "content": "再生温度上升会导致反应温度(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 351, "type": "单选题", "content": "分馏塔一中段回流返塔温度(　　)，轻柴油凝固点降低。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 352, "type": "单选题", "content": "分馏塔顶回流返塔温度(　　)，粗汽油干点降低。", "optionA": "降低", "optionB": "不变", "optionC": "上升", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 353, "type": "单选题", "content": "使用CO助燃剂后，再生温度提高，再生催化剂含炭量(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "升高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 354, "type": "单选题", "content": "使用CO助燃剂后，在提升管出口温度不变的情况下，剂油比(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "升高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 355, "type": "单选题", "content": "分馏塔顶压力(　　)，粗汽油干点降低。", "optionA": "降低", "optionB": "不变", "optionC": "升高", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 356, "type": "单选题", "content": "分馏塔底温度(　　)，会造成塔底液面下降。", "optionA": "降低", "optionB": "不变", "optionC": "升高", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 357, "type": "单选题", "content": "分馏外排油浆量(　　)，会造成分馏塔底液面上升。", "optionA": "降低", "optionB": "不变", "optionC": "升高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 358, "type": "单选题", "content": "分馏油浆回炼量(　　)，会造成分馏塔底液面上升。", "optionA": "降低", "optionB": "不变", "optionC": "升高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 359, "type": "单选题", "content": "催化裂化分馏塔底油浆回流可以使塔中、上部的负荷(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 360, "type": "单选题", "content": "反应温度升高，可使汽油收率(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 361, "type": "单选题", "content": "催化剂活性低，会导致汽油收率(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 362, "type": "单选题", "content": "回炼比低，会导致汽油收率(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "增加", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 363, "type": "单选题", "content": "下列选项中，关于硫转移剂用途描述正确的是(　　)。", "optionA": "降低催化柴油中的S含量", "optionB": "降低催化油浆中的S含量", "optionC": "降低催化汽油中的S含量", "optionD": "降低催化烟气中的S含量", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 364, "type": "单选题", "content": "催化裂化分馏塔顶回流能(　　)。", "optionA": "降低分馏塔顶部负荷", "optionB": "降低分馏塔中部负荷", "optionC": "降低分馏塔底部负荷", "optionD": "降低分馏塔顶冷换设备热负荷", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 365, "type": "单选题", "content": "分馏塔底选用人字挡板的目的是(　　)。", "optionA": "降低压降", "optionB": "防止塔板堵塞", "optionC": "降低压降及防止塔板堵塞", "optionD": "防止结焦", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 366, "type": "单选题", "content": "开工原料油循环时，因塔底温度低，油浆泵不上量，应(　　)。", "optionA": "降低油浆循环量", "optionB": "提高油浆返塔温度", "optionC": "降低油浆下返塔量", "optionD": "提高原料预热温度", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 367, "type": "单选题", "content": "防止主风机喘振最简便的方法是(　　)。", "optionA": "降低再生压力", "optionB": "增加主风流量", "optionC": "打开主风机防喘振阀", "optionD": "停主风机", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 368, "type": "单选题", "content": "下列选项中，属于装置停工降温降量操作不正确的是(　　)。", "optionA": "降温降量要控制好速度", "optionB": "适当降主风量", "optionC": "控制好反应再生压力", "optionD": "分馏系统稳定，控制好液面，不需保证产品质量", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 369, "type": "单选题", "content": "烟气降压孔板的作用是(　　)。", "optionA": "降压", "optionB": "降量", "optionC": "降温", "optionD": "降速", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 370, "type": "单选题", "content": "催化剂重金属污染的明显特征是(　　)。", "optionA": "焦炭产率增大", "optionB": "液体产品产率下降", "optionC": "氢气产率增加", "optionD": "气体中C3、C4产率降低", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 371, "type": "单选题", "content": "对于滑动轴承，在高负荷下为了保证油膜的必要厚度，应选用粘度(　　)的润滑油。", "optionA": "较低", "optionB": "没有要求", "optionC": "较高", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 372, "type": "单选题", "content": "对于滑动轴承，在高转速下为了保证油膜的必要厚度，应选用粘度(　　)的润滑油。", "optionA": "较低", "optionB": "没有要求", "optionC": "较高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 373, "type": "单选题", "content": "当汽轮机进水时，必须(　　)。", "optionA": "紧急停机", "optionB": "观察使用", "optionC": "加强排凝", "optionD": "检查润滑油温度", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 374, "type": "单选题", "content": "催化剂装入量筒内，在桌上振动数次至体积不再变时，由读取的体积所计算的密度，叫做(　　)。", "optionA": "紧密堆积密度", "optionB": "自由堆积密度", "optionC": "疏松密度", "optionD": "沉降密度", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 375, "type": "单选题", "content": "凝汽式汽轮机凝汽器的过冷度指的是(　　)与凝结水温度之差。", "optionA": "进汽温度", "optionB": "排汽温度", "optionC": "循环水进口温度", "optionD": "循环水出口温度", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 376, "type": "单选题", "content": "大型机组人工控制润滑油温度的方法是控制(　　)。", "optionA": "进润滑点的阀门", "optionB": "出润滑点的阀门", "optionC": "润滑油泵出口阀", "optionD": "冷却水量", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 377, "type": "单选题", "content": "通过安装在(　　)的回油看窗，可以检查高位油箱是否充满。", "optionA": "进油管线", "optionB": "回油管线", "optionC": "油箱顶部", "optionD": "油箱底部", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 378, "type": "单选题", "content": "汽轮机是将(　　)。", "optionA": "静压能转化为动能", "optionB": "热能转化为动能", "optionC": "静压能转化为热能", "optionD": "热能转化为静压能", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 379, "type": "单选题", "content": "通过汽轮机电动脱扣装置可以实现(　　)紧急停车操作。", "optionA": "就地", "optionB": "就地控制盒", "optionC": "操作室遥控", "optionD": "操作室自动", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 380, "type": "单选题", "content": "渣油中的碱性氮化物有(　　)，极易吸附于酸性中心，使催化剂活性降低。", "optionA": "咔唑", "optionB": "吡咯", "optionC": "吸哚", "optionD": "喹啉", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 381, "type": "单选题", "content": "催化剂转剂时，沿途管线上的阀门应(　　)。", "optionA": "开1/3", "optionB": "开一半", "optionC": "开2/3", "optionD": "全开", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 382, "type": "单选题", "content": "离心泵通过泵体扫线时，应(　　)。", "optionA": "开泵冷却水", "optionB": "开泵封油", "optionC": "开泵预热线", "optionD": "开泵出口放空", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 383, "type": "单选题", "content": "当负荷增加时，汽轮机调速系统就(　　)主汽门。", "optionA": "开大", "optionB": "关小", "optionC": "全开", "optionD": "关闭", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 384, "type": "单选题", "content": "三旋转催化剂时，出口第一道阀门应该(　　)。", "optionA": "开一半", "optionB": "开2/3", "optionC": "全开", "optionD": "没有要求", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 385, "type": "单选题", "content": "稳定塔顶压力采用热旁路控制的特点是(　　)。", "optionA": "可选用较小的调节阀", "optionB": "调节滞后大", "optionC": "要求冷却面积小", "optionD": "冷却器标高比回流罐高", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 386, "type": "单选题", "content": "稳定塔顶压力采用卡脖子控制的特点是(　　)。", "optionA": "可选用较小的调节阀", "optionB": "调节滞后小", "optionC": "塔顶压降小", "optionD": "空冷器在回流罐下方", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 387, "type": "单选题", "content": "提升管喷油过程中，注意集合管压力平稳，开喷嘴时(　　)。", "optionA": "可逐渐缓慢打开", "optionB": "可以快速打开", "optionC": "根据集合管压力缓慢打开", "optionD": "听从内操指挥", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 388, "type": "单选题", "content": "空冷器的迎风面是指(　　)。", "optionA": "空冷管束外侧", "optionB": "空冷管束内侧", "optionC": "风机上面", "optionD": "风机下面", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 389, "type": "单选题", "content": "压力容器必备的五个附件是切断阀、放空阀、安全阀、(　　)和压力表。", "optionA": "控制阀", "optionB": "双金属温度计", "optionC": "液面计", "optionD": "8字盲板", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 390, "type": "单选题", "content": "部分再生时，防止二次燃烧的措施有(　　)。", "optionA": "控制较高的过剩氧，控制较高的再生温度", "optionB": "控制较高的过剩氧，控制较低的再生温度", "optionC": "控制较低的过剩氧，控制较高的再生温度", "optionD": "控制较低的过剩氧，控制较低的再生温度", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 391, "type": "单选题", "content": "下列选项中，属于催化裂化工艺组成部分的是(　　)。", "optionA": "冷换设备系统", "optionB": "取热系统", "optionC": "能量回收系统", "optionD": "计算机系统", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 392, "type": "单选题", "content": "冷却水出口虹吸作用降低，一般是由于(　　)造成。", "optionA": "冷却水压力低", "optionB": "冷却水温度高", "optionC": "管板堵塞", "optionD": "换热器漏", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 393, "type": "单选题", "content": "确定取热器漏管的方位是(　　)。", "optionA": "利用取热器藏量测点", "optionB": "利用取热器温度测点", "optionC": "观察给水量", "optionD": "观察蒸汽量", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 394, "type": "单选题", "content": "就地液面计是依据(　　)原理工作的。", "optionA": "连通器", "optionB": "压力传递", "optionC": "流体压差", "optionD": "流体能量方程", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 395, "type": "单选题", "content": "液化石油气中烷烃与烯烃之比，与(　　)反应有关。", "optionA": "裂化", "optionB": "热裂化", "optionC": "芳构化", "optionD": "氢转移", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 396, "type": "单选题", "content": "管内密度太大，气流已不足以支持固体颗粒，因而出现腾涌的最大气体速度叫做(　　)。", "optionA": "流化速度", "optionB": "起始气泡速度", "optionC": "最大流化速度", "optionD": "噎塞速度", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 397, "type": "单选题", "content": "DCS仪表符号中FC×××代表(　　)。", "optionA": "流量显示", "optionB": "压力显示", "optionC": "流量控制", "optionD": "压力控制", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 398, "type": "单选题", "content": "轴流压缩机调节特性是指(　　)这一特性曲线。", "optionA": "流速与进口压力", "optionB": "流速与出口压力", "optionC": "流量与进口压力", "optionD": "流量与出口压力", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 399, "type": "单选题", "content": "保证汽油在使用中不发生气阻的质量指标是(　　)。", "optionA": "馏程", "optionB": "诱导期", "optionC": "蒸汽压", "optionD": "胶质", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 400, "type": "单选题", "content": "下列选项中，不属于平衡催化剂物理性质的是(　　)。", "optionA": "密度", "optionB": "比表面积", "optionC": "孔体积", "optionD": "磨损指数", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 401, "type": "单选题", "content": "下列选项中，会造成机械密封突然性漏损的原因是(　　)。", "optionA": "密封面产生水垢", "optionB": "弹簧失去作用", "optionC": "防转销被切断", "optionD": "静环密封面变形", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 402, "type": "单选题", "content": "质量分率就是经常说的(　　)。", "optionA": "摩尔百分比", "optionB": "质量百分比", "optionC": "体积百分比", "optionD": "质量收率", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 403, "type": "单选题", "content": "催化剂从再生器引出，经过取热后再返回到再生器，这种结构型式称为(　　)。", "optionA": "内取热器", "optionB": "外取热器", "optionC": "过热盘管", "optionD": "蒸汽发生器", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 404, "type": "单选题", "content": "分馏系统操作主要任务，是在稳定状态下，把反应器过来的油气，按(　　)不同，分割成目的产品。", "optionA": "凝点", "optionB": "闪点", "optionC": "馏程", "optionD": "溶解度", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 405, "type": "单选题", "content": "下列选项中，对于润滑油选用最重要的指标是(　　)。", "optionA": "凝固点", "optionB": "机械杂质", "optionC": "粘度", "optionD": "抗乳化度", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 406, "type": "单选题", "content": "下列选项中，属于背压式汽轮机本体结构组成中汽缸部分的是(　　)。", "optionA": "排汽缸", "optionB": "蒸汽室", "optionC": "中间汽封", "optionD": "导叶持环", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 407, "type": "单选题", "content": "蒸汽抽空器是由(　　)构成。", "optionA": "喷嘴和扩压室", "optionB": "喷嘴和降压室", "optionC": "喷嘴、扩压室和混合室", "optionD": "喷嘴、降压室和混合室", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 408, "type": "单选题", "content": "原料油泵抽空首先应(　　)。", "optionA": "启用进料低流量自保", "optionB": "切换备泵", "optionC": "联系罐区或提高原料油罐液面", "optionD": "提其它原料、提回炼油（浆）量，反应降量", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 409, "type": "单选题", "content": "机泵启动未上量，主要是(　　)原因。", "optionA": "气缚", "optionB": "汽蚀", "optionC": "气蚀", "optionD": "汽缚", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 410, "type": "单选题", "content": "流化状态的固体颗粒是靠推动力以克服流动过程中所产生的各种阻力，来达到的(　　)的目的。", "optionA": "气力输送", "optionB": "稀相输送", "optionC": "密相输送", "optionD": "机械输送", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 411, "type": "单选题", "content": "转化率等于(　　)的产率之和。", "optionA": "气体＋汽油＋柴油", "optionB": "气体＋汽油＋回炼油", "optionC": "气体＋汽油＋液化气", "optionD": "气体＋汽油＋焦炭", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 412, "type": "单选题", "content": "稀相输送的一项重要指标是(　　)。", "optionA": "气体速度", "optionB": "平均密度", "optionC": "固气比", "optionD": "压力降", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 413, "type": "单选题", "content": "使用CO助燃剂后，减少了CO对(　　)的污染。", "optionA": "汽油", "optionB": "柴油", "optionC": "催化剂", "optionD": "大气", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 414, "type": "单选题", "content": "金属钝化剂的注入点，应在所有加热器之(　　)，防止金属钝化剂的分解。", "optionA": "前", "optionB": "后", "optionC": "前后都可以", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 415, "type": "单选题", "content": "金属钝化剂的加注点，一般要求选择在原料油进提升管之(　　)，并有足够长的混合管。", "optionA": "前", "optionB": "后", "optionC": "前后都可以", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 416, "type": "单选题", "content": "增加气压机中间冷却器后，可减少冷却器(　　)各段的重量流率。", "optionA": "前面", "optionB": "中间", "optionC": "后面", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 417, "type": "单选题", "content": "锅炉电化学腐蚀产生的原因是给水中有(　　)存在。", "optionA": "氢气", "optionB": "氮气", "optionC": "氧气", "optionD": "悬浮物", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 418, "type": "单选题", "content": "再生烟气氧含量的控制方法，一般通过调节(　　)来控制。", "optionA": "取热量", "optionB": "掺渣比", "optionC": "再生压力", "optionD": "主风量", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 419, "type": "单选题", "content": "气控式外取热器的流化风和提升风旁的动力风，正常状态应该是(　　)。", "optionA": "全开", "optionB": "全关", "optionC": "稍开", "optionD": "没有要求", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 420, "type": "单选题", "content": "催化裂化分馏塔是(　　)进料，温度较高，而出塔产品温度较低，所以整塔要靠回流取走大量剩余热量。", "optionA": "全液相过热", "optionB": "气液混合", "optionC": "全气相饱和", "optionD": "全气相过热", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 421, "type": "单选题", "content": "当前工程上，压力容器断裂事故主要有三种型式，不含以下的(　　)。", "optionA": "韧性破裂", "optionB": "脆性破裂", "optionC": "疲劳破裂", "optionD": "应力腐蚀破裂", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 422, "type": "单选题", "content": "破裂有以下两个原因造成：一是容器的问题；二是容器制造或使用中的问题。此种破裂称为(　　)。", "optionA": "韧性破裂", "optionB": "脆性破裂", "optionC": "疲劳破裂", "optionD": "应力腐蚀破裂", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 423, "type": "单选题", "content": "汽轮机水冲击主要对汽轮机的(　　)产生危害。", "optionA": "入口管线", "optionB": "主汽门", "optionC": "调速器", "optionD": "叶片", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 424, "type": "单选题", "content": "外取热器汽包液面正常时，由(　　)控制。", "optionA": "三冲量", "optionB": "连续排污", "optionC": "定期排污", "optionD": "紧急放水阀", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 425, "type": "单选题", "content": "气速增大到一定限度时，由于气泡的不稳定性，使气泡产生更多的小气泡，导致床层内循环加剧，气泡分布较前更为均匀，床层由气泡引起的压力波动也较小，表面夹带颗粒量大增，这种床层称为(　　)。", "optionA": "散式流化床", "optionB": "输送床", "optionC": "湍动床", "optionD": "密相气力输送", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 426, "type": "单选题", "content": "催化裂化分馏塔底油浆回流，采用(　　)方式可以把油气中的催化剂颗粒冲洗下来，以免堵塞塔盘。", "optionA": "上进口", "optionB": "中进口", "optionC": "下进口", "optionD": "没有要求", "answer": "A ", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 427, "type": "单选题", "content": "轻油系统水洗时，应注意水必须(　　)。", "optionA": "上进上出", "optionB": "上进下出", "optionC": "下进下出", "optionD": "下进上出", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 428, "type": "单选题", "content": "发生二次燃烧时，烟气中氧含量会(　　)。", "optionA": "上升", "optionB": "不变", "optionC": "下降", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 429, "type": "单选题", "content": "泵使用变频技术后，由于压力、转速、功率电压和频率(　　)，机泵寿命将大大延长，维修费用及工作量将减少。", "optionA": "上升", "optionB": "降低", "optionC": "不变", "optionD": "突变", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 430, "type": "单选题", "content": "干气带凝缩油会使液态烃收率(　　)。", "optionA": "上升", "optionB": "降低", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 431, "type": "单选题", "content": "液态烃含C5较多时，如果作化工原料用，会使目的产品（C3、C4）的收率(　　)。", "optionA": "上升", "optionB": "降低", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 432, "type": "单选题", "content": "液态烃C5含量高会使汽油收率(　　)。", "optionA": "上升", "optionB": "降低", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 433, "type": "单选题", "content": "分馏塔脱过热段由于温度较高，同时又有催化剂粉尘，所以一般采用(　　)。", "optionA": "舌形塔盘", "optionB": "筛孔塔盘", "optionC": "浮阀塔盘", "optionD": "人字挡板", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 434, "type": "单选题", "content": "再生器采用分布管时，一级料腿底部可(　　)。", "optionA": "设置翼阀", "optionB": "设置防倒锥", "optionC": "设置翼阀或防倒锥", "optionD": "不用设置任何设施", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 435, "type": "单选题", "content": "对某一静叶角度下，流量与出口压力特性曲线随流量减小，出口压力则(　　)。", "optionA": "升高", "optionB": "不变", "optionC": "先升后降", "optionD": "先降后升", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 436, "type": "单选题", "content": "当汽轮机由全负荷突然降到空负荷时，调速系统应能(　　)。", "optionA": "使汽轮机停车", "optionB": "启动危急保安器", "optionC": "在超速情况下降低转速", "optionD": "控制转速在危急保安器动作转速以下", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 437, "type": "单选题", "content": "因操作引起锅炉内水冲击时，应(　　)。", "optionA": "适当降低锅炉水位", "optionB": "适当提高锅炉水位", "optionC": "开大连排", "optionD": "增加加药量", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 438, "type": "单选题", "content": "催化剂装入量筒内经摇动后，静止2分钟，由读取的体积所计算的密度，叫做(　　)。", "optionA": "疏松密度", "optionB": "紧密堆积密度", "optionC": "沉降密度", "optionD": "压紧密度", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 439, "type": "单选题", "content": "固体粒子处于堆紧状态，且颗粒静止不动的床层称为(　　)。", "optionA": "输送床", "optionB": "快速床", "optionC": "固定床", "optionD": "密相床", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 440, "type": "单选题", "content": "当装置内有烟气膨胀透平时，(　　)应有降压孔板。", "optionA": "双动滑阀前", "optionB": "双动滑阀与余热锅炉之间", "optionC": "烟机进口", "optionD": "余热锅炉后", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 441, "type": "单选题", "content": "使用CO助燃剂后，提高了操作稳定性，实现了CO在再生器的完全燃烧，避免了(　　)。", "optionA": "炭堆", "optionB": "二次燃烧", "optionC": "返混", "optionD": "偏流", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 442, "type": "单选题", "content": "催化裂化新鲜催化剂的物理性质分析有(　　)。", "optionA": "炭含量", "optionB": "粒度分布", "optionC": "磨损指数", "optionD": "灼烧减量", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 443, "type": "单选题", "content": "因油浆组分变轻，油浆泵不上量，应(　　)。", "optionA": "提高分馏塔下部温度", "optionB": "提高油浆循环量", "optionC": "提高油浆下返塔量", "optionD": "提高外排油浆量", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 444, "type": "单选题", "content": "因富气组分变轻，引起气压机飞动，应采取(　　)。", "optionA": "提高冷后温度，提高再吸收塔压力", "optionB": "提高冷后温度，降低再吸收塔压力", "optionC": "降低冷后温度，提高再吸收塔压力", "optionD": "降低冷后温度，降低再吸收塔压力", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 445, "type": "单选题", "content": "因液面低，油浆泵不上量，应(　　)。", "optionA": "提高油浆返塔温度", "optionB": "降低油浆返塔温度", "optionC": "降油浆循环量", "optionD": "提高外排油浆量", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 446, "type": "单选题", "content": "主风、烟气管线上的调节阀，要求(　　)。", "optionA": "调节速度慢", "optionB": "压降低", "optionC": "无泄漏", "optionD": "压降大", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 447, "type": "单选题", "content": "当锅炉水位看不到水位线时，应首先(　　)。", "optionA": "停止给水", "optionB": "判断是满水还是缺水", "optionC": "紧急停炉", "optionD": "增加给水", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 448, "type": "单选题", "content": "气控式外取热器壳体上的流化风，正常状态是(　　)。", "optionA": "投用限流孔板", "optionB": "投用旁路", "optionC": "正、副线都投用", "optionD": "全关", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 449, "type": "单选题", "content": "分馏塔人字挡板的作用是(　　)。", "optionA": "脱除过热", "optionB": "传质传热", "optionC": "洗涤催化剂粉末", "optionD": "脱除过热及洗涤催化剂粉末", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 450, "type": "单选题", "content": "吸收剂中选择性最好的烃类是(　　)。", "optionA": "烷烃", "optionB": "烯烃", "optionC": "芳烃", "optionD": "环烷烃", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 451, "type": "单选题", "content": "对于有浮环密封的气压机还有(　　)保护装置。", "optionA": "危急保安", "optionB": "背压安全阀", "optionC": "电机脱扣", "optionD": "油气压差控制", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 452, "type": "单选题", "content": "烟气轮机的级数是根据烟气的(　　)来确定的。", "optionA": "温度", "optionB": "效率", "optionC": "焓降", "optionD": "功率", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 453, "type": "单选题", "content": "当汽液两相达到平衡时，气液两相中的各组分不随(　　)发生变化。", "optionA": "温度", "optionB": "压力", "optionC": "时间", "optionD": "进料量", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 454, "type": "单选题", "content": "下列选项中，由电机引起泵抽空的原因是(　　)。", "optionA": "温度高", "optionB": "转向不对", "optionC": "振动大", "optionD": "电流大", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 455, "type": "单选题", "content": "泵使用变频技术后，实质上是改变泵的特性曲线而没有改变(　　)曲线。", "optionA": "温度特性", "optionB": "管路特性", "optionC": "电流特性", "optionD": "功率特性", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 456, "type": "单选题", "content": "DCS仪表符号中TC×××代表(　　)。", "optionA": "温度显示", "optionB": "压力控制", "optionC": "流量控制", "optionD": "温度控制", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 457, "type": "单选题", "content": "DCS仪表符号中PD×××代表(　　)。", "optionA": "温度显示", "optionB": "压力显示", "optionC": "流量控制", "optionD": "压力控制", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 458, "type": "单选题", "content": "凝汽式汽轮机凝汽器运行情况好坏，反应在(　　)。", "optionA": "温升", "optionB": "端差", "optionC": "真空", "optionD": "过冷度", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 459, "type": "单选题", "content": "在催化裂化反应过程中，产品分子沿催化剂微孔到催化剂表面的过程，称为(　　)过程。", "optionA": "吸附", "optionB": "脱附", "optionC": "反向外扩散", "optionD": "反向内扩散", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 460, "type": "单选题", "content": "在催化裂化反应过程中，原料分子由主气流到催化剂表面的过程，称为(　　)过程。", "optionA": "吸附", "optionB": "脱附", "optionC": "外扩散", "optionD": "内扩散", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 461, "type": "单选题", "content": "在催化裂化反应过程中，原料分子到催化剂内表面的过程，称为(　　)过程。", "optionA": "吸附", "optionB": "脱附", "optionC": "外扩散", "optionD": "内扩散", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 462, "type": "单选题", "content": "在催化裂化反应过程中，产品分子自催化剂内表面脱离的过程，称为(　　)过程。", "optionA": "吸附", "optionB": "脱附", "optionC": "外扩散", "optionD": "内扩散", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 463, "type": "单选题", "content": "催化裂化装置(　　)塔顶部进料，相当于侧线产品的提馏段。", "optionA": "吸收塔", "optionB": "稳定", "optionC": "分馏塔", "optionD": "汽提塔", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 464, "type": "单选题", "content": "催化裂化装置(　　)是底部进料。", "optionA": "吸收塔", "optionB": "稳定塔", "optionC": "分馏塔", "optionD": "汽提塔", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 465, "type": "单选题", "content": "(　　)底通入过热蒸汽，在温度不变以及总压力一定时，降低油气分压，增加汽化率，从而降低产品中轻组分的含量。", "optionA": "吸收塔", "optionB": "稳定塔", "optionC": "分馏塔", "optionD": "汽提塔", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 466, "type": "单选题", "content": "分馏塔采用(　　)可以使塔顶冷却面积减少，降低压降，并回收部分低温位热量。", "optionA": "吸收油流程", "optionB": "冷回流", "optionC": "顶循环回流", "optionD": "一中段回流", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 467, "type": "单选题", "content": "在相同的原料转化率和温度下，原料油中硫含量上升，将会引起汽油辛烷值(　　)。", "optionA": "下降", "optionB": "上升", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 468, "type": "单选题", "content": "停工停气压机后，稳定退油的关键是(　　)。", "optionA": "先将液化气退尽", "optionB": "维持系统压力", "optionC": "各塔容器保持低液位", "optionD": "保证产品质量合格", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 469, "type": "单选题", "content": "限流孔板的作用是(　　)。", "optionA": "限制流体的流量", "optionB": "限制流体的流速", "optionC": "增加流体的流量", "optionD": "增加流体的流速", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 470, "type": "单选题", "content": "管内固体质量速度底或管径越大，噎塞速度就越(　　)。", "optionA": "小", "optionB": "不变", "optionC": "大", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 471, "type": "单选题", "content": "为了保证机泵在高温下有良好的润滑状态，润滑油的粘度比越(　　)越好。", "optionA": "小", "optionB": "没有要求", "optionC": "大", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 472, "type": "单选题", "content": "提升管喷油过程中，要注意再生器压力(　　)沉降器压力。", "optionA": "小于", "optionB": "等于", "optionC": "大于", "optionD": "没有要求", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 473, "type": "单选题", "content": "水平管密相输送时，只有在固体颗粒保持悬浮状态和固体流速摩擦损失(　　)压力降条件下才能实现。", "optionA": "小于", "optionB": "等于", "optionC": "大于", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 474, "type": "单选题", "content": "当给水的PH值(　　)7时，能形成较稳定的保护膜，使腐蚀缓慢下来。", "optionA": "小于", "optionB": "等于", "optionC": "大于", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 475, "type": "单选题", "content": "高位油箱的作用是当润滑油系统和机组同时停车时，保证高位油箱供油时间(　　)机组的惰走时间。", "optionA": "小于", "optionB": "等于", "optionC": "大于", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 476, "type": "单选题", "content": "当给水的PH值(　　)时，水中游离CO2不存在，碳酸全部形成HCO3-，这样游离CO2腐蚀可以大大减少。", "optionA": "小于4.3", "optionB": "大于4.3", "optionC": "小于8.3", "optionD": "大于8.3", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 477, "type": "单选题", "content": "烟气轮机为反动式透平，与冲动式透平相比，该式(　　)。", "optionA": "效率高、气体速度低", "optionB": "效率高、气体速度高", "optionC": "效率低、气体速度低", "optionD": "效率低、气体速度高", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 478, "type": "单选题", "content": "下列选项中，属于《压力容器安全技术监察规程》监察范围的压力容器是(　　)。", "optionA": "新鲜催化剂罐", "optionB": "催化裂化余热锅炉", "optionC": "高压氮气气瓶", "optionD": "催化裂化气压机气缸", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 479, "type": "单选题", "content": "剂油比是催化剂循环量与(　　)之比。", "optionA": "新鲜进料量", "optionB": "总进料量", "optionC": "回炼油量", "optionD": "回炼油浆量", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 480, "type": "单选题", "content": "当料腿内的催化剂静压超过(　　)，翼阀自行打开。", "optionA": "旋风器的压降", "optionB": "翼阀上方床层静压", "optionC": "打开翼阀所需压力", "optionD": "旋风器的压降、翼阀上方床层静压、打开翼阀所需压力三者之和", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 481, "type": "单选题", "content": "管路特性曲线是管路系统的(　　)与流量的关系曲线。", "optionA": "压力", "optionB": "阻力", "optionC": "流速", "optionD": "压降", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 482, "type": "单选题", "content": "为了防止再生器超压，可以对(　　)设定为超驰控制。", "optionA": "烟机进口蝶阀", "optionB": "双动滑阀", "optionC": "再生单动滑阀", "optionD": "烟机旁路蝶阀", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 483, "type": "单选题", "content": "提升管喷油过程中，再生压力由(　　)控制。", "optionA": "烟机进口蝶阀", "optionB": "烟机旁路蝶阀", "optionC": "双动滑阀", "optionD": "烟机进口高温闸阀", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 484, "type": "单选题", "content": "主风机开机前，烟气系统准备状态是(　　)。", "optionA": "烟机进口蝶阀打开", "optionB": "烟机出口防空阀关闭", "optionC": "烟机出口水封罐已撤", "optionD": "烟机冷却蒸汽投用", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 485, "type": "单选题", "content": "烟气轮机动叶片中部截面处的圆周速度与下列因素无关的是(　　)。", "optionA": "烟机转速", "optionB": "轮盘直径", "optionC": "叶片高度", "optionD": "叶片厚度", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 486, "type": "单选题", "content": "硫转移剂的作用是将(　　)中的硫化物转化为H2S。", "optionA": "烟气", "optionB": "干气", "optionC": "柴油", "optionD": "汽油", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 487, "type": "单选题", "content": "下列选项中，产生二次燃烧的条件是(　　)。", "optionA": "烟气中氧含量过高、无一氧化碳", "optionB": "烟气中氧含量过低、无一氧化碳", "optionC": "烟气中氧含量过高、有一氧化碳", "optionD": "烟气中氧含量过低、有一氧化碳", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 488, "type": "单选题", "content": "油雾发生器利用(　　)作为动力源以生产（1-3微米）的小油滴。产生的小油滴悬浮在空气中通过分配管道网络，到达机泵要求润滑的部位。", "optionA": "液化气", "optionB": "汽油", "optionC": "压缩空气", "optionD": "氮气", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 489, "type": "单选题", "content": "C3吸收率，通常用富气中C3的量减去(　　)中C3的量再除以富气中C3的量来表示。", "optionA": "液态烃", "optionB": "压缩富气", "optionC": "干气", "optionD": "贫气", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 490, "type": "单选题", "content": "辅助燃烧室正常燃烧时，火焰呈浅蓝色，如(　　)，火焰发亮短促。", "optionA": "一次风过大", "optionB": "一次风过小", "optionC": "二次风过大", "optionD": "二次风过小", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 491, "type": "单选题", "content": "辅助燃烧室正常燃烧时，火焰呈浅蓝色，如(　　)，火焰发暗，长而飘摇不定。", "optionA": "一次风过大", "optionB": "一次风过小", "optionC": "二次风过大", "optionD": "二次风过小", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 492, "type": "单选题", "content": "料腿中能维持的料位高度与旋风器的压降及(　　)有关。", "optionA": "翼板与水平线的夹角", "optionB": "翼板与垂直线的夹角", "optionC": "料腿直径", "optionD": "料腿长度", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 493, "type": "单选题", "content": "再生器采用分布板时，一级料腿底部应设置(　　)。", "optionA": "翼阀", "optionB": "防倒锥", "optionC": "翼阀或防倒锥", "optionD": "不用设置", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 494, "type": "单选题", "content": "原料油泵抽空后，切换机泵前，(　　)。", "optionA": "应将流量控制阀改手动关小", "optionB": "应将流量控制阀改手动", "optionC": "流量控制阀仍放自动", "optionD": "应将流量控制阀改副线", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 495, "type": "单选题", "content": "当(　　)过大，会产生晶间腐蚀，即苛性脆化，对锅炉安全运行危害较大。", "optionA": "硬度", "optionB": "碱度", "optionC": "PH值", "optionD": "含盐量", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 496, "type": "单选题", "content": "下列选项中，不会导致低油雾密度报警的原因是(　　)。", "optionA": "油雾储罐液位低", "optionB": "油吸入网堵", "optionC": "油雾压力低", "optionD": "油温低", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 497, "type": "单选题", "content": "车用汽油的抗爆性用(　　)来表示。", "optionA": "诱导期", "optionB": "蒸汽压", "optionC": "辛烷值", "optionD": "品质度", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 498, "type": "单选题", "content": "金属钝化剂注入点应尽量靠近(　　)管线上。", "optionA": "原料油泵", "optionB": "换热器", "optionC": "提升管入口处", "optionD": "原料控制阀", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 499, "type": "单选题", "content": "催化裂化装置金属钝化剂的加注点，一般选择在(　　)。", "optionA": "原料油泵出口", "optionB": "换热器进口", "optionC": "原料油集合管", "optionD": "原料油混合器前", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 500, "type": "单选题", "content": "渣油中的氢含量是影响原料油裂化性质的一个重要因素，氢含量越低，转化率(　　)。", "optionA": "越低", "optionB": "不变", "optionC": "越高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 501, "type": "单选题", "content": "吸收剂应具有较好的稳定性，不易产生热分解而生成(　　)。", "optionA": "杂质", "optionB": "胶质", "optionC": "硫化物", "optionD": "焦炭", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 502, "type": "单选题", "content": "在提升管催化裂化装置，如烟机停用时，再生压力由(　　)控制。", "optionA": "再生单动滑阀", "optionB": "待生塞阀", "optionC": "双动滑阀", "optionD": "烟机进口蝶阀", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 503, "type": "单选题", "content": "对再生温度而言，(　　)温度高能促使二次燃烧。", "optionA": "再生密相或再生稀相", "optionB": "再生稀相或再生器出口管线", "optionC": "再生密相或再生器出口管线", "optionD": "再生密相、稀相或再生器出口管线", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 504, "type": "单选题", "content": "再生旋风器二级料腿应伸至(　　)。", "optionA": "再生器密相床层", "optionB": "再生器密相", "optionC": "再生器稀相", "optionD": "再生器底部", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 505, "type": "单选题", "content": "内取热器一般安装在(　　)。", "optionA": "再生器密相床内", "optionB": "再生器稀相床内", "optionC": "沉降器密相床内", "optionD": "沉降器稀相床内", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 506, "type": "单选题", "content": "再生器取热器发生漏水时，再生器密度变化是(　　)。", "optionA": "再生器稀相密度下降", "optionB": "再生器密相上部密度下降", "optionC": "再生器密相中部密度下降", "optionD": "再生器密相下部密度下降", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 507, "type": "单选题", "content": "临界喷嘴是用来控制(　　)。", "optionA": "再生器烟气流量", "optionB": "三旋料腿的下流烟气量", "optionC": "四旋料腿的下流烟气量", "optionD": "四旋料腿的下流催化剂量", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 508, "type": "单选题", "content": "再生器取热器发生漏水的现象是(　　)。", "optionA": "再生压力上升", "optionB": "旋风分离器压力降下降", "optionC": "再生器密相床以上温度上升", "optionD": "烟囱排烟颜色变化", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 509, "type": "单选题", "content": "下列选项中，引起气压机飞动不常见的原因是(　　)。", "optionA": "再吸收塔压力控制阀失灵", "optionB": "反飞动控制阀失灵", "optionC": "富气组分变重", "optionD": "调速器失灵", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 510, "type": "单选题", "content": "采用炉内加药水处理的锅炉，发生汽水共沸时，应(　　)。", "optionA": "暂停加药", "optionB": "正常加药", "optionC": "减小加药", "optionD": "增加加药", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 511, "type": "单选题", "content": "随塔盘出口溢流堰高度增加，将使气体穿越塔板的阻力(　　)，雾沫夹带(　　)。", "optionA": "增大，增加", "optionB": "减小，减少", "optionC": "减小，增加", "optionD": "增大，减小", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 512, "type": "单选题", "content": "随塔内气速的(　　)，塔盘上气液两相混合程度(　　)，两相接触时间变短，塔板分离下降。", "optionA": "增大，增强", "optionB": "减小，减弱", "optionC": "减小，增强", "optionD": "增大，减弱", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 513, "type": "单选题", "content": "沸石晶胞尺寸降低，液化石油气中的烯烃与烷烃之比(　　)。", "optionA": "增加", "optionB": "降低", "optionC": "一样", "optionD": "不能确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 514, "type": "单选题", "content": "由于返混，气体产生了与其主流方向相反的流动，使传质推动力（）。", "optionA": "增加", "optionB": "下降", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 515, "type": "单选题", "content": "与水或蒸汽接触的润滑部位用油，要求具有良好的(　　)。", "optionA": "粘度", "optionB": "抗乳化度", "optionC": "酸值", "optionD": "抗氧安定性", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 516, "type": "单选题", "content": "柴油的燃烧性能用(　　)作为衡量指标。", "optionA": "粘度", "optionB": "闪点", "optionC": "十六烷值", "optionD": "凝点", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 517, "type": "单选题", "content": "反映柴油低温流动性能的指标是(　　)。", "optionA": "粘度", "optionB": "闪点", "optionC": "十六烷值", "optionD": "凝点", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 518, "type": "单选题", "content": "柴油的使用储存安全性用(　　)表示。", "optionA": "粘度", "optionB": "闪点", "optionC": "十六烷值", "optionD": "凝点", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 519, "type": "单选题", "content": "保证柴油的供油量、雾化状态、燃烧情况和高压油泵的重要指标是(　　)。", "optionA": "粘度", "optionB": "闪点", "optionC": "十六烷值", "optionD": "凝固点", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 520, "type": "单选题", "content": "在同高并列催化裂化装置，再生压力由双动滑阀控制两器差压为(　　)。", "optionA": "正差压", "optionB": "负差压", "optionC": 0, "optionD": "没有要求", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 521, "type": "单选题", "content": "催化裂化催化剂的小型加料属于(　　)输送。", "optionA": "直管", "optionB": "水平管", "optionC": "压送式", "optionD": "真空吸入式", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 522, "type": "单选题", "content": "从催化剂料斗往催化剂罐输送催化剂属于(　　)输送。", "optionA": "直管", "optionB": "水平管", "optionC": "压送式", "optionD": "真空吸入式", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 523, "type": "单选题", "content": "带控制点的工艺流程图中，仪表风线用(　　)表示。", "optionA": "中实线", "optionB": "粗实线", "optionC": "细实线", "optionD": "虚线", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 524, "type": "单选题", "content": "由于泵转子轴向窜动，动环来不及补偿位移或操作不稳而引起的机械密封泄漏，属于(　　)。", "optionA": "周期性漏损", "optionB": "经常性漏损", "optionC": "突然性漏损", "optionD": "停用后启动漏损", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 525, "type": "单选题", "content": "由于强烈抽空使密封破坏，引起的机械密封泄漏属于(　　)。", "optionA": "周期性漏损", "optionB": "经常性漏损", "optionC": "突然性漏损", "optionD": "停用后启动漏损", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 526, "type": "单选题", "content": "由于摩擦副密封环面处结焦，引起的机械密封泄漏属于(　　)。", "optionA": "周期性漏损", "optionB": "经常性漏损", "optionC": "突然性漏损", "optionD": "停用后启动漏损", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 527, "type": "单选题", "content": "电机绝缘不良会造成(　　)。", "optionA": "轴承温度高", "optionB": "电机温度高", "optionC": "机泵振动大", "optionD": "压力波动大", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 528, "type": "单选题", "content": "润滑油变质、乳化会造成(　　)。", "optionA": "轴承温度高", "optionB": "电机温度高", "optionC": "密封泄漏", "optionD": "压力波动大", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 529, "type": "单选题", "content": "悬臂支撑结构的烟气轮机进气、排气方式为(　　)。", "optionA": "轴向进气、轴向排气", "optionB": "轴向进气、径向排气", "optionC": "径向进气、径向排气", "optionD": "径向进气、轴向排气", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 530, "type": "单选题", "content": "两端支撑结构的烟气轮机进气、排气方式为(　　)。", "optionA": "轴向进气、轴向排气", "optionB": "轴向进气、径向排气", "optionC": "径向进气、径向排气", "optionD": "径向进气、轴向排气", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 531, "type": "单选题", "content": "确定取热器漏管的方法是(　　)。", "optionA": "逐个关闭取热管进出口阀", "optionB": "逐个关闭取热管出口阀", "optionC": "逐个关闭取热管进口阀", "optionD": "减小进水流量", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 532, "type": "单选题", "content": "翼阀的作用是防止(　　)。", "optionA": "主风倒窜", "optionB": "催化剂倒窜", "optionC": "料腿结焦", "optionD": "料腿堵塞", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 533, "type": "单选题", "content": "降压孔板安装位置在(　　)。", "optionA": "主风机进口", "optionB": "主风机出口", "optionC": "余热锅炉前", "optionD": "余热锅炉后", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 534, "type": "单选题", "content": "下列选项中，不会引起高油雾压力报警的是(　　)。", "optionA": "主油雾发生器单独运行", "optionB": "油雾分配器堵", "optionC": "油雾管道堵", "optionD": "空气压力偏离设定值", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 535, "type": "单选题", "content": "剂油比增大说明(　　)。", "optionA": "转化率提高，焦炭产率升高", "optionB": "转化率提高，焦炭产率降低", "optionC": "转化率降低，焦炭产率升高", "optionD": "转化率降低，焦炭产率降低", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 536, "type": "单选题", "content": "停工时，液态烃外管道顶水结束标志是(　　)。", "optionA": "装置边界放空没有液化气出来", "optionB": "液化气罐区有水出来", "optionC": "液化气罐区脱法兰放水没有液化气出来", "optionD": "液化气罐放水没有液化气出来", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 537, "type": "单选题", "content": "单程转化率是指(　　)一次通过反应器的转化率。", "optionA": "总进料", "optionB": "新鲜进料", "optionC": "回炼油", "optionD": "回炼油浆", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 538, "type": "单选题", "content": "总程转化率是指(　　)一次通过反应器的转化率。", "optionA": "总进料", "optionB": "新鲜进料", "optionC": "回炼油", "optionD": "回炼油浆", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 539, "type": "单选题", "content": "根据《压力容器安全技术监察规程》规定，固定式压力容器上只装一个安全阀时，开启压力不应大于该容器的(　　)。", "optionA": "最大工作压力", "optionB": "设计压力", "optionC": "最大允许工作压力", "optionD": "设计压力的1.05倍", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 540, "type": "单选题", "content": "液面计的(　　)应有明显标记。", "optionA": "最低和最高液位", "optionB": "最高液位", "optionC": "最低液位", "optionD": "液体引出管口", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 541, "type": "多选题", "content": "最小回流比为3，在设计中适宜回流比可以选取的数值正确的是(　　)。", "optionA": 2.5, "optionB": 3, "optionC": 4, "optionD": 5, "answer": "CD", "answercode": 11, "state": 0, "marked": 0, "checked": 0 },
    { "id": 542, "type": "多选题", "content": "钙盐中主要有(　　)，它们的存在将会引起蒸汽品质恶化，导致腐蚀锅炉金属。", "optionA": "Ca（HCO3）2 ", "optionB": "CACL2 ", "optionC": "CaSO4 ", "optionD": "CaSiO3", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 543, "type": "多选题", "content": "铁盐中主要有(　　)，这些铁化合物会沉积在锅炉中成为水垢，引起腐蚀。", "optionA": "Fe2O3", "optionB": "Fe3O4", "optionC": "Fe（HCO3）3", "optionD": "FeSO4", "answer": "CD", "answercode": 11, "state": 0, "marked": 0, "checked": 0 },
    { "id": 544, "type": "多选题", "content": "碱度的大小反映了水中(　　)离子含量的多少。", "optionA": "HCO3-", "optionB": "H-", "optionC": "O2-", "optionD": "OH-", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 545, "type": "多选题", "content": "国内开发的进料喷嘴的类型有(　　)。", "optionA": "LPC", "optionB": "KH", "optionC": "BWJ", "optionD": "BP", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 546, "type": "多选题", "content": "镁盐中主要有(　　)，它们的存在将会形成水垢，恶化蒸汽品质。", "optionA": "MgCl2 ", "optionB": "Mg（OH）2 ", "optionC": "MgSO4 ", "optionD": "MgO", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 547, "type": "多选题", "content": "钠盐中主要有(　　)，它们的存在会使锅炉金属发生苛性脆化的危险。", "optionA": "NaCL", "optionB": "NaOH", "optionC": "Na2SO4 ", "optionD": "NaHCO3", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 548, "type": "多选题", "content": "锅炉发生汽水共沸时，应对锅炉进行换水，以降低锅炉水的(　　)。", "optionA": "PH值", "optionB": "碱度", "optionC": "含盐量", "optionD": "硬度", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 549, "type": "多选题", "content": "液体返混型式有(　　)。", "optionA": "板上液体返混", "optionB": "漏液", "optionC": "雾沫夹带", "optionD": "气泡夹带", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 550, "type": "多选题", "content": "板式塔返混的型式主要有(　　)。", "optionA": "板上液体返混", "optionB": "漏液", "optionC": "雾沫夹带", "optionD": "气泡夹带", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 551, "type": "多选题", "content": "停工时，外管道进行确认的目的是(　　)。", "optionA": "保证管道用火的安全", "optionB": "放尽顶水后管道内存水", "optionC": "确保盲板安装位置正确", "optionD": "保证管道内氧含量", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 552, "type": "多选题", "content": "噎塞速度主要取决于催化剂的(　　)。", "optionA": "比表面积", "optionB": "平均孔径", "optionC": "筛分组成", "optionD": "颗粒密度", "answer": "CD", "answercode": 11, "state": 0, "marked": 0, "checked": 0 },
    { "id": 553, "type": "多选题", "content": "防止蒸汽管道内水冲击的措施有(　　)。", "optionA": "避免锅炉负荷增加过快", "optionB": "保证给水和锅水质量", "optionC": "防止高水位运行", "optionD": "防止锅炉满水", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 554, "type": "多选题", "content": "返混是(　　)的物料相互之间的混合。", "optionA": "不同时刻进入反应器", "optionB": "同一时刻进入反应器", "optionC": "不同年龄", "optionD": "相同年龄", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 555, "type": "多选题", "content": "下列选项中，关于催化分馏塔描述正确的是(　　)。", "optionA": "采用吸收油流程", "optionB": "有脱过热和洗涤粉尘的油浆换热段", "optionC": "采用较多的循环回流", "optionD": "采用压降小的塔盘", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 556, "type": "多选题", "content": "下列选项中，造成催化剂损耗的原因有(　　)。", "optionA": "操作压力波动", "optionB": "主风量波动", "optionC": "水蒸汽量波动", "optionD": "发生事故", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 557, "type": "多选题", "content": "提升管反应器的特点有(　　)。", "optionA": "产品分布改善", "optionB": "操作弹性好", "optionC": "处理量大", "optionD": "较易控制", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 558, "type": "多选题", "content": "原料中硫含量上升，会导致(　　)。", "optionA": "产品质量变差", "optionB": "气体产率增加", "optionC": "产品分布变坏", "optionD": "主风耗量减少", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 559, "type": "多选题", "content": "原料中硫含量上升，会导致(　　)。", "optionA": "产品中硫含量下降", "optionB": "产品中硫含量上升", "optionC": "烟气中硫含量上升", "optionD": "烟气中硫含量下降", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 560, "type": "多选题", "content": "机泵轴承温度偏高的原因有(　　)。", "optionA": "超负荷", "optionB": "泵轴与电机轴不同心", "optionC": "润滑油不足", "optionD": "冷却水不足", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 561, "type": "多选题", "content": "下列选项中，关于开路大循环描述正确的是(　　)", "optionA": "除去设备、管道中的积水", "optionB": "使设备逐步升温", "optionC": "可以不进行开路大循环", "optionD": "没有作用", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 562, "type": "多选题", "content": "下列选项中，属于气压机振动过大常见原因的是(　　)。", "optionA": "喘振", "optionB": "转子与定子碰撞", "optionC": "转子不平衡", "optionD": "产生油膜振荡", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 563, "type": "多选题", "content": "在稳定状态下，分馏塔把反应器过来的油气分割成(　　)馏分。", "optionA": "粗汽油", "optionB": "富气", "optionC": "轻柴油", "optionD": "油浆", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 564, "type": "多选题", "content": "分馏系统操作的主要任务是保证(　　)质量满足规定的要求。", "optionA": "粗汽油", "optionB": "稳定汽油", "optionC": "轻柴油", "optionD": "液化气", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 565, "type": "多选题", "content": "催化剂重金属污染产生的后果是(　　)。", "optionA": "催化剂选择性降低", "optionB": "催化剂活性下降", "optionC": "催化剂稳定性下降", "optionD": "抗磨损性下降", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 566, "type": "多选题", "content": "下列选项中，会引起反应温度上升的原因是(　　)。", "optionA": "催化剂循环量增加", "optionB": "催化剂循环量减少", "optionC": "原料油预热温度高", "optionD": "原料油预热温度低", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 567, "type": "多选题", "content": "渣油中的硫是以(　　)的状态存在。", "optionA": "单质硫", "optionB": "硫醇", "optionC": "硫化物", "optionD": "噻吩", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 568, "type": "多选题", "content": "离心式气压机主要有(　　)保护装置。", "optionA": "低油压", "optionB": "轴向位移", "optionC": "危急保安", "optionD": "反飞动控制", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 569, "type": "多选题", "content": "分馏系统操作应重点控制好(　　)。", "optionA": "顶回流", "optionB": "一中段回流", "optionC": "二中段回流", "optionD": "油浆循环回流", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 570, "type": "多选题", "content": "机泵电机温度偏高的原因有(　　)。", "optionA": "定子内绕线短线", "optionB": "电源电压超负荷", "optionC": "轴承磨损", "optionD": "润滑油变质", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 571, "type": "多选题", "content": "催化剂堆积时，按测定方法不同可分成(　　)。", "optionA": "堆积状态", "optionB": "松动状态", "optionC": "沉降状态", "optionD": "密实状态", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 572, "type": "多选题", "content": "原料中硫含量上升，则(　　)。", "optionA": "对设备无影响", "optionB": "设备腐蚀加剧", "optionC": "酸性水硫含量上升", "optionD": "酸性水硫含量下降", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 573, "type": "多选题", "content": "硬度对锅炉运行的影响有(　　)。", "optionA": "恶化蒸汽品质", "optionB": "锅炉结垢", "optionC": "传热系数减少", "optionD": "局部过热", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 574, "type": "多选题", "content": "主风机试车前须对(　　)进行调校。", "optionA": "反喘振阀", "optionB": "主风出口单向阀", "optionC": "主风进再生器前单向阻尼阀", "optionD": "烟机入口蝶阀", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 575, "type": "多选题", "content": "下列选项中，会造成分馏塔底温度下降的操作是(　　)。", "optionA": "反应切断进料", "optionB": "油浆热流增加", "optionC": "油浆下返塔阀开大", "optionD": "油浆下返塔阀关小", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 576, "type": "多选题", "content": "提升管反应灵活性好的特点，体现在可以通过改变(　　)等条件，实现不同的生产方案。", "optionA": "反应温度", "optionB": "催化剂活性", "optionC": "反应压力", "optionD": "不同催化剂", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 577, "type": "多选题", "content": "下列选项中，会导致催化剂带油的原因有(　　)。", "optionA": "反应温度高", "optionB": "反应温度低", "optionC": "汽提蒸汽量小", "optionD": "汽提蒸汽量大", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 578, "type": "多选题", "content": "下列选项中，会引起反应压力上升的原因是(　　)。", "optionA": "反应温度上升", "optionB": "反应温度降低", "optionC": "原料油带水", "optionD": "分馏塔顶冷却效果好", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 579, "type": "多选题", "content": "因给水止回阀不严，引起锅炉内水冲击，在抢修或更换止回阀时，应(　　)。", "optionA": "防止锅炉满水", "optionB": "注意监视水位", "optionC": "抢修或更换要迅速", "optionD": "防止锅炉缺水", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 580, "type": "多选题", "content": "分馏塔蒸塔时，应防止(　　)。", "optionA": "分馏塔超压", "optionB": "空气倒入分馏塔", "optionC": "系统互窜", "optionD": "塔底积水", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 581, "type": "多选题", "content": "提升管喷油过程中，反应压力可由(　　)控制。", "optionA": "分馏塔顶蝶阀", "optionB": "反飞动控制阀", "optionC": "气压机进口放火炬", "optionD": "气压机出口放火炬", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 582, "type": "多选题", "content": "下列选项中，引起干气中C3含量增加的原因是(　　)。", "optionA": "富气量大", "optionB": "吸收剂量小", "optionC": "吸收塔压力低", "optionD": "吸收塔冲塔", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 583, "type": "多选题", "content": "大多数催化装置采用(　　)，实现控制再生烟气氧含量。", "optionA": "富氧操作", "optionB": "加CO助燃剂", "optionC": "高温完全再生", "optionD": "调节再生温度", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 584, "type": "多选题", "content": "催化剂重金属污染会造成(　　)。", "optionA": "干气产率上升", "optionB": "焦炭产率增大", "optionC": "轻质油收率下降", "optionD": "产品的不饱和度增加", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 585, "type": "多选题", "content": "提升管反应器必须使用高活性的(　　)催化剂，以进一步促进催化剂再生技术的发展。", "optionA": "高硅酸铝微球", "optionB": "低硅酸铝微球", "optionC": "半合成分子筛", "optionD": "全合成分子筛", "answer": "CD", "answercode": 11, "state": 0, "marked": 0, "checked": 0 },
    { "id": 586, "type": "多选题", "content": "锅炉内水冲击的原因有(　　)。", "optionA": "给水管道上的止回阀不严", "optionB": "锅内水位高于给水分配管", "optionC": "蒸汽窜入给水管道", "optionD": "给水管在锅内部分腐蚀穿孔", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 587, "type": "多选题", "content": "下列选项中，引起锅炉汽包缺水的原因是(　　)。", "optionA": "给水系统故障", "optionB": "炉管漏", "optionC": "省煤器漏", "optionD": "排污阀漏", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 588, "type": "多选题", "content": "班组经济核算的内容主要是效益和成本的核算，其中成本的核算主要是计算(　　)等动力消耗和原材料的消耗成本。", "optionA": "工资", "optionB": "水", "optionC": "电", "optionD": "蒸汽", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 589, "type": "多选题", "content": "催化裂化装置的流态化系统有(　　)。", "optionA": "鼓泡床流态化", "optionB": "湍动床流态化", "optionC": "快速床流态化", "optionD": "密相气力输送和稀相气力输送", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 590, "type": "多选题", "content": "分馏塔蒸塔时，应(　　)。", "optionA": "关闭塔各侧线", "optionB": "塔底放空稍开排凝", "optionC": "塔顶放空开大", "optionD": "塔顶放空稍开", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 591, "type": "多选题", "content": "辅助燃烧室炉膛温度高，而炉出口温度低可以(　　)进行调节。", "optionA": "关小一次风", "optionB": "开大一次风", "optionC": "关小二次风", "optionD": "开大二次风", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 592, "type": "多选题", "content": "下列选项中，会引起蒸汽管道内产生水冲击的原因是(　　)。", "optionA": "锅炉低水位运行", "optionB": "锅炉高水位运行", "optionC": "锅炉汽水共腾", "optionD": "锅炉满水", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 593, "type": "多选题", "content": "与柴油机腐蚀和磨损有关的指标是(　　)。", "optionA": "含硫量", "optionB": "酸度", "optionC": "灰分", "optionD": "残炭", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 594, "type": "多选题", "content": "下列选项中，处理二次燃烧正确的操作方法是(　　)。", "optionA": "后部温度高时大量使用三旋喷水", "optionB": "增加一氧化碳助燃剂的用量", "optionC": "增加一氧化碳助燃剂的加入次数", "optionD": "增加催化剂循环量", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 595, "type": "多选题", "content": "下列选项中，属于操作工责任心不强，引起锅炉汽包缺水的原因是(　　)。", "optionA": "忽视对水位的监视", "optionB": "技术素质低", "optionC": "脱岗", "optionD": "巡检不认真", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 596, "type": "多选题", "content": "稳定塔回流比大，则(　　)。", "optionA": "会受到热源不足的限制", "optionB": "塔顶冷凝器负荷增大", "optionC": "不凝气增多", "optionD": "液化气产量减少", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 597, "type": "多选题", "content": "提升管反应灵活性好的特点，体现在可以使用(　　)原料，作为提升管进料，并能得到较好的产品收率。", "optionA": "混合蜡油", "optionB": "常压渣油", "optionC": "减压渣油", "optionD": "脱沥青油", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 598, "type": "多选题", "content": "当进入烧焦罐的催化剂量不变时，随着线速的增加，床层密度变化将出现(　　)。", "optionA": "混合区", "optionB": "高密度区", "optionC": "低密度区", "optionD": "过渡区", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 599, "type": "多选题", "content": "影响塔效率的因素有(　　)。", "optionA": "混和物气液两相的物理性质", "optionB": "操作变量", "optionC": "精馏塔的结构", "optionD": "处理量", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 600, "type": "多选题", "content": "原料中的硫会污染催化剂，使催化剂的(　　)变差。", "optionA": "活性", "optionB": "选择性", "optionC": "稳定性", "optionD": "水热稳定性", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 601, "type": "多选题", "content": "下列选项中，导致气压机运行中产生飞动的原因有(　　)。", "optionA": "机出口压力突升", "optionB": "机出口压力突降", "optionC": "机入口流量突升", "optionD": "机入口流量突降", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 602, "type": "多选题", "content": "变频调速的缺点是(　　)。", "optionA": "技术复杂", "optionB": "投资大", "optionC": "选择余地不大", "optionD": "品种少", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 603, "type": "多选题", "content": "变频调速的优点是(　　)。", "optionA": "技术简单", "optionB": "调速范围宽", "optionC": "选择余地大", "optionD": "品种多", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 604, "type": "多选题", "content": "控制汽油收率，常用手段是调节(　　)。", "optionA": "剂油比", "optionB": "催化剂活性", "optionC": "回炼比", "optionD": "反应器喷汽油量", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 605, "type": "多选题", "content": "下列选项中，能使汽油收率增加的是(　　)。", "optionA": "剂油比高", "optionB": "剂油比低", "optionC": "回炼比高", "optionD": "回炼比低", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 606, "type": "多选题", "content": "下列选项中，对降低油浆固含量有利的操作是(　　)。", "optionA": "加大油浆外排量", "optionB": "加大油浆回炼量", "optionC": "提高反应温度", "optionD": "提高油浆上返塔量", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 607, "type": "多选题", "content": "停工扫线时，应注意(　　)。", "optionA": "加强与外单位的联系", "optionB": "给汽前必须将蒸汽中冷凝水放净", "optionC": "要认真做好记录", "optionD": "停汽前应由放空检查，切实扫净再停", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 608, "type": "多选题", "content": "硫转移催化剂加入方式可以采用(　　)方式。", "optionA": "加助燃剂", "optionB": "小型加料", "optionC": "大型加料", "optionD": "加入原料油", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 609, "type": "多选题", "content": "当汽轮机发生水冲击停机后，应进行(　　)。", "optionA": "检查推力轴承合金润滑油温度或回油温度", "optionB": "正确记录惰走时间和惰走时真空值的变化情况", "optionC": "惰走时仔细倾听汽轮机内部声响", "optionD": "测量轴向位移值", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 610, "type": "多选题", "content": "下列选项中，处理锅炉汽水共沸错误的方法是(　　)。", "optionA": "减小锅炉蒸发量", "optionB": "增大锅炉负荷", "optionC": "减少排污", "optionD": "开启蒸汽管路和分汽包上的疏水器", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 611, "type": "多选题", "content": "停工分馏系统退油时，应(　　)。", "optionA": "将回炼罐内油转入分馏塔并通入蒸汽", "optionB": "将原料油、回炼油、一中系统内存油扫入分馏塔", "optionC": "将粗汽油罐内汽油送至不合格油罐", "optionD": "沉降器内无催化剂后，停油浆循环，尽快将分馏塔底油送出装置", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 612, "type": "多选题", "content": "发生碳堆积时，应(　　)。", "optionA": "降低反应进料量", "optionB": "增加油浆回炼量", "optionC": "降低油浆回炼油量", "optionD": "增加反应进料量", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 613, "type": "多选题", "content": "因给水止回阀不严，引起锅炉内水冲击，在抢修或更换止回阀时，应(　　)。", "optionA": "降低锅炉负荷", "optionB": "增加锅炉负荷", "optionC": "降低锅炉压力", "optionD": "增加锅炉压力", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 614, "type": "多选题", "content": "发生碳堆积时，可适当(　　)。", "optionA": "降低汽提蒸汽量", "optionB": "增加汽提蒸汽量", "optionC": "降低催化剂循环量", "optionD": "增加催化剂循环量", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 615, "type": "多选题", "content": "当锅炉轻微缺水时，应(　　)。", "optionA": "降低热源", "optionB": "减少给水", "optionC": "缓慢增加给水", "optionD": "增加热源", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 616, "type": "多选题", "content": "控制催化剂活性过高，最简单的方法就是(　　)。", "optionA": "降低再生器烧焦强度", "optionB": "提高再生器烧焦强度", "optionC": "降低再生剂含炭量", "optionD": "增加再生剂含炭量", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 617, "type": "多选题", "content": "下列选项中，能提高剂油比措施的是(　　)。", "optionA": "降低再生温度", "optionB": "提高提升管出口温度", "optionC": "降低原料油预热温度", "optionD": "提高回炼油量", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 618, "type": "多选题", "content": "机泵轴承温度偏高的处理方法有(　　)。", "optionA": "降负荷", "optionB": "温度不超标观察使用", "optionC": "更换润滑油", "optionD": "保证冷却水量", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 619, "type": "多选题", "content": "下列选项中，引起板式塔不能维持正常操作的原因是(　　)。", "optionA": "降液管液泛", "optionB": "雾沫夹带", "optionC": "气泡夹带", "optionD": "严重漏液", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 620, "type": "多选题", "content": "原料油带水的处理方法有(　　)", "optionA": "降总进料量，可适当多降带水原料流量，量太低可提其它原料及回炼油（浆）流量", "optionB": "带水原料流量改手动", "optionC": "再生单动滑阀改手动", "optionD": "控制好反应压力", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 621, "type": "多选题", "content": "当锅炉轻微满水时，应(　　)。", "optionA": "紧急停炉", "optionB": "蒸汽切出管网", "optionC": "停给水", "optionD": "开排污", "answer": "CD", "answercode": 11, "state": 0, "marked": 0, "checked": 0 },
    { "id": 622, "type": "多选题", "content": "催化剂密度对催化剂损耗的影响是(　　)。", "optionA": "颗粒密度越低，催化剂带出量越大", "optionB": "颗粒密度越低，催化剂带出量越少", "optionC": "颗粒密度越大，催化剂带出量越大", "optionD": "颗粒密度越大，催化剂带出量越少", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 623, "type": "多选题", "content": "催化剂湿度对催化剂损耗的影响是(　　)。", "optionA": "颗粒湿度大，催化剂损耗少", "optionB": "颗粒湿度大，催化剂损耗大", "optionC": "颗粒湿度小，催化剂损耗大", "optionD": "颗粒湿度小，催化剂损耗少", "answer": "BD", "answercode": 101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 624, "type": "多选题", "content": "催化剂圆度对催化剂损耗的影响是(　　)。", "optionA": "颗粒圆度好，催化剂损耗少", "optionB": "颗粒圆度好，催化剂损耗大", "optionC": "颗粒圆度差，催化剂损耗大", "optionD": "颗粒圆度差，催化剂损耗少", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 625, "type": "多选题", "content": "催化剂粒度对催化剂损耗的影响是(　　)。", "optionA": "颗粒越小，气体夹带损耗大", "optionB": "颗粒越小，气体夹带损耗小", "optionC": "颗粒过大本身破碎大", "optionD": "颗粒过大本身破碎小", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 626, "type": "多选题", "content": "顶循环系统顶水时，应(　　)。", "optionA": "空冷分片顶水", "optionB": "空冷一起顶水", "optionC": "换热器分组顶水", "optionD": "换热器组一起顶水", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 627, "type": "多选题", "content": "完全再生时，防止二次燃烧的措施有(　　)。", "optionA": "控制较高的过剩氧", "optionB": "加一氧化碳助燃剂", "optionC": "控制较低的再生藏量", "optionD": "控制较低的过剩氧", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 628, "type": "多选题", "content": "开工循环时，顶回流充汽油的目的是(　　)。", "optionA": "控制塔顶温度", "optionB": "赶走系统存水", "optionC": "尽快建立顶回流", "optionD": "防止硫化铁自燃", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 629, "type": "多选题", "content": "下列选项中，会引起汽油干点下降的原因是(　　)。", "optionA": "冷回流增加", "optionB": "顶回流增加", "optionC": "一中段温度上升", "optionD": "一中段流量增加", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 630, "type": "多选题", "content": "下列选项中，属于新鲜催化剂物理性质的是(　　)。", "optionA": "粒度", "optionB": "比表面积", "optionC": "孔体积", "optionD": "密度", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 631, "type": "多选题", "content": "下列选项中，属于平衡催化剂物理性质的是(　　)。", "optionA": "粒度", "optionB": "比表面积", "optionC": "孔体积", "optionD": "密度", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 632, "type": "多选题", "content": "汽油的蒸发性用(　　)指标评定。", "optionA": "馏程", "optionB": "诱导期", "optionC": "蒸汽压", "optionD": "胶质", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 633, "type": "多选题", "content": "催化裂化平衡催化剂的物理组成分析包括(　　)。", "optionA": "密度", "optionB": "孔体积", "optionC": "炭含量", "optionD": "磨损指数", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 634, "type": "多选题", "content": "下列选项中，会造成机械密封经常性漏损的原因是(　　)。", "optionA": "密封面比压太小", "optionB": "密封圈的密封性不好", "optionC": "转子振动", "optionD": "弹簧偏心", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 635, "type": "多选题", "content": "给水中主要由(　　)形成的硬度会造成锅炉结垢，使锅炉传热系数减少。", "optionA": "钠盐", "optionB": "镁盐", "optionC": "钙盐", "optionD": "铁盐", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 636, "type": "多选题", "content": "为防止蒸汽管道内水冲击，在蒸汽管道引汽前应(　　)。", "optionA": "排凝", "optionB": "暖管", "optionC": "缓慢引汽", "optionD": "迅速引汽", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 637, "type": "多选题", "content": "评定汽油安定性的指标有(　　)。", "optionA": "品质度", "optionB": "碘值", "optionC": "诱导期", "optionD": "实际胶质", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 638, "type": "多选题", "content": "膨胀节按其形状分类有轴向、铰链型、(　　)。", "optionA": "平衡环型", "optionB": "平衡型", "optionC": "复式", "optionD": "压力平衡型", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 639, "type": "多选题", "content": "下列操作变量中，属于塔分离效率影响因素的是(　　)。", "optionA": "气速", "optionB": "回流比", "optionC": "温度", "optionD": "压力", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 640, "type": "多选题", "content": "造成板式塔液泛的原因有(　　)。", "optionA": "气速过小", "optionB": "气体分布不均匀", "optionC": "液体流量过大", "optionD": "气速过大", "answer": "CD", "answercode": 11, "state": 0, "marked": 0, "checked": 0 },
    { "id": 641, "type": "多选题", "content": "大气温度变化，对轴流压缩机特性曲线的影响是(　　)。", "optionA": "气温低，吸入流量少", "optionB": "气温高，吸入流量少", "optionC": "气温低，出口压力上升", "optionD": "气温高，出口压力上升", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 642, "type": "多选题", "content": "蒸馏过程是(　　)的传质过程。", "optionA": "气相中轻组分进入液相", "optionB": "气相中重组分进入液相", "optionC": "液相中轻组分进入气相", "optionD": "液相中重组分进入气相", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 643, "type": "多选题", "content": "返混是一个重要的工程因素，它存在于(　　)类型反应器。", "optionA": "气液反应塔", "optionB": "固定床反应器", "optionC": "间隙反应釜", "optionD": "流化床反应器", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 644, "type": "多选题", "content": "下列选项中，与轻柴油闪点质量相关的操作参数是(　　)。", "optionA": "汽提塔液面", "optionB": "汽提蒸汽流量", "optionC": "分馏一中段流量", "optionD": "分馏循环油浆流量", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 645, "type": "多选题", "content": "分馏塔采用较多循环回流的原因有(　　)。", "optionA": "全塔剩余热量大", "optionB": "利用较高温位的热能", "optionC": "减少塔顶回流用量", "optionD": "减少塔顶热负荷", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 646, "type": "多选题", "content": "催化裂化分馏塔的特点是(　　)。", "optionA": "全塔剩余热量大", "optionB": "全塔剩余热量小", "optionC": "系统压降大", "optionD": "系统压降小", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 647, "type": "多选题", "content": "稳定塔顶压力控制方案有(　　)。", "optionA": "热旁路", "optionB": "卡脖子", "optionC": "热旁路和卡脖子结合", "optionD": "调节冷却水流量", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 648, "type": "多选题", "content": "由泵入口系统引起泵抽空的原因有(　　)。", "optionA": "容器液面低", "optionB": "管线堵塞", "optionC": "扫线蒸汽漏", "optionD": "漏气", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 649, "type": "多选题", "content": "大型机组自动控制润滑油温度的方法是控制(　　)。", "optionA": "润滑油量", "optionB": "进入冷油器的润滑油量", "optionC": "冷却水量", "optionD": "电加热器", "answer": "BD", "answercode": 101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 650, "type": "多选题", "content": "主风机开机前，润滑油系统检查工作包括(　　)。", "optionA": "润滑油压力", "optionB": "润滑油温度", "optionC": "过滤器差压", "optionD": "蓄能器压力", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 651, "type": "多选题", "content": "开工时，分馏塔一中段回流充柴油的目的是(　　)。", "optionA": "使一中段泵尽快上量", "optionB": "控制柴油质量", "optionC": "脱水", "optionD": "提供热源", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 652, "type": "多选题", "content": "下列选项中，属于进料雾化蒸汽作用的是(　　)。", "optionA": "使油气与催化剂混合均匀", "optionB": "提高原料温度", "optionC": "避免催化剂迅速结焦", "optionD": "降低油气分压", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 653, "type": "多选题", "content": "下列选项中，能够缓解锅炉汽水共沸的方法有(　　)。", "optionA": "适当降低锅炉蒸发量", "optionB": "全开连排阀门", "optionC": "全关连排阀门", "optionD": "适当提高锅炉蒸发量", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 654, "type": "多选题", "content": "当发生二次燃烧时，应(　　)。", "optionA": "适当降低氧含量", "optionB": "降低再生温度", "optionC": "提高再生温度", "optionD": "加助燃剂后提主风量", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 655, "type": "多选题", "content": "热崩是(　　)碰到(　　)催化剂迅速汽化，从而使催化剂崩裂的现象。", "optionA": "水，高温", "optionB": "蒸汽，高温", "optionC": "水，低温", "optionD": "蒸汽，低温", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 656, "type": "多选题", "content": "锅炉给水中含有起泡沫的物质(　　)，会恶化蒸汽品质，严重时会造成汽水共腾。", "optionA": "苏打", "optionB": "有机物质", "optionC": "泥渣类物质", "optionD": "腐蚀性物质", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 657, "type": "多选题", "content": "压缩机喘振的危害有(　　)。", "optionA": "损坏动、静部件", "optionB": "产生叶片折断", "optionC": "导致内缸损坏", "optionD": "造成轴封损坏", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 658, "type": "多选题", "content": "影响最小回流比的相关因素，正确的有(　　)。", "optionA": "塔顶产品要求", "optionB": "塔底产品要求", "optionC": "气液平衡关系", "optionD": "塔板数", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 659, "type": "多选题", "content": "催化剂带油正确处理的方法有(　　)", "optionA": "提高反温度", "optionB": "降低反应温度", "optionC": "降低汽提蒸汽量", "optionD": "提高汽提蒸汽量", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 660, "type": "多选题", "content": "气压机产生飞动的处理方法有(　　)。", "optionA": "调节好反飞动流量", "optionB": "控制好再吸收塔压力", "optionC": "调节气压机工况", "optionD": "紧急时，可采取气压机出口或进口放火炬", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 661, "type": "多选题", "content": "停工反应切断进料后，分馏系统应(　　)。", "optionA": "停止原料进分馏塔", "optionB": "用冷回流控制分馏塔顶温度，粗汽油改不合格汽油线", "optionC": "停出轻柴油，控制好封油罐液位", "optionD": "控制分馏塔底温度不要太低，排油浆以控制分馏塔底液面", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 662, "type": "多选题", "content": "固体在垂直管中向下流动是密相输送的常见方式，催化裂化装置中(　　)属于这种方式。", "optionA": "脱气罐", "optionB": "立管", "optionC": "汽提段", "optionD": "旋风器料腿", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 663, "type": "多选题", "content": "背压式汽轮机本体结构包括(　　)", "optionA": "危急保安装置", "optionB": "转子", "optionC": "速关阀", "optionD": "手动保安装置", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 664, "type": "多选题", "content": "背压式汽轮机转子部分包括(　　)", "optionA": "危急遮断器", "optionB": "动叶环", "optionC": "联轴器", "optionD": "整锻轴", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 665, "type": "多选题", "content": "凝汽式汽轮机凝汽器运行中，应经常检查(　　)指标。", "optionA": "温升", "optionB": "过冷度", "optionC": "端差", "optionD": "冷却水出口虹吸降低", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 666, "type": "多选题", "content": "下列选项中，引起液态烃中C5含量超标的原因有(　　)。", "optionA": "稳定塔顶回流小", "optionB": "稳定塔底温度高", "optionC": "稳定塔压力高", "optionD": "稳定塔进料位置变化", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 667, "type": "多选题", "content": "板式塔传质效率的主要影响因素有(　　)。", "optionA": "雾沫夹带", "optionB": "板上液体返混", "optionC": "均匀流动", "optionD": "不均匀流动", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 668, "type": "多选题", "content": "催化裂化分馏塔一中段回流，理论上可以作为(　　)的热源。", "optionA": "吸收塔", "optionB": "解吸塔", "optionC": "再吸收塔", "optionD": "稳定塔", "answer": "BD", "answercode": 101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 669, "type": "多选题", "content": "吸收稳定系统包括(　　)。", "optionA": "吸收塔", "optionB": "再吸收塔", "optionC": "解吸塔", "optionD": "稳定塔", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 670, "type": "多选题", "content": "开工稳定系统充瓦斯时，应(　　)。", "optionA": "先赶空气，再充瓦斯", "optionB": "直接充瓦斯", "optionC": "注意切水", "optionD": "充完后要采样分析", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 671, "type": "多选题", "content": "使用提升管反应器后，汽油质量中(　　)。", "optionA": "辛烷值提高", "optionB": "烯烃减少", "optionC": "诱导期增长", "optionD": "安定性提高", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 672, "type": "多选题", "content": "锅炉给水中含有结垢性物质(　　)，会引起炉管过热，严重时会发生爆管。", "optionA": "悬浮物", "optionB": "硫酸盐", "optionC": "碳酸盐", "optionD": "硝酸盐", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 673, "type": "多选题", "content": "受管网容积支配的是压缩机的(　　)。", "optionA": "压力", "optionB": "振幅", "optionC": "流量", "optionD": "频率", "answer": "BD", "answercode": 101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 674, "type": "多选题", "content": "带有烟机的再生器压力控制方案，一般是由(　　)组成分程控制。", "optionA": "烟机进口蝶阀", "optionB": "双动滑阀", "optionC": "再生单动滑阀", "optionD": "烟机旁路蝶阀", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 675, "type": "多选题", "content": "下列选项中，会引起柴油凝固点降低的原因是(　　)。", "optionA": "一中段返塔温度降低", "optionB": "一中段返塔温度升高", "optionC": "一中段回流量减少", "optionD": "一中段回流量增加", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 676, "type": "多选题", "content": "锅炉给水不纯的影响有(　　)。", "optionA": "引起泡沫", "optionB": "汽水共腾", "optionC": "结垢", "optionD": "腐蚀", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 677, "type": "多选题", "content": "蒸汽管道内水冲击的原因有(　　)。", "optionA": "引汽前未进行暖管", "optionB": "引汽前未排凝", "optionC": "蒸汽并网速度慢", "optionD": "锅炉负荷增加太快", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 678, "type": "多选题", "content": "催化裂化分馏塔是底部过热进料，(　　)。", "optionA": "有精馏段", "optionB": "没有精馏段", "optionC": "有提馏段", "optionD": "没有提馏段", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 679, "type": "多选题", "content": "下列选项中，会引起碳堆积的原因是(　　)。", "optionA": "原料油变重", "optionB": "反应深度太大", "optionC": "反应温度过低", "optionD": "汽提蒸汽量大", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 680, "type": "多选题", "content": "下列选项中，可以减少催化剂重金属污染的是(　　)。", "optionA": "原料油预处理", "optionB": "催化剂脱金属", "optionC": "使用金属钝化剂", "optionD": "加大催化剂置换率。", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 681, "type": "多选题", "content": "在提升管催化裂化装置，当烟机启用时，再生压力可以由(　　)控制。", "optionA": "再生单动滑阀", "optionB": "待生塞阀", "optionC": "双动滑阀", "optionD": "烟机进口蝶阀", "answer": "CD", "answercode": 11, "state": 0, "marked": 0, "checked": 0 },
    { "id": 682, "type": "多选题", "content": "发生二次燃烧时，温度会升高的部位是(　　)。", "optionA": "再生密相温度", "optionB": "三旋入口", "optionC": "三旋出口", "optionD": "烟机进口", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 683, "type": "多选题", "content": "下列选项中，能够引起碳堆积的原因有(　　)。", "optionA": "再生器藏量高", "optionB": "主风量小", "optionC": "再生温度过低", "optionD": "燃料油量大", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 684, "type": "多选题", "content": "下列选项中，能引起分馏塔底液面上升的原因是(　　)。", "optionA": "增加反应深度", "optionB": "增加油浆循环量", "optionC": "油浆泵抽空", "optionD": "油浆返塔温度降低", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 685, "type": "多选题", "content": "发生碳堆积时，下列操作错误的是(　　)。", "optionA": "增加回炼油量", "optionB": "降低回炼油量", "optionC": "适当增加主风量", "optionD": "降低主风量", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 686, "type": "多选题", "content": "下列混合物气液两相的物理性质，对塔分离效率有影响的是(　　)。", "optionA": "粘度", "optionB": "相对挥发度", "optionC": "扩散系数", "optionD": "表面张力", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 687, "type": "多选题", "content": "下列选项中，引起压缩机运行振动过大的原因有(　　)。", "optionA": "转子不平衡", "optionB": "安装不良", "optionC": "轴颈椭圆度过大", "optionD": "产生油膜振动", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 688, "type": "多选题", "content": "下列选项中，属于催化裂化装置催化剂操作方法的是(　　)。", "optionA": "装新鲜催化剂", "optionB": "三旋转催化剂", "optionC": "加金属钝化剂", "optionD": "大型加料", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 689, "type": "多选题", "content": "下列选项中，会引起反应温度降低的原因是(　　)。", "optionA": "总进料量增加", "optionB": "总进料量降低", "optionC": "终止剂量增加", "optionD": "终止剂量降低", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 690, "type": "多选题", "content": "顶循环系统顶水应(　　)。", "optionA": "走合格汽油线", "optionB": "由上往下顶水", "optionC": "由下往上顶水", "optionD": "走不合格汽油线", "answer": "CD", "answercode": 11, "state": 0, "marked": 0, "checked": 0 },
];

export default middle;