// 高级工题库
const senior = [
    { "id": 1, "type": "判断题", "content": "实验室分析出来的催化裂化原料的残炭是一些加工过程中生焦的前身物质。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 2, "type": "判断题", "content": "当孔板压力降超过临界压力降时，不论孔板出口压力如何降低，通过孔板流体的流量将维持一定而不再增加。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 3, "type": "判断题", "content": "催化裂化装置的反应再生系统是一个典型的广义循环流态化系统。循环流态化系统包括鼓泡床流态化、湍动床流化态、快速床流化态、密相气力输送和稀相气力输送。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 4, "type": "判断题", "content": "在进入床层催化剂量不变的情况下，随着气速的增加，气体把催化剂带出床层的能力也相应增加。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 5, "type": "判断题", "content": "对于一个气/固垂直管道输送系统中，催化剂颗粒的上行速度总是低于气体的速度。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 6, "type": "判断题", "content": "同一种类型的分子筛催化剂，比表面积与活性有很好的对应关系。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 7, "type": "判断题", "content": "单位体积催化剂内外表面积之和，叫做催化剂的比表面积。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 8, "type": "判断题", "content": "污染指数是表示催化剂被重金属污染的程度。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 9, "type": "判断题", "content": "基于催化剂颗粒耐表面磨损而测定的催化剂强度，称为催化剂的耐磨强度，用磨损指数来表示。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 10, "type": "判断题", "content": "通常以全合成硅铝胶为粘结剂的催化剂磨损指数小，以铝溶胶为粘结剂的催化剂磨损指数大。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 11, "type": "判断题", "content": "装置在催化剂补充速度和中毒状况相同的条件下，平衡活性越高说明稳定性越好。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 12, "type": "判断题", "content": "同类催化裂化催化剂中，新鲜催化剂中的RE2O3含量越高，活性越高。 ", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 13, "type": "判断题", "content": "催化剂活性越高，转化率越高。为达到高的汽油收率，催化剂活性越高越好。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 14, "type": "判断题", "content": "钒对催化剂的的破坏机理是氧环境下生成V2O5，从而对催化剂产生破坏。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 15, "type": "判断题", "content": "硫转移催化剂对催化裂化反应不起作用。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 16, "type": "判断题", "content": "正构烷烃的反应速度大于异构烷烃。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 17, "type": "判断题", "content": "早期同高并列式催化裂化装置，由于采用床层反应器，与提升管反应器相比产品收率较低。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 18, "type": "判断题", "content": "高低并列式催化裂化装置，在催化剂输送线路上一般不需松动，仅在需要降低滑阀压降时方在滑阀上游补入松动气。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 19, "type": "判断题", "content": "同轴式催化裂化装置的催化剂循环量可用塞阀调节，亦可用滑阀调节。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 20, "type": "判断题", "content": "辅助燃烧室电打火原理是高压放电。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 21, "type": "判断题", "content": "重油催化裂化装置的废催化剂，包括三旋回收的催化剂细粉和卸出的平衡催化剂。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 22, "type": "判断题", "content": "气压机开机时，当中间冷却器见油后，可以不开泵，而直接通过自压将凝缩油压出。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 23, "type": "判断题", "content": "事故蒸汽的作用是提升催化剂，以保证催化剂呈流化状态，防止死床。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 24, "type": "判断题", "content": "在满足分馏要求的前提下，应尽量提高顶循环回流的取热量。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 25, "type": "判断题", "content": "分馏二中段回流的作用是回收高温位能量，并使分馏塔内气液相负荷均匀，调节轻柴油凝固点。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 26, "type": "判断题", "content": "分馏二中段回流主要作用是回收高温位能量，视情况可以不用。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 27, "type": "判断题", "content": "在满足分馏要求的前提下，应尽量提高油浆循环的取热量。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 28, "type": "判断题", "content": "钝化剂可以有效钝化重金属对催化剂的污染，因此加入量越大越好。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 29, "type": "判断题", "content": "金属钝化剂稀释比，应按照反应操作参数确定。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 30, "type": "判断题", "content": "APC是先进控制系统，是以模型为基础，通过调节多个相互耦合的控制汇率，实现多个目标的控制。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 31, "type": "判断题", "content": "每小时进入反应器的原料量与反应器催化剂藏量之比，称为空间速度，简称空速。是密相床催化裂化表示反应速率的变量。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 32, "type": "判断题", "content": "所谓反应时间，严格说就是催化剂在提升管的停留时间。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 33, "type": "判断题", "content": "实际生产中，床层操作线速要小于最大流化速度。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 34, "type": "判断题", "content": "增加提升管蒸汽用量，会降低油气分压，还能提高提升管线速，有利于减少反应生焦。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 35, "type": "判断题", "content": "原料中环烷烃含量上升，会导致汽油辛烷值上升。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 36, "type": "判断题", "content": "原料中粗汽油比例上升，会导致汽油辛烷值上升。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 37, "type": "判断题", "content": "催化剂分子筛含量增高，氢转移活性增加，汽油辛烷值上升。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 38, "type": "判断题", "content": "延长油气在提升管的停留时间，可以提高汽油辛烷值。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 39, "type": "判断题", "content": "温度一定时，转化率越大，汽油辛烷值越低。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 40, "type": "判断题", "content": "转化率一定时，汽油辛烷值随反应温度升高而增加。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 41, "type": "判断题", "content": "增加剂油比或减少空速，均增加了反应强度，使裂化转化率增加，使汽油辛烷值增加。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 42, "type": "判断题", "content": "同一种类型的原料，在进料中掺入渣油，量大时汽油烯烃含量低。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 43, "type": "判断题", "content": "相同反应条件下，催化剂平衡活性越高，汽油中烯烃含量越高。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 44, "type": "判断题", "content": "相同转化率情况下，反应温度上升，汽油烯烃含量上升。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 45, "type": "判断题", "content": "沉降器汽提段的汽提，实质是一个吸附过程。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 46, "type": "判断题", "content": "催化剂性质会影响沉降器汽提段汽提效果。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 47, "type": "判断题", "content": "反应温度下降时，可以采用提高进料预热温度或降低反应器进料量的方法。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 48, "type": "判断题", "content": "生产中，反应压力过低时，可以用关小分馏塔顶蝶阀的手段来提高反应压力。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 49, "type": "判断题", "content": "烧焦罐提高线速可以减少返混，从而提高烧焦速率。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 50, "type": "判断题", "content": "分馏塔顶温度变化，塔顶油气冷凝效果好坏，直接影响分馏塔压力，从而造成反应系统压力的变化。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 51, "type": "判断题", "content": "反应系统蒸汽量过大，会造分馏塔气相负荷增大，易产生漏塔现象，造成产品质量不合格。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 52, "type": "判断题", "content": "反应系统蒸汽量过小，分馏塔气相负荷减小，会造成雾沫夹带，使分离效果变差，造成产品质量不合格。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 53, "type": "判断题", "content": "增加循环油浆热流流量，可以提高油浆蒸汽发生器的发汽量。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 54, "type": "判断题", "content": "调节分馏塔顶循环量，可以提高汽油终馏点，降低汽油烯烃含量。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 55, "type": "判断题", "content": "分馏塔轻重馏分重叠，意味着一部分轻馏分进入到重馏分里去，分馏精度变差。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 56, "type": "判断题", "content": "分馏塔轻重馏分重叠，意味着一部分重馏分进入到轻馏分里去，分馏精度好。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 57, "type": "判断题", "content": "选用合适的钝化剂可以有效防止油浆系统结焦。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 58, "type": "判断题", "content": "多组分分离过程中，起控制作用的组分就是关键组分，大多数情况为两种组分。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 59, "type": "判断题", "content": "多组分分离过程中，起控制作用的组分就是关键组分，必定为两个组分。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 60, "type": "判断题", "content": "塔顶回收率高于塔底回收率的组分中最重的组分即为轻关键组分。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 61, "type": "判断题", "content": "塔底回收率高于塔顶回收率的组分中最重的组分即为重关键组分。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 62, "type": "判断题", "content": "通常单塔流程的吸收效果高于双塔流程。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 63, "type": "判断题", "content": "稳定汽油10%点高，汽油蒸气压高。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 64, "type": "判断题", "content": "C4是影响汽油蒸气压的主要组分。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 65, "type": "判断题", "content": "汽油蒸气压是保证汽油启动性能的质量指标。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 66, "type": "判断题", "content": "在同一转化率下，一套装置的生焦率基本一致。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 67, "type": "判断题", "content": "在气压机能力有富余的装置，可采用干气预提升技术来减少结焦。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 68, "type": "判断题", "content": "降低剂油比，使更多重质烃转化，提高转化率，可以减少结焦。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 69, "type": "判断题", "content": "防焦蒸汽采用二级孔喷嘴，使喷嘴指向沉降器所有静空间，可以避免沉降器顶部出现死角。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 70, "type": "判断题", "content": "为了防止反应油气管线结焦，应使油气管线弯头与分馏塔入口之间距离最短。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 71, "type": "判断题", "content": "为提高工作效率，稳定塔顶空冷顶水时，可以采用多片空冷同时顶水的方法。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 72, "type": "判断题", "content": "停烟机时，烟机入口蝶阀全关后，当烟机出口温度降到188℃后，可将烟机轮盘冷却蒸汽停用。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 73, "type": "判断题", "content": "气压机停转后，即可停密封系统。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 74, "type": "判断题", "content": "冷壁滑阀主要部件有阀体、阀盖、截流锥、阀座圈、阀板、导轨和阀杆。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 75, "type": "判断题", "content": "风动塞阀主要由阀体部分、执行机构组成。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 76, "type": "判断题", "content": "膨胀量指示器分为垂直式和摆动式两种，当待生立管在再生器中央时，采用垂直式，否则采用摆动式。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 77, "type": "判断题", "content": "电液塞阀有实心塞阀和空心塞阀两种，其中从国外引进的是实心塞阀。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 78, "type": "判断题", "content": "膨胀量指示器仅待生塞阀需要时设置。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 79, "type": "判断题", "content": "干气密封是气体润滑接触式机械密封的简称。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 80, "type": "判断题", "content": "干气密封由中性高压隔离气体所形成的气膜，完全阻塞了相对低压的密封介质泄漏通道，实现了密封介质的零泄漏或零溢出。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 81, "type": "判断题", "content": "BWJ喷嘴，是内混合式双喉道型进料喷嘴。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 82, "type": "判断题", "content": "KH型喷嘴，是内混合式双喉道型进料喷嘴。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 83, "type": "判断题", "content": "LPC型喷嘴，是双流体液体离心式喷嘴。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 84, "type": "判断题", "content": "集散控制系统的基本特点是分散管理、集中控制。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 85, "type": "判断题", "content": "取热器松动点位置设置不佳，会造成取热器管束泄漏。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 86, "type": "判断题", "content": "对于凝气式汽轮机，在升速暖机过程中应注意真空度到231kPa时，停用辅助抽气器。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 87, "type": "判断题", "content": "对于背压式汽轮机，应注意转速在1000rpm/min以下时间不宜过长。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 88, "type": "判断题", "content": "背压式汽轮机停机过程中，当进汽压力低于1.5MPa时，应逐步打开排汽防空阀。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 89, "type": "判断题", "content": "凝气式汽轮机停机1小时以后，润滑油系统可以停。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 90, "type": "判断题", "content": "烟机密封蒸汽的作用，是防止带有粉尘的高温烟气从轴伸出端外漏，污染环境。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 91, "type": "判断题", "content": "烟机轮盘冷却蒸汽的作用，是防止催化剂细粉进入轮盘死区，形成的团块粘在轮盘上，影响动平衡。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 92, "type": "判断题", "content": "输送液体预热不足，对机泵耗功没有影响。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 93, "type": "判断题", "content": "换热管的排列方式有等边三角形和正方形两种。与正方形相比，等边三角形排列紧凑，管外流体湍动程度高，给热系数大。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 94, "type": "判断题", "content": "轴流式风机在流量大时，运行经济性高于离心式风机。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 95, "type": "判断题", "content": "汽轮机流通部分结垢，会造成气流通过隔板及叶片的压降增加，工作叶片反动度也随之增加，严重时会使隔板及推力轴承过负荷。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 96, "type": "判断题", "content": "调速系统传动杆与蒸汽室温度相差过大，热膨胀不一致，而使错油门不在空负荷位置上，是造成调速系统在空负荷下，不能维持额定转速情况的原因。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 97, "type": "判断题", "content": "对使用干气密封系统的气压机组，密封气与缓冲气差压处于低限时，气压机自动停机。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 98, "type": "判断题", "content": "当再生器内含有催化剂粉末的高温气体倒流后，可使机内温度猛烈上升，对机组破坏十分严重。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 99, "type": "判断题", "content": "汽轮机只要各参数正常，可以长期超负荷运行。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 100, "type": "判断题", "content": "待生松动点采样，采出的是再生催化剂，可以断定待生套筒穿孔。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 101, "type": "判断题", "content": "控制好催化剂的湿度可以减少催化剂跑损。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 102, "type": "判断题", "content": "催化裂化装置轴流式主风机系统必须设置逆流保护装置。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 103, "type": "判断题", "content": "进料自保启用后，反应温度超高，可以启用喷汽油，但要注意反应压力不能超高。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 104, "type": "判断题", "content": "反应进料自保复位时，要将进料旁通阀副线打开。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 105, "type": "判断题", "content": "两器自保复位时，应将再生单动滑阀和待生塞阀控制器置于手动全关位置。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 106, "type": "判断题", "content": "主风自保投用后，检查增压风自保是否动作，若不动作，手动投用增压风自保。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 107, "type": "判断题", "content": "主风自保投用后，应关死外取热器流化风阀，保留少许动力风反吹，避免死床。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 108, "type": "判断题", "content": "气压机停机自保后，稳定塔顶气态烃排放要改瓦斯管网。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 109, "type": "判断题", "content": "外取热器管管束内漏，造成再生器压力急剧上升，反应系统应紧急停工。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 110, "type": "判断题", "content": "在任何情况下，只要沉降器、汽提段内有催化剂，就必须保持分馏塔底油浆循环从上进口返塔。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 111, "type": "判断题", "content": "主风中断后，再生温度靠喷燃烧油来控制。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 112, "type": "判断题", "content": "装置停高压电，主风机组处于发电工况时，机组将停机，装置作紧急停工处理。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 113, "type": "判断题", "content": "装置停高压电，如果主风机组未停，对于有汽轮机带动的气压机的三机组，可以通过反应降量或用入口放火矩控制反应压力，维持操作。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 114, "type": "判断题", "content": "装置停循环水，如果无备用冷却水，主风机作紧急停机处理。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 115, "type": "判断题", "content": "装置停低压电，会造成主风机组一定停机，作紧急停工处理。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 116, "type": "判断题", "content": "装置停动力风时，加料线上靠近再生器壁的阀门不用关闭。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 117, "type": "判断题", "content": "如果分馏塔顶油气分离器液面上涨过快，可以启动两台粗汽油泵，同时向外送油。", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 118, "type": "单选题", "content": "吸收稳定系统收汽油时，稳定塔顶回流罐液位是(　　)。", "optionA": 0, "optionB": 0.3, "optionC": 0.5, "optionD": 1, "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 119, "type": "单选题", "content": "氧气输送管道上每个阀门法兰之间跨接导线，并保证跨接电阻小于(　　)Ω。", "optionA": 0.01, "optionB": 0.02, "optionC": 0.03, "optionD": 0.04, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 120, "type": "单选题", "content": "汽轮机正常运行中，应经常检查润滑油过滤器差压，一旦压差超过(　　)MPA立即切换清洗。", "optionA": 0.05, "optionB": 0.1, "optionC": 0.15, "optionD": 0.2, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 121, "type": "单选题", "content": "对于使用干气密封系统的气压机组，开机条件必须满足主密封气与前置缓冲气压差≥(　　)MPa。", "optionA": 0.1, "optionB": 0.2, "optionC": 0.3, "optionD": 0.4, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 122, "type": "单选题", "content": "稳定塔顶空冷顶水操作，一般控制顶水压力是(　　)MPa。", "optionA": 0.1, "optionB": 0.5, "optionC": 1, "optionD": 2, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 123, "type": "单选题", "content": "在主风管里增加氧气分布器，使氧气混入点到辅助燃烧室入口距离达到(　　)米以上。", "optionA": 3, "optionB": 4, "optionC": 5, "optionD": 6, "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 124, "type": "单选题", "content": "烟机密封蒸汽，应始终控制蒸汽压力大于烟气压力高(　　)kPa。", "optionA": 5, "optionB": 7, "optionC": 10, "optionD": 15, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 125, "type": "单选题", "content": "停工分馏塔热水洗时，分馏塔底部温度一般控制在(　　)℃。", "optionA": 50, "optionB": 80, "optionC": 100, "optionD": 120, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 126, "type": "单选题", "content": "严禁将超过(　　)℃的油品直接放空或直接进罐，防止引起火灾。", "optionA": 100, "optionB": 150, "optionC": 200, "optionD": 250, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 127, "type": "单选题", "content": "烟机轮盘冷却蒸汽的蒸汽压力应控制在 (　　)kPa。", "optionA": 250, "optionB": 350, "optionC": 450, "optionD": 550, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 128, "type": "单选题", "content": "气压机开机过程中，转速在(　　)r/min以下时间不宜过长。", "optionA": 300, "optionB": 500, "optionC": 1000, "optionD": 1200, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 129, "type": "单选题", "content": "切断进料后，主风仍保持时，再生温度不能低于(　　)℃。", "optionA": 350, "optionB": 400, "optionC": 450, "optionD": 500, "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 130, "type": "单选题", "content": "气压机开机过程中，转速在(　　)r/min以上不准停留。", "optionA": 500, "optionB": 1000, "optionC": 1500, "optionD": 2000, "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 131, "type": "单选题", "content": "拆大盲板时，沉降器压力应在(　　)左右。", "optionA": "0.002MPa（G）", "optionB": "0MPa（G）", "optionC": "-0.002MPa（G）", "optionD": "任意值", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 132, "type": "单选题", "content": "催化裂化装置主风事故蒸汽，一般采用的是(　　)蒸汽。", "optionA": "0.3MPa", "optionB": "1.0MPa", "optionC": "3.5MPa", "optionD": "10MPa", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 133, "type": "单选题", "content": "空冷迎风速一般要求(　　)m/s。", "optionA": "0.4~1.4", "optionB": "1.4~3.4", "optionC": "3.4~5.4", "optionD": "大于5.4", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 134, "type": "单选题", "content": "对于均匀颗粒，噎塞速度与沉积速度基本相等；对于粒度分布不均的混合固体颗粒，噎塞速度是其沉积速度的。(　　)。", "optionA": "1/6～1/10", "optionB": "1/3～1/6", "optionC": "1/2～2/3", "optionD": "2～3", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 135, "type": "单选题", "content": "为防止油浆系统结焦，油浆在分馏塔底的停留时间控制在(　　)为宜。", "optionA": "20~30S", "optionB": "3~5min", "optionC": "15~20min", "optionD": "1小时以上", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 136, "type": "单选题", "content": "装置内辅助燃烧室电打火使用电源是(　　)。", "optionA": "24V", "optionB": "110V", "optionC": "220V", "optionD": "380V", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 137, "type": "单选题", "content": "辅助燃烧室电打火一般将电压升压到(　　)", "optionA": "380V", "optionB": "1000V", "optionC": "6000V", "optionD": "10000V", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 138, "type": "单选题", "content": "平衡催化剂的比表面积值一般是(　　)。", "optionA": "50m2/g ", "optionB": "100m2/g ", "optionC": "200m2/g ", "optionD": "150m2/g", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 139, "type": "单选题", "content": "以挥发度递减的A、B、C、D、E五种物料分离为例，现须将A、B、C和D、E分离，其中轻关键组分是(　　)。", "optionA": "A", "optionB": "B", "optionC": "C", "optionD": "D", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 140, "type": "单选题", "content": "以挥发度递减的A、B、C、D、E五种物料分离为例，现须将A、B和C、D、E分离，其中轻关键组分是(　　)。", "optionA": "A", "optionB": "B", "optionC": "C", "optionD": "D", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 141, "type": "单选题", "content": "以挥发度递增的A、B、C、D、E五种物料分离为例，现须将A、B和C、D、E分离，其中重关键组分为(　　)。", "optionA": "A", "optionB": "B", "optionC": "C", "optionD": "D", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 142, "type": "单选题", "content": "以挥发度递减的A、B、C、D、E五种物料分离为例，现须将A、B、C和D、E分离，其中重关键组分为(　　)。", "optionA": "A", "optionB": "B", "optionC": "C", "optionD": "D", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 143, "type": "单选题", "content": "由于停低压电，造成气压机润滑油泵全停，应立即启用(　　)。", "optionA": "进料切断自保", "optionB": "两器切断自保", "optionC": "主风低流量自保", "optionD": "气压机停机自保", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 144, "type": "单选题", "content": "催化剂的活性主要来源是催化剂中的(　　)。", "optionA": "Al2O3 ", "optionB": "Na2O ", "optionC": "CuO", "optionD": "Fe2O3", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 145, "type": "单选题", "content": "下列选项中，属于鸭嘴单孔型喷嘴的是(　　)。", "optionA": "BWJ喷嘴", "optionB": "KH型喷嘴", "optionC": "LPC型喷嘴", "optionD": "SY型喷嘴", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 146, "type": "单选题", "content": "催化裂化稳定塔的轻关键组分是(　　)。", "optionA": "C3", "optionB": "C4", "optionC": "C5", "optionD": "C6", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 147, "type": "单选题", "content": "催化裂化稳定塔的重关键组分是(　　)", "optionA": "C3 ", "optionB": "C4 ", "optionC": "C5 ", "optionD": "C6", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 148, "type": "单选题", "content": "在结构族组成中，表示平均分子中总环上的碳原子数占总碳原子数的百分数的是(　　)。", "optionA": "CA% ", "optionB": "CN% ", "optionC": "CR% ", "optionD": "CP%", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 149, "type": "单选题", "content": "下列催化剂中，抗钒性能最好的是(　　)。", "optionA": "CRC-1", "optionB": "ZCM-7", "optionC": "CHZ-1", "optionD": "Y-15", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 150, "type": "单选题", "content": "金属铁被硫化氢腐蚀，化学反应式正确的是(　　)。", "optionA": "Fe＋H2S→H2＋FeS ", "optionB": "Fe＋H2S→H2＋Fe3O4 ", "optionC": "Fe＋H2S→H2O＋FeS ", "optionD": "3Fe＋4H2S→4H2＋Fe3S4", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 151, "type": "单选题", "content": "空速的单位是(　　)。", "optionA": "h", "optionB": "s", "optionC": "h－1 ", "optionD": "s-1", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 152, "type": "单选题", "content": "管壳式换热器中，结构最简单、使用较方便的是(　　)换热器。", "optionA": "U型管式", "optionB": "浮头式", "optionC": "固定管板式", "optionD": "板式", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 153, "type": "单选题", "content": "传热系数K的国际标准单位是(　　)。", "optionA": "W/l2·℃", "optionB": "kcal/l2·℃", "optionC": "kcal/m2·℃", "optionD": "W/m2·℃", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 154, "type": "单选题", "content": "汽油蒸气压过高，使用时会产生(　　)现象。", "optionA": "爆燃", "optionB": "气阻", "optionC": "积碳", "optionD": "抽空", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 155, "type": "单选题", "content": "干气密封属于(　　)密封结构。", "optionA": "泵入式接触", "optionB": "泵入式非接触", "optionC": "抽空式接触", "optionD": "抽空式非接触", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 156, "type": "单选题", "content": "反应温度升高，反应速度(　　)。", "optionA": "变大", "optionB": "不变", "optionC": "变小", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 157, "type": "单选题", "content": "下列选项中，具有较高的MON和RON，而且有高的调和辛烷值的是(　　)", "optionA": "丙烷", "optionB": "丁烷", "optionC": "正戊烷", "optionD": "正己烷", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 158, "type": "单选题", "content": "换热器内物流流向，对数平均温度差数值最大的是(　　)。", "optionA": "并流", "optionB": "错流", "optionC": "折流", "optionD": "逆流", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 159, "type": "单选题", "content": "装置停仪表风，变频控制的原料油流量变化趋势是(　　)。", "optionA": "不变", "optionB": "变大", "optionC": "变小", "optionD": "先变大，在变小", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 160, "type": "单选题", "content": "为防止油浆系统结焦，控制油浆密度在(　　)为宜。", "optionA": "不大于1.05", "optionB": "不大于0.95", "optionC": "不大于0.9", "optionD": "无所谓", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 161, "type": "单选题", "content": "分馏收汽油脱水时，人不能离开，目的是(　　)。", "optionA": "采样分析", "optionB": "防止跑油", "optionC": "查漏", "optionD": "巡检", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 162, "type": "单选题", "content": "下列选项中，不利于减少反应生焦的措施是(　　)。", "optionA": "采用干气预提升", "optionB": "进料位置下移", "optionC": "改进汽提效果", "optionD": "实行排油浆操作", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 163, "type": "单选题", "content": "烘衬里时，用来控制反应压力的是(　　)。", "optionA": "沉降器顶放空自控阀", "optionB": "分馏塔顶蝶(闸)阀", "optionC": "气压机入口放火炬阀", "optionD": "气压机出口放火炬阀", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 164, "type": "单选题", "content": "反应喷油开气压机后，用来控制反应压力的是(　　)。", "optionA": "沉降器顶放空自控阀", "optionB": "气压机反飞动控制阀和转速", "optionC": "再生滑阀", "optionD": "气压机出口放火炬阀", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 165, "type": "单选题", "content": "反应喷油后，开气压机前，用来控制反应压力的是(　　)。", "optionA": "沉降器顶放空自控阀", "optionB": "再生滑阀", "optionC": "气压机入口放火炬阀", "optionD": "气压机出口放火炬阀", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 166, "type": "单选题", "content": "油浆固体含量高，而操作无异常波动，其原因是(　　)。", "optionA": "沉降器旋风分离器出现故障", "optionB": "再生器旋风分离器出现故障", "optionC": "油浆泵运转不正常", "optionD": "油浆换热器出现泄漏", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 167, "type": "单选题", "content": "对均一直径流化床，床层高度与床层密度的关系，下列叙述正确的是(　　)。", "optionA": "成反比", "optionB": "成正比", "optionC": "无关", "optionD": "成对数关系", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 168, "type": "单选题", "content": "分馏收汽油时，分馏塔顶循环进出大阀的状态是(　　)。", "optionA": "抽出阀开，返回阀关", "optionB": "抽出阀和返回阀均关", "optionC": "抽出阀关，返回阀开", "optionD": "抽出阀和返回阀均开", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 169, "type": "单选题", "content": "分馏收柴油时，分馏塔一中段循环进出大阀的状态是(　　)。", "optionA": "抽出阀开，返回阀关", "optionB": "抽出阀和返回阀均关", "optionC": "抽出阀关，返回阀开", "optionD": "抽出阀和返回阀均开", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 170, "type": "单选题", "content": "下列选项中，关于流化床性质，叙述正确的是(　　)。", "optionA": "床层的压降随流体速度的增加而增加", "optionB": "床层高度和空隙率随流速增大而增大", "optionC": "床层高度和空隙率随流速增大而增大，但床层压降基本不随流速而变", "optionD": "床层高度基本不随流速而变", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 171, "type": "单选题", "content": "分馏收汽油一般收的是(　　)。", "optionA": "粗汽油", "optionB": "石脑油", "optionC": "精制汽油", "optionD": "航空汽油", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 172, "type": "单选题", "content": "在进料情况下，提升管出口温度低温自保的目的是防止(　　)。", "optionA": "催化剂带油", "optionB": "再生温度过低", "optionC": "产品质量变差", "optionD": "提升管流化不畅", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 173, "type": "单选题", "content": "下列选项中，与噎塞速度有关的因素是(　　)。", "optionA": "催化剂的孔体积", "optionB": "管道直径", "optionC": "管道的材质", "optionD": "催化剂的比表面积", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 174, "type": "单选题", "content": "国内计算停留时间的提升管长度指的是(　　)至提升管出口的长度。", "optionA": "催化剂进提升管处的最高点", "optionB": "催化剂进提升管处的中心点", "optionC": "提升管底部口", "optionD": "原料油喷入口", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 175, "type": "单选题", "content": "下列选项中，不会引起提升管催化剂中断的原因是(　　)。", "optionA": "催化剂循环量大", "optionB": "预提升介质小", "optionC": "进料量太小", "optionD": "预提升介质大", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 176, "type": "单选题", "content": "下列选项中，关于催化裂化新鲜催化剂的化学组成，叙述不正确的是(　　)。", "optionA": "催化剂中少量结构水的存在，对于形成质子酸活性中心很重要", "optionB": "催化剂中的Na2O是催化剂必要组成，含量高低对催化剂性质影响不大", "optionC": "催化剂中的Fe2O3在高温下分解并沉积在催化剂上，累积到一定程度会引起催化剂中毒", "optionD": "催化剂中的SO42-危害较大，应尽量降低这种杂质的含量。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 177, "type": "单选题", "content": "下列选项中，不会影响金属钝化剂使用效果的是(　　)。", "optionA": "催化剂重金属种", "optionB": "催化剂的污染程度", "optionC": "钝化剂的品筛分组成", "optionD": "钝化剂在原料中的分散度", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 178, "type": "单选题", "content": "同一种催化剂，使用直管法分析的磨损指数与使用鹅径管法分析得到的结果相比要(　　)。", "optionA": "大", "optionB": "小", "optionC": "一样", "optionD": "不一定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 179, "type": "单选题", "content": "一般来说，半合成催化剂的磨损指数与全合成催化剂相比要(　　)。", "optionA": "大", "optionB": "小", "optionC": "一样", "optionD": "不一定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 180, "type": "单选题", "content": "流化床线速高，其中的气泡(　　)，流速(　　)。", "optionA": "大，快", "optionB": "小，快", "optionC": "大，慢", "optionD": "小，慢", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 181, "type": "单选题", "content": "下列选项中，关于顶循环油的密度，叙述正确的是(　　)。", "optionA": "大于粗汽油", "optionB": "大于轻柴油", "optionC": "小于粗汽油", "optionD": "在0.9左右", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 182, "type": "单选题", "content": "待生套筒穿孔，一般发生位置是在(　　)。", "optionA": "待生立管上部", "optionB": "待生立管中部", "optionC": "待生立管下部", "optionD": "汽提段锥体处", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 183, "type": "单选题", "content": "冷壁滑阀的阀体内是(　　)。", "optionA": "单层耐磨衬里", "optionB": "隔热耐磨衬里", "optionC": "单层隔热衬里", "optionD": "无衬里", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 184, "type": "单选题", "content": "下列选项中，关于导热系数λ，叙述错误的是(　　)。", "optionA": "导热系数λ数值越大，说明该物质导热能力越强", "optionB": "乙醇的导热系数λ随温度的升高而降低", "optionC": "甘油的导热系数λ随温度的升高而升高", "optionD": "水的导热系数λ随温度的升高而降低", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 185, "type": "单选题", "content": "提升管出口采用(　　)分离装置，可以有效减少旋分器的料腿结焦。", "optionA": "倒L型", "optionB": "伞帽", "optionC": "两级旋分器", "optionD": "粗旋加单旋", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 186, "type": "单选题", "content": "原料油中链烷烃分子大时汽油中烯烃含量(　　)。", "optionA": "低", "optionB": "不变", "optionC": "高", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 187, "type": "单选题", "content": "原料油中链烷烃含量高时，汽油中烯烃含量(　　)。", "optionA": "低", "optionB": "高", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 188, "type": "单选题", "content": "氢含量高、K值大的原料油汽油烯烃含量(　　)。", "optionA": "低", "optionB": "高", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 189, "type": "单选题", "content": "稳定塔底部重沸器返塔气体温度高，汽油蒸气压(　　)。", "optionA": "低", "optionB": "无变化", "optionC": "高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 190, "type": "单选题", "content": "稳定塔压力高，汽油蒸气压(　　)。", "optionA": "低", "optionB": "无变化", "optionC": "高", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 191, "type": "单选题", "content": "稳定塔顶温低，汽油蒸气压(　　)。", "optionA": "低", "optionB": "无变化", "optionC": "高", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 192, "type": "单选题", "content": "轴流式风机与离心式风机相比，单级叶轮压缩比(　　)。", "optionA": "低", "optionB": "一样", "optionC": "高", "optionD": "说不清", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 193, "type": "单选题", "content": "分馏塔较重馏分的初馏点(　　)较轻馏分的终馏点，说明这两个馏分之间有一定的间隙。", "optionA": "低于", "optionB": "等于", "optionC": "高于", "optionD": "不确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 194, "type": "单选题", "content": "分馏塔较重馏分的初馏点(　　)较轻馏分的终馏点，说明这两个馏分之间有重叠。", "optionA": "低于", "optionB": "等于", "optionC": "高于", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 195, "type": "单选题", "content": "对于同轴式催化裂化装置，主风中断后，两器压力的调整方式是保证再生器压力(　　)。", "optionA": "低于沉降器压力", "optionB": "高于沉降器压力", "optionC": "高于分馏塔压力", "optionD": "低于分馏塔压力", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 196, "type": "单选题", "content": "在汽轮机升速暖机时，不允许在(　　)状态下工作。", "optionA": "低于临界转速", "optionB": "等于临界转速", "optionC": "高于临界转速", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 197, "type": "单选题", "content": "主风中断后，可以采用(　　)的方法来延长事故处理时间。", "optionA": "点辅助燃烧室", "optionB": "闷床", "optionC": "关闭主风事故蒸汽", "optionD": "卸催化剂", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 198, "type": "单选题", "content": "电液执行机构的动力是(　　)。", "optionA": "电动机", "optionB": "动力风", "optionC": "液压油", "optionD": "仪表风", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 199, "type": "单选题", "content": "当分馏系统出现(　　)情况时，会造成反应系统切断进料。", "optionA": "顶回流抽空", "optionB": "一中段抽空", "optionC": "塔底液面过高，造成液封", "optionD": "油浆泵抽空", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 200, "type": "单选题", "content": "稳定塔顶空冷顶水操作，主要是通过观察(　　)压力来控制顶水压力。", "optionA": "顶水泵泵出口", "optionB": "空冷进口", "optionC": "空冷出口", "optionD": "顶水泵泵进口", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 201, "type": "单选题", "content": "分馏收汽油是指(　　)系统收汽油。", "optionA": "顶循环回流", "optionB": "一中段循环回流", "optionC": "二中段循环回流", "optionD": "油浆循环回流", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 202, "type": "单选题", "content": "分馏收柴油是指(　　)系统收柴油。", "optionA": "顶循环回流", "optionB": "一中段循环回流", "optionC": "二中段循环回流", "optionD": "油浆循环回流", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 203, "type": "单选题", "content": "在渣油催化裂化装置中，分馏取热量最大的是(　　)。", "optionA": "顶循环回流", "optionB": "一中循环回流", "optionC": "二中循环回流", "optionD": "塔底循环回流", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 204, "type": "单选题", "content": "开工时，分馏塔最早建立的循环是(　　)。", "optionA": "顶循环回流", "optionB": "一中循环回流", "optionC": "二中循环回流", "optionD": "塔底循环回流", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 205, "type": "单选题", "content": "冷壁滑阀的传动机构有风动驱动的，这里的“风”指的是(　　)。", "optionA": "动力风", "optionB": "净化风", "optionC": "增压风", "optionD": "主风", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 206, "type": "单选题", "content": "塞阀按照在催化裂化工艺中的作用，分为待生塞阀与再生塞阀两类，相同通径的再生塞阀与待生塞阀相比全行程(　　)。", "optionA": "短", "optionB": "一样", "optionC": "长", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 207, "type": "单选题", "content": "高低并列式催化裂化装置与同高并列式催化裂化装置相比，催化剂输送线路上松动点设置是(　　)。", "optionA": "多", "optionB": "一样", "optionC": "少", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 208, "type": "单选题", "content": "APC的核心是(　　)。", "optionA": "多变量模型控制器", "optionB": "集散控制系统", "optionC": "多变量模型预估控制器", "optionD": "多变量模型预估器", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 209, "type": "单选题", "content": "当输送液体的(　　)超过原设计值时，会造成泵的功耗过大。", "optionA": "额定流量", "optionB": "额定效率", "optionC": "额定扬程", "optionD": "相对密度", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 210, "type": "单选题", "content": "富氧再生设有安全报警及联锁系统，为安全起见，混合温度超高联锁采用(　　)。", "optionA": "二选一", "optionB": "二选二", "optionC": "三选一", "optionD": "三选二", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 211, "type": "单选题", "content": "余热锅炉过热段的作用是(　　)。", "optionA": "发生饱和蒸汽", "optionB": "过热饱和蒸汽", "optionC": "过热主风", "optionD": "过热除氧水", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 212, "type": "单选题", "content": "冷壁滑阀主要部件不包括(　　)。", "optionA": "阀杆", "optionB": "阀板", "optionC": "阀盘", "optionD": "阀盖", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 213, "type": "单选题", "content": "塞阀按照配置的执行机构分，可分为电液塞阀与风动塞阀两类，电液塞阀与风动塞阀的区别是没有(　　)。", "optionA": "阀体部分", "optionB": "传动部分", "optionC": "定位及阀位变送部分", "optionD": "补偿弹簧箱", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 214, "type": "单选题", "content": "下列选项中，对钒破坏催化剂叙述错误的是(　　)。", "optionA": "钒主要起脱氢作用", "optionB": "钒在氧环境下生成V2O5，熔融导致催化剂永久失活 ", "optionC": "钒在水蒸气气氛下，生成一种强酸VO（OH）3，破坏沸石晶体结构 ", "optionD": "钒与钠协同作用形成低熔点共熔物，减少活性中心，降低催化剂的热稳定性", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 215, "type": "单选题", "content": "气压机停机前，入口压力由(　　)控制。", "optionA": "反飞动流量", "optionB": "气压机出口放火炬", "optionC": "气压机入口放火炬", "optionD": "入口风动闸阀", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 216, "type": "单选题", "content": "再生线路催化剂中断的现象是(　　)。", "optionA": "反应温度、压力上升", "optionB": "待生塞阀或滑阀开大", "optionC": "再生滑阀开大且压降下降", "optionD": "再生器藏量下降", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 217, "type": "单选题", "content": "切断进料主风仍保持时，再生温度最低不能低于400℃，目的是(　　)。", "optionA": "防止发生碳堆", "optionB": "保证喷燃烧油", "optionC": "防止再生器催化剂跑损", "optionD": "防止再生器流化不畅", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 218, "type": "单选题", "content": "分馏收汽油时，要注意将水放净，主要目的是(　　)。", "optionA": "防止开泵时机泵抽空", "optionB": "防止开泵时机泵过载", "optionC": "防止影响换热效果", "optionD": "避免影响分馏塔压力。", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 219, "type": "单选题", "content": "机组停机后，盘车目的是(　　)。", "optionA": "防止轴弯曲", "optionB": "促进轴瓦冷却", "optionC": "防止泄漏", "optionD": "加快润滑油回油速度", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 220, "type": "单选题", "content": "气压机自保后，注意防止反应压力超高，可以通过(　　)来控制。", "optionA": "分顶蝶阀", "optionB": "气压机反飞动", "optionC": "入口放火炬", "optionD": "出口放火炬", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 221, "type": "单选题", "content": "烷烃的催化裂化反应是(　　)。", "optionA": "分解反应", "optionB": "异构化反应", "optionC": "氢转移反应", "optionD": "芳构化反应", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 222, "type": "单选题", "content": "停工热水洗时，充水液位不能超过(　　)以上。", "optionA": "分馏塔顶循环回流抽出板", "optionB": "轻柴油抽出板", "optionC": "一中段循环回流抽出板", "optionD": "二中段循环回流抽出板", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 223, "type": "单选题", "content": "吸收稳定系统收汽油时，一般不将外装置汽油引入(　　)。", "optionA": "分馏塔顶油气分离器", "optionB": "气压机出口气液平衡罐", "optionC": "吸收塔", "optionD": "稳定塔", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 224, "type": "单选题", "content": "集散控制系统又称为(　　)系统。", "optionA": "分散型控制", "optionB": "分散型管理", "optionC": "集中型控制", "optionD": "集中型管理", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 225, "type": "单选题", "content": "装置停仪表风，应立即将风开控制阀改为(　　)控制。", "optionA": "副线", "optionB": "上游阀", "optionC": "下游阀", "optionD": "泵出口", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 226, "type": "单选题", "content": "发现待生套筒流化风和吹扫风中断时，正确的处理方法是(　　)。", "optionA": "改就地手动进行操作，维持操作", "optionB": "改流化蒸汽吹扫，维持操作", "optionC": "切断进料", "optionD": "只要阀门控制正常，维持操作", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 227, "type": "单选题", "content": "发现控制单动滑阀的仪表失灵时，正确处理方法是(　　)。", "optionA": "改就地手动进行操作，维持操作即可", "optionB": "改流化蒸汽吹扫，维持操作", "optionC": "切断进料", "optionD": "就地改手动进行操作，联系仪表进行处理", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 228, "type": "单选题", "content": "非金属中，导热系数最大的是(　　)。", "optionA": "甘油", "optionB": "汽油", "optionC": "乙醇", "optionD": "水", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 229, "type": "单选题", "content": "在输送管的转弯处，固体浓度比直管段(　　)。", "optionA": "高", "optionB": "低", "optionC": "一样", "optionD": "无法确定", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 230, "type": "单选题", "content": "汽油蒸气压高，存储时损失(　　)。", "optionA": "高", "optionB": "低", "optionC": "一样", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 231, "type": "单选题", "content": "我国夏季汽油的汽油蒸气压一般比冬季的要(　　)。", "optionA": "高", "optionB": "低", "optionC": "一样", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 232, "type": "单选题", "content": "冷壁滑阀与热壁滑阀相比，阀体壁温(　　)，阀体的尺寸(　　)。", "optionA": "高，小", "optionB": "高，大", "optionC": "低，小", "optionD": "低，大", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 233, "type": "单选题", "content": "我国早期床层反应催化裂化采用的装置类型是(　　)。", "optionA": "高低并列式", "optionB": "同高并列式", "optionC": "同轴式", "optionD": "烧焦罐式", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 234, "type": "单选题", "content": "对于使用干气密封系统的气压机组，在润滑油循环以前要先投用(　　)。", "optionA": "隔离气", "optionB": "高压端密封气", "optionC": "低压端密封气", "optionD": "缓冲气", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 235, "type": "单选题", "content": "下列选项中，不属于同一类型换热器的是(　　)。", "optionA": "固定管板式换热器", "optionB": "套管式换热器", "optionC": "Ｕ型管换热器", "optionD": "浮头式换热器", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 236, "type": "单选题", "content": "通常情况下，导热系数λ由大到小的顺序为(　　)。", "optionA": "固体、气体、液体", "optionB": "固体、液体、气体", "optionC": "气体、液体、固体", "optionD": "气体、固体、液体", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 237, "type": "单选题", "content": "气泡在流化床中与(　　)进行质的交换。", "optionA": "固体和液体", "optionB": "气体和液体", "optionC": "固体和气体", "optionD": "固体、气体和液体", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 238, "type": "单选题", "content": "下列选项中，造成固体颗粒和气体返混的主要原因是(　　)。", "optionA": "固体颗粒速度的差异", "optionB": "气体速度的变化", "optionC": "固体颗粒直径大小不一", "optionD": "气泡在流化床中的运动", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 239, "type": "单选题", "content": "下列选项中，关于流化床定义正确的是(　　)。", "optionA": "固体粒子处于堆紧状态，颗粒静止不动的床层", "optionB": "流体与固体颗粒同时进入反应器，流体与固体颗粒相互接触一面反应一面移动的床层", "optionC": "床层颗粒之间脱离接触，颗粒悬浮在流体中，往各个方向运动的床层", "optionD": "床层颗粒之间脱离接触，颗粒之间均匀分布，颗粒间充满流体的床层", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 240, "type": "单选题", "content": "分馏塔顶油气分离器液面暴涨未满罐时，当气压机入口压力低，应(　　)，保证气压机入口压力。", "optionA": "关小分顶蝶阀", "optionB": "关小气压机入口阀", "optionC": "提高反飞动流量", "optionD": "打开放火炬阀", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 241, "type": "单选题", "content": "装置停动力风时，对于使用动力风的松动点，正确处理方法是(　　)。", "optionA": "关正线，开副线", "optionB": "正副线均关闭", "optionC": "正副线都开", "optionD": "改用备用介质松动", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 242, "type": "单选题", "content": "汽轮机开机时，调速器一般在(　　)转速时投入使用。", "optionA": "规定", "optionB": "临界", "optionC": "汽轮机工作", "optionD": "零", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 243, "type": "单选题", "content": "氧气输送管道、储罐、以及附件选材全部执行(　　)，以减少氧气腐蚀，保证安全。", "optionA": "国际标准", "optionB": "国家标准", "optionC": "行业标准", "optionD": "公司标准", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 244, "type": "单选题", "content": "大油气管采用冷壁设计比采用热壁设计的防焦效果(　　)。", "optionA": "好", "optionB": "相等", "optionC": "差", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 245, "type": "单选题", "content": "下列选项中，描述错误的是(　　)。", "optionA": "化学除氧的原理，是给水通过一定的化学反应而达到除氧的目的", "optionB": "热力除氧的原理，是将水加热到沸腾的温度，使气体在水中的溶解度降到零，从而达到除去水中氧气和二氧化碳的目的", "optionC": "真空除氧是在常温下，利用抽真空的方法，使水呈沸腾状态以除去其中溶解的气体", "optionD": "热力除氧器结构只有淋水盘式一种", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 246, "type": "单选题", "content": "拆大盲板前，必须先确认(　　)通畅无油。", "optionA": "回炼油罐底排凝", "optionB": "分馏塔顶油气分离罐底放空", "optionC": "分馏塔底排凝", "optionD": "轻柴油汽提塔底放空", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 247, "type": "单选题", "content": "气压机开机时，需全开(　　)。", "optionA": "机入口放火炬阀", "optionB": "机出口放火炬阀", "optionC": "机出口至反飞动阀", "optionD": "机出口至稳定大阀", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 248, "type": "单选题", "content": "APC是(　　)的英文简写。", "optionA": "集散控制系统", "optionB": "计算机报警系统", "optionC": "先进控制系统", "optionD": "自动联锁保护系统", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 249, "type": "单选题", "content": "DCS是以(　　)控制，(　　)监视、操作和管理达到控制全局的目的。", "optionA": "集中，分散", "optionB": "集中，集中", "optionC": "分散，分散", "optionD": "分散，集中", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 250, "type": "单选题", "content": "空冷迎风速过低的后果是(　　)。", "optionA": "加剧空冷管束腐蚀", "optionB": "引起空冷管束表面结垢", "optionC": "增加风机消耗", "optionD": "降低空冷冷却效果", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 251, "type": "单选题", "content": "根据冷、热流体热量交换原理和方式将换热器进行分类，下述正确的是(　　)。", "optionA": "间壁式、夹套式、蓄热式", "optionB": "间壁式、混合式、蓄热式", "optionC": "夹套式、混合式、蓄热式", "optionD": "间壁式、混合式、夹套式", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 252, "type": "单选题", "content": "提高再生藏量，烧焦量(　　)。", "optionA": "减少", "optionB": "增加", "optionC": "不变", "optionD": "不确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 253, "type": "单选题", "content": "换热器中垢层的存在，会造成换热器传热热阻(　　)，传热量(　　)。", "optionA": "减少，减少", "optionB": "增加，减少", "optionC": "减少，增加", "optionD": "增加，增加", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 254, "type": "单选题", "content": "在保证柴油凝点和闪点合格的情况下，通过(　　)柴油初馏点可以提高轻柴油收率。", "optionA": "降低", "optionB": "保持", "optionC": "提高", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 255, "type": "单选题", "content": "在保证柴油凝点和闪点合格的情况下，通过(　　)汽油干点可以提高轻柴油收率。", "optionA": "降低", "optionB": "保持", "optionC": "提高", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 256, "type": "单选题", "content": "在保证柴油凝点和闪点合格的情况下，通过(　　)柴油干点可以提高轻柴油收率。", "optionA": "降低", "optionB": "保持", "optionC": "提高", "optionD": "不确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 257, "type": "单选题", "content": "在保证柴油最低闪点要求下，通过(　　)汽提蒸汽，可以提高轻柴油收率。", "optionA": "降低", "optionB": "保持", "optionC": "提高", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 258, "type": "单选题", "content": "提高吸收效果的操作是(　　)吸收温度。", "optionA": "降低", "optionB": "保持", "optionC": "提高", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 259, "type": "单选题", "content": "提高吸收效果的操作是(　　)吸收系统操作压力。", "optionA": "降低", "optionB": "保持", "optionC": "提高", "optionD": "不确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 260, "type": "单选题", "content": "提高吸收效果的操作是(　　)解吸塔底温度。", "optionA": "降低", "optionB": "保持", "optionC": "提高", "optionD": "不确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 261, "type": "单选题", "content": "提高吸收效果的操作是(　　)吸收剂质量。", "optionA": "降低", "optionB": "保持", "optionC": "提高", "optionD": "不确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 262, "type": "单选题", "content": "采用高效汽提技术，降低催化剂含炭量，(　　)剂油比，达到减少结焦的目的。", "optionA": "降低", "optionB": "保持", "optionC": "提高", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 263, "type": "单选题", "content": "提高汽油终馏点，相应的汽油烯烃含量(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "升高", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 264, "type": "单选题", "content": "汽油终馏点降低，相应的汽油烯烃含量(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "升高", "optionD": "不确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 265, "type": "单选题", "content": "粗汽油量增加，则吸收塔顶温度降低，使吸收塔液气比(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "增大", "optionD": "不确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 266, "type": "单选题", "content": "粗汽油带水，会使吸收塔顶温度(　　)，操作产生波动。", "optionA": "降低", "optionB": "不变", "optionC": "增大", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 267, "type": "单选题", "content": "分馏一中段泵抽空，会引起稳定塔回流罐液位(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "增大", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 268, "type": "单选题", "content": "汽油辛烷值随蒸气压升高而(　　)。", "optionA": "降低", "optionB": "不变", "optionC": "增加", "optionD": "不确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 269, "type": "单选题", "content": "反应过程停留时间增加，汽油烯烃含量(　　)。", "optionA": "降低", "optionB": "上升", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 270, "type": "单选题", "content": "相同转化率情况下，反应温度上升，汽油烯烃含量(　　)。", "optionA": "降低", "optionB": "上升", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 271, "type": "单选题", "content": "增加剂油比，转化率(　　)。", "optionA": "降低", "optionB": "升高", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 272, "type": "单选题", "content": "增加剂油比，汽油辛烷值(　　)。", "optionA": "降低", "optionB": "升高", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 273, "type": "单选题", "content": "再生催化剂含炭量上升，汽油辛烷值(　　)。", "optionA": "降低", "optionB": "升高", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 274, "type": "单选题", "content": "采用富氧再生技术后，再生催化剂的烧焦强度(　　)。", "optionA": "降低", "optionB": "提高", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 275, "type": "单选题", "content": "稳定塔进料位置上移，汽油蒸气压(　　)。", "optionA": "降低", "optionB": "无变化", "optionC": "升高", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 276, "type": "单选题", "content": "提升管增加蒸汽量，导致反应生焦量(　　)。", "optionA": "降低", "optionB": "增加", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 277, "type": "单选题", "content": "增加回炼比，轻柴油凝固点(　　)，十六烷值(　　)。", "optionA": "降低，增加", "optionB": "降低，降低", "optionC": "增加，增加", "optionD": "增加，降低", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 278, "type": "单选题", "content": "增加回炼比，其他操作条件不变，催化裂化反应转化率(　　)，处理能力(　　)。", "optionA": "降低，增加", "optionB": "降低，降低", "optionC": "增加，增加", "optionD": "增加，降低", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 279, "type": "单选题", "content": "主风中断后，反应岗位应立即投用(　　)自保。", "optionA": "进料", "optionB": "两器切断", "optionC": "主风", "optionD": "增压风", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 280, "type": "单选题", "content": "吸收稳定系统收汽油时，系统内的介质是(　　)。", "optionA": "空气", "optionB": "瓦斯", "optionC": "蒸汽", "optionD": "液态烃", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 281, "type": "单选题", "content": "拆大盲板时，沉降器至大盲板处油气管线内的介质是(　　)。", "optionA": "空气", "optionB": "油气", "optionC": "蒸汽", "optionD": "氮气", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 282, "type": "单选题", "content": "拆大盲板时，分馏塔底的介质是(　　)。", "optionA": "空气", "optionB": "油气", "optionC": "蒸汽", "optionD": "原料油", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 283, "type": "单选题", "content": "再生温度高，焦炭燃烧速度(　　)。", "optionA": "快", "optionB": "慢", "optionC": "不变", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 284, "type": "单选题", "content": "催化剂含碳量高，烧焦速度(　　)。", "optionA": "快", "optionB": "慢", "optionC": "不变", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 285, "type": "单选题", "content": "下列选项中，对于分馏塔采用顶循环回流而不用冷回流，叙述错误的是(　　)。", "optionA": "利于回收热量", "optionB": "减少塔顶冷却面积", "optionC": "减少塔顶至气压机入口压降", "optionD": "可以提高精馏作用", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 286, "type": "单选题", "content": "同轴式催化裂化装置，一般用(　　)来控制反应藏量。", "optionA": "两器差压", "optionB": "塞阀节流", "optionC": "滑阀节流", "optionD": "滑阀差压低选保护", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 287, "type": "单选题", "content": "高低并列式催化裂化装置，一般用(　　)来控制反应藏量。", "optionA": "两器差压", "optionB": "塞阀节流", "optionC": "滑阀节流", "optionD": "塞阀差压低选保护", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 288, "type": "单选题", "content": "下列选项中，关于轴流式风机与离心式风机的区别，叙述错误的是(　　)。", "optionA": "两者机内气体流向不同", "optionB": "轴流式风机在要求流量大时造价低", "optionC": "轴流式风机在不同工况下，有较稳定的效率值", "optionD": "离心式风机易损部件少，使用期限长", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 289, "type": "单选题", "content": "重油催化裂化装置的污水中，不含较高浓度的(　　)。", "optionA": "硫化物", "optionB": "氰化物", "optionC": "氢氟酸", "optionD": "挥发酚", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 290, "type": "单选题", "content": "催化裂化装置的大气污染源不包括(　　)。", "optionA": "硫氧化物", "optionB": "氮氧化物", "optionC": "碳氧化物", "optionD": "碳氢化合物", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 291, "type": "单选题", "content": "催化裂化分馏一中段回流的作用是控制轻柴油的(　　)。", "optionA": "凝固点", "optionB": "闪点", "optionC": "胶质", "optionD": "十六烷值", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 292, "type": "单选题", "content": "烟机防止烟气泄漏，在轴伸出部位装有(　　)密封环，并向内圈注入蒸汽。", "optionA": "平滑式", "optionB": "阶梯式", "optionC": "迷宫式", "optionD": "浮动环式", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 293, "type": "单选题", "content": "气压机停机前，必须试验(　　)，应灵活无卡阻现象。", "optionA": "气压机出口安全阀", "optionB": "气压机出口放火炬阀", "optionC": "气压机入口放火炬阀", "optionD": "气压机入口风动闸阀", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 294, "type": "单选题", "content": "稳定塔顶不凝气排放至(　　)，会影响分馏塔压力。", "optionA": "气压机入口", "optionB": "气压机机间冷却器", "optionC": "瓦斯管网", "optionD": "放火炬管道", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 295, "type": "单选题", "content": "待生线路催化剂中断的现象是(　　)。", "optionA": "汽提段藏量上升，待生线路压降上升", "optionB": "汽提段藏量上升，待生线路压降下降", "optionC": "待生塞阀或滑阀关小", "optionD": "再生器藏量、密度上升", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 296, "type": "单选题", "content": "通常，停主风机组是在(　　)之后。", "optionA": "切断进料", "optionB": "再生器催化剂卸尽", "optionC": "分馏系统退油结束", "optionD": "稳定退油结束", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 297, "type": "单选题", "content": "如果分馏塔顶油气分离器液面上涨过快，紧急情况下可用(　　)降低液面。", "optionA": "轻柴油泵", "optionB": "顶循环回流泵", "optionC": "酸性水泵", "optionD": "油浆泵", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 298, "type": "单选题", "content": "反应温度越低，有利于以下反应的是(　　)。", "optionA": "氢转移", "optionB": "裂化", "optionC": "芳构化", "optionD": "热裂化", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 299, "type": "单选题", "content": "下列选项中，不属于分馏塔塔底回流用途的是(　　)。", "optionA": "取出大量高温位热量供回收利用", "optionB": "与反应来油气换热", "optionC": "将油气中携带的催化剂颗粒冲洗下来", "optionD": "控制柴油凝固点", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 300, "type": "单选题", "content": "下列选项中，对于催化剂抗镍性能大小次序正确的是(　　)。", "optionA": "全白土型>半合成型>全合成型", "optionB": "全白土型>全合成型>半合成型", "optionC": "全合成型>全白土型>半合成型", "optionD": "半合成型>全合成型>全白土型", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 301, "type": "单选题", "content": "装置停循环水，对于冷却水使用循环水的机泵，应及时将进水改(　　)，回水就地排放。", "optionA": "软化水", "optionB": "除氧水", "optionC": "新鲜水", "optionD": "凝结水", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 302, "type": "单选题", "content": "同高并列式催化裂化装置，一般用(　　)控制反应藏量。", "optionA": "塞阀节流", "optionB": "滑阀节流", "optionC": "两器差压", "optionD": "滑阀差压低选保护", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 303, "type": "单选题", "content": "下列提升管出口快速分离设备中，分离效果最好，且有利于减少反应生焦的是(　　)。", "optionA": "伞帽式", "optionB": "T型弯头式", "optionC": "倒L型", "optionD": "弹射式", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 304, "type": "单选题", "content": "催化剂活性太高，会导致柴油产率(　　)。", "optionA": "上升", "optionB": "不变", "optionC": "下降", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 305, "type": "单选题", "content": "催化剂活性太高，会导致焦炭产率(　　)。", "optionA": "上升", "optionB": "不变", "optionC": "下降", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 306, "type": "单选题", "content": "催化剂活性太高，会导致气体产率(　　)。", "optionA": "上升", "optionB": "不变", "optionC": "下降", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 307, "type": "单选题", "content": "增加回炼比，提升管出口温度不变，再生温度(　　)。", "optionA": "上升", "optionB": "不变", "optionC": "下降", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 308, "type": "单选题", "content": "转化率不变，反应温度升高，焦炭产率(　　)。", "optionA": "上升", "optionB": "不变", "optionC": "下降", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 309, "type": "单选题", "content": "转化率不变，反应温度升高，轻柴油的收率(　　)。", "optionA": "上升", "optionB": "不变", "optionC": "下降", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 310, "type": "单选题", "content": "催化裂化反应转化率上升，反应温度(　　)。", "optionA": "上升", "optionB": "不变", "optionC": "下降", "optionD": "无法确定", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 311, "type": "单选题", "content": "提高回炼比，分馏塔总的取热量(　　)", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "不确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 312, "type": "单选题", "content": "总进料流量不变，反应温度升高，反应深度增加，分馏塔底液面(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "不确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 313, "type": "单选题", "content": "总进料流量不变，反应温度升高，反应深度增加，分馏塔回炼油罐液面(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "不确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 314, "type": "单选题", "content": "当油浆蒸汽发生器发生干锅时，会引起分馏塔底液面(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "不确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 315, "type": "单选题", "content": "原料中烷烃上升，会导致汽油辛烷值(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 316, "type": "单选题", "content": "原料中芳烃上升，会导致汽油辛烷值(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 317, "type": "单选题", "content": "催化剂活性升高，汽油辛烷值(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 318, "type": "单选题", "content": "缩短在提升管的油气停留时间，汽油辛烷值(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 319, "type": "单选题", "content": "转化率一定时，反应温度升高，汽油辛烷值(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 320, "type": "单选题", "content": "采用干气预提升技术时，干气带液会导致预提升段温度(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 321, "type": "单选题", "content": "分馏塔冷回流流量增大，导致反应压力(　　)。", "optionA": "上升", "optionB": "下降", "optionC": "不变", "optionD": "先降后升", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 322, "type": "单选题", "content": "若分馏塔顶采用全凝器，则塔顶馏出组成及回流液组成均与塔顶第一层(　　)组成相同。", "optionA": "上升蒸气", "optionB": "下降液体", "optionC": "上升液体", "optionD": "下降蒸气", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 323, "type": "单选题", "content": "当孔板压力降超过临界压力降时，当孔板出口压力降低后，通过孔板流体的流量将(　　)。", "optionA": "升高", "optionB": "降低", "optionC": "不变", "optionD": "先升高后降低", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 324, "type": "单选题", "content": "余热锅炉温度最低的部位是(　　)。", "optionA": "省煤器", "optionB": "过热段", "optionC": "上锅筒", "optionD": "下锅筒", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 325, "type": "单选题", "content": "通常，停烟机前，需将主风机流量(　　)。", "optionA": "适当降低", "optionB": "适当增加", "optionC": "保持不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 326, "type": "单选题", "content": "电液执行机构分为整体式和分离式两种，分离式的控制柜中包括(　　)和框架。", "optionA": "伺服油缸、电气控制系统", "optionB": "电气控制系统、液压系统", "optionC": "伺服油缸、液压系统", "optionD": "电气控制系统、液压系统、伺服油缸", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 327, "type": "单选题", "content": "电液执行机构，分为整体式和分离式两种，分离式是将(　　)和阀安装在一起。", "optionA": "伺服油缸、手动机构", "optionB": "伺服油缸、液压系统", "optionC": "手动系统、液压系统", "optionD": "手动系统、液压系统、伺服油缸", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 328, "type": "单选题", "content": "实际生产中，油浆三通阀尽量不走热流的原因是(　　)。", "optionA": "提高油浆在换热器管内的流速，防止结焦", "optionB": "防止热流管线磨损", "optionC": "减少回收能量", "optionD": "减少三通阀的磨损", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 329, "type": "单选题", "content": "采用提升管，反应温度与再生滑阀压降控制反应温度时，滑阀开度由(　　)控制。", "optionA": "提升管出口温度输出值", "optionB": "再生滑阀压降开度输出值", "optionC": "提升管出口温度输出值与再生滑阀压降开度输出值两者中较低的输出值", "optionD": "提升管出口温度输出值与再生滑阀压降开度输出值两者的差值", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 330, "type": "单选题", "content": "国内计算停留时间的油气平均线速指的是(　　)。", "optionA": "提升管出口油气线速", "optionB": "提升管出口与原料油喷入口两处线速的算术平均值", "optionC": "提升管出口与原料油喷入口两处线速的对数平均值", "optionD": "原料油喷入口处线速", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 331, "type": "单选题", "content": "国外计算停留时间的油气平均线速指的是(　　)。", "optionA": "提升管出口油气线速", "optionB": "提升管出口与原料油喷入口两处线速的算术平均值", "optionC": "提升管出口与原料油喷入口两处线速的对数平均值", "optionD": "原料油喷入口处线速", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 332, "type": "单选题", "content": "催化裂化装置开工时，需要水洗的设备是(　　)。", "optionA": "提升管反应器", "optionB": "内取热器", "optionC": "主风机组", "optionD": "再生器", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 333, "type": "单选题", "content": "发生催化剂水热失活的主要位置是(　　)。", "optionA": "提升管反应器原料油喷嘴后部", "optionB": "再生器密相", "optionC": "沉降器汽提段", "optionD": "提升管反应器出口", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 334, "type": "单选题", "content": "WHSV表示(　　)。", "optionA": "体积空速", "optionB": "质量空速", "optionC": "空速", "optionD": "反应时间", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 335, "type": "单选题", "content": "VHSV表示(　　)。", "optionA": "体积空速", "optionB": "重量空速", "optionC": "空速", "optionD": "反应时间", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 336, "type": "单选题", "content": "油浆下返塔的作用是(　　)。", "optionA": "调节塔底液面", "optionB": "调节塔底温度", "optionC": "与反应来油气换热", "optionD": "调节油浆中固体含量", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 337, "type": "单选题", "content": "污染指数不包括(　　)的含量。", "optionA": "铁", "optionB": "镍", "optionC": "铅", "optionD": "钒", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 338, "type": "单选题", "content": "从污染指数看，以下各种金属中毒作用排列正确的是(　　)。", "optionA": "铁>镍>钒", "optionB": "镍>钒>铁", "optionC": "镍>铁>钒", "optionD": "钒>镍>铁", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 339, "type": "单选题", "content": "稳定塔又称为(　　)塔。", "optionA": "脱乙烷", "optionB": "脱丙烷", "optionC": "脱丁烷", "optionD": "脱戊烷", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 340, "type": "单选题", "content": "工程上，传热系数K都是相对于管道(　　)而言的。", "optionA": "外表面", "optionB": "内表面", "optionC": "内外表面算术平均值", "optionD": "内外表面对数平均值", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 341, "type": "单选题", "content": "如果硫扩散进入合金，导致一种或多种合金元素的硫化物在合金内部析出，这种过程叫做(　　)。", "optionA": "外硫化", "optionB": "晶届硫化", "optionC": "内硫化", "optionD": "晶体硫化", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 342, "type": "单选题", "content": "下列选项中，属于催化裂化反应的是(　　)。", "optionA": "烷烃脱氢生成烯烃", "optionB": "烃类分解为甲烷和碳", "optionC": "烯烃加氢", "optionD": "烯烃叠合", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 343, "type": "单选题", "content": "由于分馏塔顶某一组冷凝冷却器泄漏带水，造成油气分离器液面暴涨，正确处理方法是(　　)。", "optionA": "维持原状，加强观察", "optionB": "降量运行", "optionC": "切断进料", "optionD": "将漏的换热器切出系统", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 344, "type": "单选题", "content": "在高速流化床的高速区，气体流动具有相当程度的(　　)特性。", "optionA": "紊流", "optionB": "活塞流", "optionC": "湍流", "optionD": "沟流", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 345, "type": "单选题", "content": "粗汽油质量不合格会导致(　　)。", "optionA": "稳定汽油干点不合格", "optionB": "液态烃C5含量不合格", "optionC": "干气带液", "optionD": "稳定汽油蒸气压不合格", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 346, "type": "单选题", "content": "气泡的形状是(　　)。", "optionA": "无定形", "optionB": "上半部呈半球型，下半部凹入", "optionC": "上半部呈半球型，下半部突入", "optionD": "上半部呈半球型，下半部呈菱形", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 347, "type": "单选题", "content": "轴流式风机与离心式风机相比，因对空气中灰尘较敏感，所以在进口通常安装有(　　)。", "optionA": "吸尘器", "optionB": "除尘器", "optionC": "过滤器", "optionD": "过滤网", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 348, "type": "单选题", "content": "控制汽油蒸气压，主要是通过调整(　　)的操作来控制。", "optionA": "吸收塔", "optionB": "稳定塔", "optionC": "分馏塔", "optionD": "解吸塔", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 349, "type": "单选题", "content": "在碳原子数相同时，裂化反应能力排列正确的是(　　)。", "optionA": "烯烃>环烷烃>芳烃>烷烃", "optionB": "烯烃>烷烃>芳烃>环烷烃", "optionC": "烯烃>环烷烃>烷烃>芳烃", "optionD": "烯烃>芳烃>环烷烃>烷烃", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 350, "type": "单选题", "content": "下列选项中，关于金属钝化剂加稀释剂，说法错误的是(　　)。", "optionA": "稀释剂加入量视金属钝化剂在加注喷嘴中不分解和游离出有效成分而定", "optionB": "加稀释剂可以降低金属钝化剂的温度，减缓分解速度", "optionC": "加稀释剂可以保证喷嘴内流体的速度，即使金属钝化剂部分分解也不至于堵塞喷嘴", "optionD": "稀释剂只起稀释作用，无其他特殊要求", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 351, "type": "单选题", "content": "狭义的流态化系统是指(　　)。", "optionA": "稀相气力输送", "optionB": "载流床流化态", "optionC": "快速床流化态", "optionD": "密相气力输送", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 352, "type": "单选题", "content": "采用富氧再生技术后，再生催化剂定碳(　　)。", "optionA": "下降", "optionB": "上升", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 353, "type": "单选题", "content": "再生催化剂含炭量上升，转化率(　　)。", "optionA": "下降", "optionB": "上升", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 354, "type": "单选题", "content": "粗汽油泵抽空，导致分馏塔顶油气分离罐液面(　　)。", "optionA": "下降", "optionB": "上涨", "optionC": "不变", "optionD": "上下剧烈波动", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 355, "type": "单选题", "content": "空冷顶水干净，主要是为了从(　　)方面考虑。", "optionA": "下周期开车顺利", "optionB": "设备使用年限", "optionC": "停车与检修安全", "optionD": "装置现场清洁", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 356, "type": "单选题", "content": "采用同高并列式的催化裂化装置反应器压力与再生器压力相比(　　)。", "optionA": "相近", "optionB": "高", "optionC": "低", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 357, "type": "单选题", "content": "一般说来，半合成催化剂的比表面积与全合成催化剂相比要(　　)。", "optionA": "小", "optionB": "大", "optionC": "一样", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 358, "type": "单选题", "content": "塞阀与滑阀的相比，磨损(　　)。", "optionA": "小", "optionB": "大", "optionC": "一样", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 359, "type": "单选题", "content": "分馏塔轻重馏分之间“间隙”愈(　　)，说明分馏精度愈高，分馏塔的分馏效果愈高。", "optionA": "小", "optionB": "中", "optionC": "大", "optionD": "不确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 360, "type": "单选题", "content": "防止反应油气管线结焦，沉降器出口应使用(　　)弯头。", "optionA": "小曲率直径", "optionB": "直角", "optionC": "大曲率半径", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 361, "type": "单选题", "content": "为防止反应油气管线结焦，分馏塔入口前弯头应使用(　　)。", "optionA": "小曲率直径", "optionB": "直角", "optionC": "大曲率半径", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 362, "type": "单选题", "content": "开工时，装置加剂一般采用(　　)催化剂。", "optionA": "新鲜", "optionB": "平衡", "optionC": "待生", "optionD": "半待生", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 363, "type": "单选题", "content": "下列进料方式，能够减少催化剂失活，降低结焦的是(　　)。", "optionA": "新鲜进料与回炼油混合进料", "optionB": "新鲜进料进上喷嘴，回炼油进下喷嘴", "optionC": "新鲜进料进下喷嘴，回炼油进上喷嘴", "optionD": "上述选项都可以", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 364, "type": "单选题", "content": "装置开工水洗用的水是(　　)。", "optionA": "新鲜水", "optionB": "除氧水", "optionC": "循环水", "optionD": "除盐水", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 365, "type": "单选题", "content": "旋风分离器的分级效率是指(　　)。", "optionA": "一级旋风分离器的效率", "optionB": "各筛分组分的回收效率", "optionC": "二级旋风分离器的效率", "optionD": "一级旋风分离器和二级旋风分离器的效率之和", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 366, "type": "单选题", "content": "相同处理量时，采用同高并列式的催化裂化装置两器总高度比采用同轴式的(　　)。", "optionA": "一样", "optionB": "高", "optionC": "低", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 367, "type": "单选题", "content": "使用硫转移催化剂，采用完全再生的脱硫效果比采用不完全再生的效果(　　)。", "optionA": "一样", "optionB": "好", "optionC": "差", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 368, "type": "单选题", "content": "使用硫转移催化剂时，较大的汽提蒸汽量对脱硫效果(　　)。", "optionA": "一样", "optionB": "好", "optionC": "差", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 369, "type": "单选题", "content": "使用硫转移催化剂，采用CO助燃剂比不使用CO助燃剂的脱硫效果(　　)。", "optionA": "一样", "optionB": "好", "optionC": "差", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 370, "type": "单选题", "content": "下列选项中，对于金属钝化剂技术要求，正确的是(　　)。", "optionA": "由于需加入到原料油中，金属钝化剂需要采用油溶性的方可", "optionB": "对装置操作无不良影响", "optionC": "对产品性质有改进作用", "optionD": "有效成分越多越好", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 371, "type": "单选题", "content": "停工热水洗时，以下操作不能允许的是(　　)。", "optionA": "油浆循环进行充分循环，不留死角", "optionB": "整个系统打满水后，进行循环水洗", "optionC": "将回炼油罐中油抽净，经检查无油后方可往上打水", "optionD": "开大分馏塔底搅拌蒸汽进行加热水洗", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 372, "type": "单选题", "content": "催化剂在提升管中的滑落系数为(　　)。", "optionA": "油气在提升管内的停留时间与催化剂在提升管内的停留时间之比", "optionB": "油气在提升管内的停留时间与催化剂在提升管内的停留时间之差", "optionC": "催化剂在提升管内的停留时间与油气在提升管内的停留时间之比", "optionD": "催化剂在提升管内的停留时间与油气在提升管内的停留时间之差", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 373, "type": "单选题", "content": "沸石催化剂含钠量越低，稳定性(　　)。", "optionA": "越差", "optionB": "越好", "optionC": "不变", "optionD": "无法确定", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 374, "type": "单选题", "content": "相同反应条件下，催化剂平衡活性越高，汽油中烯烃含量(　　)。", "optionA": "越低", "optionB": "越高", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 375, "type": "单选题", "content": "空速越大，单位催化剂藏量通过的原料油(　　)。", "optionA": "越多", "optionB": "越少", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 376, "type": "单选题", "content": "温度一定时，转化率越大，汽油辛烷值(　　)。", "optionA": "越高", "optionB": "越低", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 377, "type": "单选题", "content": "在一定的总转化率下，单程转化率越高，回炼比就(　　)。", "optionA": "越小", "optionB": "无变化", "optionC": "越大", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 378, "type": "单选题", "content": "在正常生产的装置中，最容易发生催化剂热崩的位置是(　　)。", "optionA": "再生器", "optionB": "提升管出口", "optionC": "汽提段顶部", "optionD": "汽提段底部", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 379, "type": "单选题", "content": "下列选项中，关于同轴式催化裂化装置特点，描述不正确的是(　　)。", "optionA": "再生器压力高于反应器压力", "optionB": "催化剂循环量依靠增压风调节", "optionC": "两器重叠在一条轴线上，设备总高较高", "optionD": "投资省和钢材消耗少", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 380, "type": "单选题", "content": "再吸收塔压力控制失灵全关，会直接导致(　　)。", "optionA": "再生压力超高", "optionB": "气压机飞动", "optionC": "进料流量波动", "optionD": "再生温度波动", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 381, "type": "单选题", "content": "轴流式风机比离心式风机相比，下列叙述正确的是(　　)。", "optionA": "造价高", "optionB": "操作维护复杂", "optionC": "效率高", "optionD": "轴流式风机特性曲线较平缓。", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 382, "type": "单选题", "content": "当气体线速度增大，滑落系数将(　　)。", "optionA": "增大趋近于1", "optionB": "减小趋近于0", "optionC": "维持不变", "optionD": "减小趋近于1", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 383, "type": "单选题", "content": "减少催化裂化生焦的措施之一是(　　)催化剂的氢转移活性。", "optionA": "增加", "optionB": "保持", "optionC": "降低", "optionD": "无法确定", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 384, "type": "单选题", "content": "反应过程停留时间增加，液态烃产率(　　)。", "optionA": "增加", "optionB": "不变", "optionC": "降低", "optionD": "不能确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 385, "type": "单选题", "content": "增加回炼比，反应所需热量(　　)。", "optionA": "增加", "optionB": "降低", "optionC": "不变", "optionD": "无法确定", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 386, "type": "单选题", "content": "增加剂油比，单位质量原料油接触的催化活性中心数(　　)，汽油烯烃含量(　　)。", "optionA": "增加，上升", "optionB": "增加，降低", "optionC": "减少，降低", "optionD": "减少，上升", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 387, "type": "单选题", "content": "催化剂分子筛含量增高，氢转移活性(　　)，汽油辛烷值(　　)。", "optionA": "增加，增加", "optionB": "增加，降低", "optionC": "降低，降低", "optionD": "降低，增加", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 388, "type": "单选题", "content": "同高并列式催化裂化装置，一般用调整(　　)的方法来控制床层反应温度。", "optionA": "增压风流量", "optionB": "反应进料预热温度", "optionC": "再生滑阀开度", "optionD": "蒸汽流量", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 389, "type": "单选题", "content": "在任何情况下，不能确保再生、待生线路的料封时，正确的是启动(　　)。", "optionA": "增压风自保", "optionB": "进料自保", "optionC": "气压机自保", "optionD": "主风自保", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 390, "type": "单选题", "content": "下列选项中，关于催化剂水热稳定性，阐述完整的是(　　)。", "optionA": "指催化剂耐高温和水蒸汽老化联合作用的能力", "optionB": "指催化剂耐水蒸汽老化作用的能力", "optionC": "指催化剂耐高温老化作用的能力", "optionD": "指在使用条件下保持其活性的能力", "answer": "A", "answercode": 1000, "state": 0, "marked": 0, "checked": 0 },
    { "id": 391, "type": "单选题", "content": "当蒸汽经过若干级的膨胀做功后，在汽缸上开有多个抽汽口，将部分蒸汽抽出进入热管网，供用户使用，以上特点是属于(　　)汽轮机的特点。", "optionA": "轴流式", "optionB": "凝汽式", "optionC": "背压式", "optionD": "抽汽式", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 392, "type": "单选题", "content": "采用U型管输送催化剂的同高并列式催化裂化装置，其催化剂循环量靠(　　)调节。", "optionA": "主风", "optionB": "塞阀", "optionC": "滑阀", "optionD": "增压风", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 393, "type": "单选题", "content": "一旦逆流产生，逆流信号将通过逻辑控制系统使(　　)在1.5秒内打开。", "optionA": "主风单向阻尼阀", "optionB": "主风出口单向阀", "optionC": "防喘振阀", "optionD": "烟机旁路蝶阀", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 394, "type": "单选题", "content": "催化裂化装置噪声污染源不含(　　)。", "optionA": "主风机组", "optionB": "中控室", "optionC": "空冷风机", "optionD": "中压蒸汽管道", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 395, "type": "单选题", "content": "下列选项中，一般不属于催化裂化装置的自保事故蒸汽是(　　)。", "optionA": "主风事故蒸汽", "optionB": "增压风事故蒸汽", "optionC": "进料事故蒸汽", "optionD": "分馏塔底事故蒸汽", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 396, "type": "单选题", "content": "在任何情况下，要确保再生、待生线路的料封，目的是防止(　　)。", "optionA": "催化剂跑损", "optionB": "再生温度低", "optionC": "反应温度低", "optionD": "油气互窜发生爆炸", "answer": "D", "answercode": 1, "state": 0, "marked": 0, "checked": 0 },
    { "id": 397, "type": "单选题", "content": "改善沉降器汽提段的汽提效果，可以降低(　　)的生成。", "optionA": "催化焦", "optionB": "污染焦", "optionC": "剂油比焦", "optionD": "附加焦", "answer": "C", "answercode": 10, "state": 0, "marked": 0, "checked": 0 },
    { "id": 398, "type": "单选题", "content": "沉降器汽提段的汽提效果可以用待生催化剂的(　　)衡量。", "optionA": "含碳量", "optionB": "含氢量", "optionC": "平衡活性", "optionD": "比表面积", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 399, "type": "单选题", "content": "再吸收塔凝缩油压往分馏塔，不会影响(　　)。", "optionA": "塔顶压力", "optionB": "分馏塔顶油气分离器界位", "optionC": "分馏塔顶温度", "optionD": "粗汽油质量", "answer": "B", "answercode": 100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 400, "type": "多选题", "content": "催化裂化新鲜催化剂的化学组成包括(　　)", "optionA": "Al2O3", "optionB": "Na2O", "optionC": "SiO2", "optionD": "RE2O3", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 401, "type": "多选题", "content": "下列选项中，属于APC的是(　　)。", "optionA": "ARC", "optionB": "TDC", "optionC": "RMPC", "optionD": "DCC", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 402, "type": "多选题", "content": "粗汽油突然中断，吸收塔顶严重超温，造成干气大量带(　　)。", "optionA": "C2", "optionB": "C3", "optionC": "汽油", "optionD": "柴油", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 403, "type": "多选题", "content": "下列选项中，属于机泵耗功大的原因是(　　)。", "optionA": "泵与电机不对中", "optionB": "转动部分发生摩擦", "optionC": "泵抽空", "optionD": "介质相对密度变大", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 404, "type": "多选题", "content": "返混会造成气体流化床(　　)。", "optionA": "不均匀", "optionB": "不稳定", "optionC": "床面的剧烈波动", "optionD": "不存在", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 405, "type": "多选题", "content": "待生套筒穿孔处理，正确的是(　　)。", "optionA": "不严重时维持操作", "optionB": "严重时必须紧急停工", "optionC": "控制再生温度在较低水平维持操作", "optionD": "控制再生压力在较低水平操作", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 406, "type": "多选题", "content": "下列选项中，属于防止提升管部分结焦措施的是(　　)。", "optionA": "采用新型雾化喷嘴", "optionB": "提高进料段温度", "optionC": "降低进料段温度", "optionD": "采用终止剂技术", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 407, "type": "多选题", "content": "为防止催化裂化分馏塔塔盘被冲，切换汽封前适当降低(　　)。", "optionA": "沉降器的蒸汽量", "optionB": "再生压力", "optionC": "主风流量", "optionD": "提升管的蒸汽量", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 408, "type": "多选题", "content": "下列选项中，可用来控制反应压力的阀门有(　　)。", "optionA": "沉降器顶放空自控阀", "optionB": "分馏塔顶蝶(闸)阀", "optionC": "气压机入口放火炬阀", "optionD": "气压机出口放火炬阀", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 409, "type": "多选题", "content": "下列选项中，属于催化裂化装置开工水洗目的的是(　　)。", "optionA": "冲洗管线", "optionB": "贯通流程", "optionC": "检查安全阀", "optionD": "熟悉操作", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 410, "type": "多选题", "content": "下列选项中，关于催化裂化分馏塔顶循环回流的作用，叙述正确的是(　　)。", "optionA": "抽出板和返回板主要起换热作用", "optionB": "使塔中汽液相负荷均匀", "optionC": "控制粗汽油干点合格", "optionD": "控制轻柴油凝固点合格", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 411, "type": "多选题", "content": "下列选项中，关于固气比说法正确的是(　　)。", "optionA": "抽吸式所能达到的固气比高于压送式", "optionB": "流动性能好的固体颗粒能达到的固气比大", "optionC": "固气比大说明管道的输送能力大", "optionD": "固气比是指在输送管道内，被输送固体重量与输送其所用气体的重量流量之比", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 412, "type": "多选题", "content": "主风机组按紧急停机按钮后，会自动关闭的阀门是(　　)。", "optionA": "出口单向阻尼阀", "optionB": "防喘振阀", "optionC": "烟机入口蝶阀", "optionD": "双动滑阀", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 413, "type": "多选题", "content": "调速系统的(　　)卡住，会造成在空负荷下不能维持额定转速。", "optionA": "传动连杆", "optionB": "调速器连杆", "optionC": "油动机", "optionD": "错油门", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 414, "type": "多选题", "content": "下列选项中，关于传热系数K，说法错误的是(　　)。", "optionA": "传热系数与传热速率成反比", "optionB": "传热系数与传热面积成正比", "optionC": "传热系数与传热速率成正比", "optionD": "传热系数与温度差成正比", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 415, "type": "多选题", "content": "浮头式换热器的优点是(　　)。", "optionA": "传热效果好", "optionB": "造价低", "optionC": "结构简单", "optionD": "不易泄漏，检查方便", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 416, "type": "多选题", "content": "切断进料后，除(　　)不能停，其余泵抽空后可停。", "optionA": "粗汽油泵", "optionB": "轻柴油泵", "optionC": "油浆泵", "optionD": "封油泵", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 417, "type": "多选题", "content": "下列选项中，与噎塞速度相关因素是(　　)。", "optionA": "催化剂的筛分组成", "optionB": "催化剂的颗粒密度", "optionC": "催化剂的磨损指数", "optionD": "管道内固体质量速度", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 418, "type": "多选题", "content": "在相同的管道内，对于噎塞速度阐述正确的是(　　)。", "optionA": "催化剂密度越大，噎塞速度越大", "optionB": "气体密度越大，噎塞速度越大", "optionC": "催化剂颗粒越大，噎塞速度越大", "optionD": "固气比越大，噎塞速度越小。", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 419, "type": "多选题", "content": "下列选项中，关于催化剂的比表面积，阐述正确的是(　　)。", "optionA": "催化剂内表面积是指催化剂微孔内部的表面积", "optionB": "催化剂内表面积通常大于催化剂的外表面积", "optionC": "催化剂的比表面积是催化剂内外表面积之和", "optionD": "催化剂的外表面积是指催化剂微孔外部的表面积", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 420, "type": "多选题", "content": "下列选项中，会引起催化剂循环中断的原因有(　　)。", "optionA": "待生塞阀或滑阀故障", "optionB": "催化剂结焦堵塞", "optionC": "松动介质中断", "optionD": "松动风带水", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 421, "type": "多选题", "content": "催化剂活性太高，造成的后果是(　　)。", "optionA": "导致转化率过高，使汽油产率下降", "optionB": "导致气体产率上升", "optionC": "导致柴油产率下降", "optionD": "导致焦炭产率下降", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 422, "type": "多选题", "content": "待生、再生线路引起催化剂中断的处理方法有(　　)。", "optionA": "反应降量，维持生产，如不能维持则切断进料", "optionB": "阀门故障，改手动或立即修理", "optionC": "调节松动点并加强脱水", "optionD": "可适当反复提高两器正、负差压", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 423, "type": "多选题", "content": "下列选项中，与反应转化率有关的因素有(　　)。", "optionA": "反应温度", "optionB": "反应压力", "optionC": "原料预热温度", "optionD": "反应的停留时间", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 424, "type": "多选题", "content": "采用再生阀单参数控制反应温度时，要注意设立(　　)报警。", "optionA": "反应温度上限", "optionB": "反应温度下限", "optionC": "再生滑阀压降上限", "optionD": "再生滑阀压降下限", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 425, "type": "多选题", "content": "催化裂化装置的污染源有(　　)。", "optionA": "废催化剂", "optionB": "废气", "optionC": "含硫污水", "optionD": "噪音", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 426, "type": "多选题", "content": "烯烃的催化裂化反应有(　　)。", "optionA": "分解反应", "optionB": "异构化反应", "optionC": "氢转移反应", "optionD": "芳构化反应", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 427, "type": "多选题", "content": "停工时需要热水洗的设备有(　　)。", "optionA": "分馏二中段换热器", "optionB": "顶循环冷却器", "optionC": "回炼油罐", "optionD": "油浆换热器", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 428, "type": "多选题", "content": "下列选项中，造成反应压力超高的原因是(　　)。", "optionA": "分馏塔底液面高", "optionB": "回炼油罐满罐", "optionC": "冷回流带水", "optionD": "分馏塔顶油气分离器液面过高", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 429, "type": "多选题", "content": "再吸收塔凝缩油压往分馏塔，会影响(　　)。", "optionA": "分馏塔压力", "optionB": "分馏塔顶部温度", "optionC": "柴油质量", "optionD": "粗汽油质量", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 430, "type": "多选题", "content": "装置停仪表风，应立即将风关控制阀改为(　　)控制。", "optionA": "副线", "optionB": "上游阀", "optionC": "下游阀", "optionD": "泵出口", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 431, "type": "多选题", "content": "待生套筒穿孔，会导致(　　)。", "optionA": "干气中空气含量上升", "optionB": "反应器压力上升", "optionC": "烟机做功下降", "optionD": "再生压力下降", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 432, "type": "多选题", "content": "提升管反应催化裂化采用的装置类型有(　　)。", "optionA": "高低并列式", "optionB": "同高并列式", "optionC": "同轴式", "optionD": "烧焦罐式", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 433, "type": "多选题", "content": "下列选项中，关于催化剂水热稳定性，阐述正确的是(　　)。", "optionA": "高铝催化剂的催化剂稳定性略好于低铝催化剂", "optionB": "超稳Y型沸石稳定性优于一般沸石催化剂", "optionC": "沸石催化剂的稳定性比无定型硅酸铝催化剂好", "optionD": "细孔催化剂稳定性比粗孔催化剂好", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 434, "type": "多选题", "content": "汽轮机正常运行中，主要监视的项目包括(　　)。", "optionA": "各类仪表", "optionB": "润滑油系统", "optionC": "泄漏情况", "optionD": "各种定时排放物", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 435, "type": "多选题", "content": "进料自保启用后，下列操作方法正确的是(　　)。", "optionA": "关进料手阀", "optionB": "停钝化剂", "optionC": "关预提升干气手阀", "optionD": "关油浆喷嘴", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 436, "type": "多选题", "content": "下列选项中，分析外取热器管管束内漏原因正确的是(　　)。", "optionA": "观察温度变化", "optionB": "停异常声响", "optionC": "关闭进出口阀门，观察压力变化", "optionD": "停工检修", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 437, "type": "多选题", "content": "工艺管线表（简称工管表）中应该包括(　　)内容。", "optionA": "管线编号", "optionB": "管线尺寸", "optionC": "介质名称", "optionD": "设计条件", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 438, "type": "多选题", "content": "吸收稳定系统收汽油的目的是(　　)。", "optionA": "贯通流程", "optionB": "检查机泵是否泄漏", "optionC": "熟悉操作", "optionD": "缩短开工时间", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 439, "type": "多选题", "content": "气泡在流化床中会发生(　　)。", "optionA": "合并", "optionB": "崩碎", "optionC": "夹带的固体落回床层", "optionD": "固体带出床层", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 440, "type": "多选题", "content": "DCS是在(　　)的基础上产生的。", "optionA": "计算机技术", "optionB": "控制技术", "optionC": "通信技术", "optionD": "CRT图形显示技术", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 441, "type": "多选题", "content": "空冷迎风速过高的后果是(　　)。", "optionA": "加剧空冷管束腐蚀", "optionB": "增加巡检时的危险性", "optionC": "增加风机消耗", "optionD": "影响空气压力降", "answer": "CD", "answercode": 11, "state": 0, "marked": 0, "checked": 0 },
    { "id": 442, "type": "多选题", "content": "提高轻柴油收率，优化分馏塔操作的方法是(　　)。", "optionA": "降低分馏塔顶温度，降低汽油干点", "optionB": "提高柴油抽出温度和一中段抽出温度", "optionC": "优化柴油汽提塔汽提蒸汽量", "optionD": "拓宽柴油馏分", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 443, "type": "多选题", "content": "汽轮机流通部分结垢所造成的危害有(　　)。", "optionA": "降低汽轮机的效率", "optionB": "增加了耗汽量", "optionC": "造成气流通过隔板及叶片的压降增加，导致隔板及推力轴承过负荷", "optionD": "结垢在汽门杆上，产生卡涩", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 444, "type": "多选题", "content": "催化裂化新鲜催化剂的灼烧减量是指催化剂中(　　)的含量。", "optionA": "结构水", "optionB": "铵盐", "optionC": "Fe2O3 ", "optionD": "存量水", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 445, "type": "多选题", "content": "下列选项中，会影响金属钝化剂使用效果的是(　　)。", "optionA": "进料预热温度", "optionB": "原料组成", "optionC": "新鲜催化剂补充速度", "optionD": "进料雾化与剂油接触状况", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 446, "type": "多选题", "content": "下列选项中，关于流化床概念，叙述正确的是(　　)。", "optionA": "孔隙率表示流化床中气体所占体积与流化床总体积之比", "optionB": "颗粒体积等于床层重量与颗粒密度之比", "optionC": "知道了床层密度和催化剂的颗粒密度，可以计算出床层孔隙率", "optionD": "膨胀比等于流化床高度与起始流化时床层高度之比", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 447, "type": "多选题", "content": "下列选项中，关于防止油浆系统结焦，措施正确的是(　　)。", "optionA": "控制分馏塔底部温度不小于375℃", "optionB": "为防止油浆泵不上量而引起催化剂堆积在分馏塔底，可以选用汽蚀余量较大的泵", "optionC": "保持油浆在管道内的流速不小于1.5~2.1m/s", "optionD": "保持油浆在换热器管程中流速控制在1.2~2.0m/s", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 448, "type": "多选题", "content": "当出现(　　)情况时，立即投用两器切断自保。", "optionA": "两器差压超限", "optionB": "再生线路可能压空", "optionC": "待生线路可能压空", "optionD": "再生器超温无下降趋势", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 449, "type": "多选题", "content": "下列选项中，增大单位体积传热面积的方法是(　　)。", "optionA": "列管换热器中采用细管排列的方式", "optionB": "用螺纹管代替普通管", "optionC": "采用板式换热器", "optionD": "采用螺旋板式换热器", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 450, "type": "多选题", "content": "造成门杆与门杆套发生卡涩，使调速汽门动作不灵活的原因有(　　)。", "optionA": "门杆套发生蠕张", "optionB": "门杆上结有盐垢", "optionC": "门杆套偏斜", "optionD": "门杆与门杆套之间间隙过小", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 451, "type": "多选题", "content": "重油泵密封泄漏的原因是(　　)。", "optionA": "摩擦副严重磨损", "optionB": "动静环吻合不均", "optionC": "O型圈损坏", "optionD": "摩擦副过大，静环破裂", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 452, "type": "多选题", "content": "两器自保投用后，再生温度可以通过调节(　　)来控制。", "optionA": "喷燃烧油", "optionB": "掺渣比", "optionC": "外取热器", "optionD": "CO助燃剂", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 453, "type": "多选题", "content": "完整的工艺配管图中应该包括(　　)。", "optionA": "平面布置图", "optionB": "工艺管线图", "optionC": "工艺管线表", "optionD": "材料表", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 454, "type": "多选题", "content": "流化床中气泡初始生成时的大小与(　　)有关。", "optionA": "气速大小", "optionB": "分布器孔径", "optionC": "固体颗粒直径", "optionD": "流化床直径大小", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 455, "type": "多选题", "content": "下列选项中，关于催化剂滑落概念，描述正确的是(　　)。", "optionA": "气体流动速度与固体流动速度之差叫做固体滑落速度", "optionB": "气体流动速度与固体滑落速度之比叫做滑落系数", "optionC": "垂直管中的气力输送，固体流动速度等于气体流动速度与固体颗粒自由降落速度之差", "optionD": "垂直管中，固体颗粒自由降落速度等于其终端流化速度", "answer": "ACD", "answercode": 1011, "state": 0, "marked": 0, "checked": 0 },
    { "id": 456, "type": "多选题", "content": "气压机停机后，需要关闭的阀门是(　　)。", "optionA": "气压机入口液包凝缩油压送阀", "optionB": "中间分液罐凝缩油压送阀", "optionC": "中间冷却器的进出口阀", "optionD": "去吸收稳定系统大阀", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 457, "type": "多选题", "content": "当达到下列(　　)条件时，气压机自保停机。", "optionA": "汽轮机超速", "optionB": "润滑油温过低", "optionC": "润滑油温过高", "optionD": "气压机轴位移超限", "answer": "AD", "answercode": 1001, "state": 0, "marked": 0, "checked": 0 },
    { "id": 458, "type": "多选题", "content": "下列选项中，防止催化分馏塔塔盘被冲的预防措施是(　　)。", "optionA": "切换汽封前适当降低沉降器的蒸汽量", "optionB": "尽量减少切断进料次数", "optionC": "分馏塔精心操作维持各回流正常，使全塔各点温度控制平稳", "optionD": "检修期间确保检修质量，塔板安装严格按照规定进行", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 459, "type": "多选题", "content": "转化率不变，反应温度升高，收率上升的是(　　)。", "optionA": "氢气", "optionB": "丙烯", "optionC": "丁烯", "optionD": "丁烷", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 460, "type": "多选题", "content": "增加剂油比，有利于(　　)反应。", "optionA": "氢转移", "optionB": "芳构化", "optionC": "热裂化", "optionD": "催化裂化", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 461, "type": "多选题", "content": "同轴式催化裂化装置相比其他类型装置，特有的设备有(　　)。", "optionA": "塞阀", "optionB": "双动滑阀", "optionC": "待生套筒", "optionD": "辅助燃烧室", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 462, "type": "多选题", "content": "以下阐述正确的是(　　)。", "optionA": "湿材料的导热系数比同样组成的干材料要高", "optionB": "建筑材料的导热系数λ随温度的升高而降低", "optionC": "蒸汽的导热系数λ随温度的升高而升高", "optionD": "绝热材料的导热系数λ随温度的升高而降低", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 463, "type": "多选题", "content": "下列选项中，有利于减少反应生焦的措施是(　　)。", "optionA": "适当提高反应压力", "optionB": "适当提高再生温度", "optionC": "适当提高反应温度", "optionD": "选用新型高效雾化喷嘴", "answer": "BD", "answercode": 101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 464, "type": "多选题", "content": "下列选项中，可以降低汽油烯烃含量的操作方法是(　　)。", "optionA": "适当增加分馏塔底搅拌蒸汽量，降低油气分压", "optionB": "调整分馏塔操作，提高汽油的90%馏出点和终馏点", "optionC": "适当减少分馏塔底搅拌蒸汽量，提高油气分压", "optionD": "调整分馏塔操作，降低汽油的90%馏出点和终馏点", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 465, "type": "多选题", "content": "待生套筒穿孔的原因是(　　)。", "optionA": "松动点管线断裂", "optionB": "衬里受冲蚀", "optionC": "仪表引压管断裂", "optionD": "衬里施工质量不佳", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 466, "type": "多选题", "content": "下列选项中，导致取热器漏水原因有(　　)。", "optionA": "松动风将取热管吹破", "optionB": "取热管被干烧过", "optionC": "取热管母材有缺陷", "optionD": "取热管超过使用寿命", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 467, "type": "多选题", "content": "采用(　　)措施，可以降低沉降器和旋分器入口处结焦。", "optionA": "缩短催化剂与油气分离时间", "optionB": "延长催化剂与油气分离时间", "optionC": "减少油气停留时间", "optionD": "增加油气停留时间", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 468, "type": "多选题", "content": "采用富氧再生技术的优点是(　　)。", "optionA": "提高减压渣油处理能力", "optionB": "降低能耗", "optionC": "提高装置处理能力", "optionD": "提高催化剂循环量", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 469, "type": "多选题", "content": "下列选项中，减小流体对流传热热阻的方法是(　　)。", "optionA": "提高流体的流速", "optionB": "增加流体的湍动程度", "optionC": "破坏流体的滞流底层", "optionD": "清除垢层", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 470, "type": "多选题", "content": "烧焦速度与再生烟气中氧分压成正比，因此可采用(　　)提高烧焦速度。", "optionA": "提高再生压力", "optionB": "增加主风量", "optionC": "降低再生压力", "optionD": "减少主风量", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 471, "type": "多选题", "content": "提升管催化剂中断的现象是(　　)。", "optionA": "提升管密度上升，汽提段藏量上升", "optionB": "反应温度急剧下降", "optionC": "反应压力下降", "optionD": "提升管密度上升，汽提段藏量下降", "answer": "BCD", "answercode": 111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 472, "type": "多选题", "content": "当出现(　　)情况时，立即投用反应进料自保。", "optionA": "提升管噎塞", "optionB": "原料油带水严重", "optionC": "原料油长时间中断", "optionD": "再生滑阀故障", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 473, "type": "多选题", "content": "下列选项中，导致分馏塔顶油气分离罐液面暴涨的原因是(　　)。", "optionA": "提升管蒸汽量大幅度提高", "optionB": "分馏塔顶油气分离罐分水包抽出泵停运", "optionC": "再生器喷燃烧油", "optionD": "反应进料带水", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 474, "type": "多选题", "content": "开工水洗时，需要预先卸下的是(　　)。", "optionA": "调节阀", "optionB": "孔板", "optionC": "安全阀", "optionD": "机泵进口过滤网", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 475, "type": "多选题", "content": "下列选项中，属于调速系统在空负荷下，不能维持额定转速情况原因的是(　　)。", "optionA": "调速汽阀接触不严密", "optionB": "调速系统连杆尺寸安装不正确", "optionC": "传动连杆连接松弛", "optionD": "没有正确安装好管道", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 476, "type": "多选题", "content": "污染指数包括以下(　　)的含量。", "optionA": "铁", "optionB": "镍", "optionC": "铜", "optionD": "钒", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 477, "type": "多选题", "content": "主风机组进入安全运行状态，系统会自动执行的动作是(　　)。", "optionA": "停增压机", "optionB": "将静叶关至最小", "optionC": "打开防喘振阀", "optionD": "关闭出口单向阻尼阀", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 478, "type": "多选题", "content": "原料中(　　)会导致汽油辛烷值上升", "optionA": "烷烃上升", "optionB": "环烷烃上升", "optionC": "芳烃上升", "optionD": "粗汽油上升", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 479, "type": "多选题", "content": "广义的流态化系统包括(　　)。", "optionA": "稀相气力输送", "optionB": "载流床流化态", "optionC": "快速床流化态", "optionD": "密相气力输送", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 480, "type": "多选题", "content": "下列选项中，与反应转化率有关的因素包括催化剂的(　　)。", "optionA": "选择性", "optionB": "活性", "optionC": "稳定性", "optionD": "流化性能", "answer": "AB", "answercode": 1100, "state": 0, "marked": 0, "checked": 0 },
    { "id": 481, "type": "多选题", "content": "MIP催化裂化装置，反应二区藏量用(　　)控制。", "optionA": "循环待生塞阀", "optionB": "待生塞阀", "optionC": "循环待生滑阀", "optionD": "再生滑阀", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 482, "type": "多选题", "content": "火炬凝缩油压往分馏塔顶油气分离器，会导致油气分离器(　　)。", "optionA": "压力上升", "optionB": "压力下降", "optionC": "液面上升", "optionD": "液面下降", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 483, "type": "多选题", "content": "氧气输送管道上装有(　　)的设施，便于纯氧流量能随意调节。", "optionA": "压力调节", "optionB": "温度调节", "optionC": "流量调节", "optionD": "氧含量调节", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 484, "type": "多选题", "content": "停烟机时，烟机入口蝶阀全关后，要将 (　　)。", "optionA": "烟机后路进烟气流程切断", "optionB": "烟机出口上水封", "optionC": "进口高温闸阀关闭", "optionD": "烟机轮盘冷却蒸汽停用", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 485, "type": "多选题", "content": "采用(　　)措施，可以达到防止反应油气管线结焦的目的。", "optionA": "延长油气管线长度", "optionB": "缩短油气管线长度", "optionC": "提高大油气管线速", "optionD": "降低大油气管线速", "answer": "BC", "answercode": 110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 486, "type": "多选题", "content": "汽轮机长期超负荷会造成(　　)。", "optionA": "叶片承受的弯曲应力增加", "optionB": "推力轴承轴瓦温度上升，严重时推力轴瓦烧毁", "optionC": "调速系统性能变坏", "optionD": "隔板绕度增加", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 487, "type": "多选题", "content": "电液执行机构包括(　　)。", "optionA": "液压系统", "optionB": "电气控制系统", "optionC": "伺服油缸", "optionD": "手动机构", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 488, "type": "多选题", "content": "汽轮机在负荷小的时候，调速汽门跳动，转速变化大的原因有(　　)。", "optionA": "油动机、错油门及传动连杆卡涩", "optionB": "油动机的内部局部磨损", "optionC": "调速系统的静态特性曲线在某一区域过于平坦", "optionD": "调速汽门重叠度调整不当", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 489, "type": "多选题", "content": "下列选项中，影响原料油预热温度的因素是(　　)。", "optionA": "油浆-原料油换热器副线的开度", "optionB": "油浆蒸汽发生器", "optionC": "柴油-原料油换热器副线的开度", "optionD": "贫富吸收油换热器副线开度", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 490, "type": "多选题", "content": "下列选项中，关于金属钝化剂技术要求，说法正确的是(　　)。", "optionA": "有效组分沉积率高，钝化效果明显", "optionB": "使用成本低", "optionC": "性质稳定，粘度低，易于输送，使用方便", "optionD": "热稳定性好，在高温下不分解或少分解", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 491, "type": "多选题", "content": "抑制重金属污染的方法有(　　)。", "optionA": "原料油预处理", "optionB": "催化剂脱金属", "optionC": "使用金属钝化剂", "optionD": "使用阻垢剂", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 492, "type": "多选题", "content": "正常生产中，造成装置催化剂跑损上升的因素是(　　)。", "optionA": "再生器使用降温水", "optionB": "采用大型加料添加新鲜催化剂", "optionC": "原料油带水", "optionD": "采用CO助燃剂", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 493, "type": "多选题", "content": "催化剂热崩的影响因素包括(　　)。", "optionA": "再生温度", "optionB": "补入系统新鲜催化剂含水量", "optionC": "系统蒸汽含水量", "optionD": "稀相喷水量", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 494, "type": "多选题", "content": "生产中，可以用控制(　　)的方法控制反应温度。", "optionA": "再生温度", "optionB": "反应进料温度", "optionC": "催化剂循环量", "optionD": "蒸汽流量", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 495, "type": "多选题", "content": "下列选项中，关于高速流化床特点，叙述正确的是(　　)。", "optionA": "在床层中部，催化剂密度基本上不随高度变化", "optionB": "高速床不存在稀密相分层的现象", "optionC": "床层密度与线速相关，与催化剂循环强度无关", "optionD": "床层中存在气泡相和颗粒相", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 496, "type": "多选题", "content": "下列选项中，磨损指数高的催化剂对装置操作危害，阐述正确的是(　　)。", "optionA": "增大催化剂用量", "optionB": "污染环境", "optionC": "影响装置流化", "optionD": "导致产品收率下降", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 497, "type": "多选题", "content": "下列选项中，防止沉降器结焦的措施有(　　)。", "optionA": "增加防焦蒸汽量", "optionB": "采用高效汽提技术", "optionC": "提高沉降器壳程温度", "optionD": "减少催化剂沉积", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 498, "type": "多选题", "content": "DCS的优点是(　　)。", "optionA": "增加数据的可靠性和安全性", "optionB": "便于资源共享，并行处理", "optionC": "扩展性好", "optionD": "系统可靠性高", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 499, "type": "多选题", "content": "提升管催化剂中断的处理方法有(　　)。", "optionA": "增加预提升介质", "optionB": "维持汽提段藏量", "optionC": "必要时将预提升介质改蒸汽", "optionD": "适当降低催化剂循环量", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 500, "type": "多选题", "content": "汽轮机正常运行中，应经常检查(　　)等各种性能数据，分析是否在规定范围内。", "optionA": "振动", "optionB": "轴位移", "optionC": "压力", "optionD": "流量", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 501, "type": "多选题", "content": "催化裂化装置上使用限流孔板的介质有(　　)。", "optionA": "蒸汽", "optionB": "净化风", "optionC": "非净化风", "optionD": "水", "answer": "ABC", "answercode": 1110, "state": 0, "marked": 0, "checked": 0 },
    { "id": 502, "type": "多选题", "content": "下列选项中，需要使用n-d-M法进行计算组成的是(　　)。", "optionA": "直馏减压馏分油", "optionB": "液化石油气", "optionC": "减压渣油", "optionD": "粗汽油", "answer": "AC", "answercode": 1010, "state": 0, "marked": 0, "checked": 0 },
    { "id": 503, "type": "多选题", "content": "催化裂化原料结构族组成，可通过(　　)得到。", "optionA": "质谱法", "optionB": "核磁共振", "optionC": "CT", "optionD": "n-d-M法", "answer": "BD", "answercode": 101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 504, "type": "多选题", "content": "机泵耗功大的常见原因有(　　)", "optionA": "轴承润滑不当", "optionB": "轴承安装不正确", "optionC": "轴弯曲", "optionD": "叶轮损坏", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 505, "type": "多选题", "content": "在汽轮机升速暖机过程中，应注意(　　)。", "optionA": "轴承温度", "optionB": "振动", "optionC": "油压", "optionD": "声响", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 506, "type": "多选题", "content": "当出现(　　)时，立即投用主风自保。", "optionA": "主风机处于安全运行状态", "optionB": "主风机紧急停机", "optionC": "两器料封有压空危险时", "optionD": "两器切断后，再生器仍超温", "answer": "ABD", "answercode": 1101, "state": 0, "marked": 0, "checked": 0 },
    { "id": 507, "type": "多选题", "content": "根据催化裂化分馏一中段回流的温位，可用于(　　)。", "optionA": "做稳定塔底热源", "optionB": "做解吸塔底热源", "optionC": "发生1.0MPa蒸汽", "optionD": "与原料油换热", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
    { "id": 508, "type": "多选题", "content": "根据催化裂化分馏二中段回流的温位，可用于(　　)。", "optionA": "做稳定塔底热源", "optionB": "做解吸塔底热源", "optionC": "发生1.0MPa蒸汽", "optionD": "与原料油换热", "answer": "ABCD", "answercode": 1111, "state": 0, "marked": 0, "checked": 0 },
];

export default senior;