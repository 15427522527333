<template>
  <div>
    <div class="header">
      <!-- 考试名称 -->
      <h4 id="examName">{{ examName }}</h4>
      <i @click="out">✖</i>
      <span style="font-size: 16px">共 {{ questionCount }} 题</span>
    </div>
    <div class="container">
      <!-- 题目内容部分 -->
      <div class="content" ref="content">
        <h3>{{ examName }}</h3>
        <div v-for="(item, index) in que_ban" :key="item.id">
          <!-- 判断题目开始 -->
          <div :id="'que_' + index" class="body" v-if="item.type === '判断题'">
            <div class="topic">
              <span>{{ index + 1 }}.({{ item.type }}) {{ item.content }}</span>
              <label
                ><input
                  type="checkbox"
                  :id="'check_ ' + item.id"
                  @click="toggleMarked(item.id, $event)"
                />标记</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="radio"
                  :value="1000"
                  @click="toggleState(item.id, $event)"
                />A.对</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="radio"
                  :value="100"
                  @click="toggleState(item.id, $event)"
                />B.错</label
              >
            </div>
          </div>
          <!-- 判断题目结束 -->
          <!-- 单选题目开始 -->
          <div
            :id="'que_' + index"
            class="body"
            v-else-if="item.type === '单选题'"
          >
            <div class="topic">
              <span
                >{{ index + 1 }} . ({{ item.type }}) {{ item.content }}</span
              >
              <label
                ><input
                  type="checkbox"
                  :id="'check_ ' + item.id"
                  @click="toggleMarked(item.id, $event)"
                />标记</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="radio"
                  :value="1000"
                  @click="toggleState(item.id, $event)"
                />A.{{ item.optionA }}</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="radio"
                  :value="100"
                  @click="toggleState(item.id, $event)"
                />B.{{ item.optionB }}</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="radio"
                  :value="10"
                  @click="toggleState(item.id, $event)"
                />C.{{ item.optionC }}</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="radio"
                  :value="1"
                  @click="toggleState(item.id, $event)"
                />D.{{ item.optionD }}</label
              >
            </div>
          </div>
          <!-- 单选题目结束 -->
          <!-- 多选题目开始 -->
          <div :id="'que_' + index" class="body" v-else>
            <div class="topic">
              <span
                >{{ index + 1 }} . ({{ item.type }}) {{ item.content }}</span
              >
              <label
                ><input
                  type="checkbox"
                  :id="'check_ ' + item.id"
                  @click="toggleMarked(item.id, $event)"
                />标记</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="checkbox"
                  value="A"
                  @click="toggleState(item.id, $event)"
                />A.{{ item.optionA }}</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="checkbox"
                  value="B"
                  @click="toggleState(item.id, $event)"
                />B.{{ item.optionB }}</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="checkbox"
                  value="C"
                  @click="toggleState(item.id, $event)"
                />C.{{ item.optionC }}</label
              >
            </div>
            <div class="option">
              <label
                ><input
                  :name="'opt_' + item.id"
                  type="checkbox"
                  value="D"
                  @click="toggleState(item.id, $event)"
                />D.{{ item.optionD }}</label
              >
            </div>
          </div>
          <!-- 多选题目结束 -->
        </div>
      </div>
      <div class="right">
        <!-- 考试信息部分 -->
        <div class="information-panel">
          <div class="panel-heading">考试提醒</div>
          <div class="information">
            <p>
              考试时间：
              <span style="color: red">{{ min }}</span>
              分钟
              <span style="color: red">{{ sec }}</span>
              秒
            </p>
            <p>姓名：<span>高飞</span></p>
            <!-- <h3>制作：高飞</h3> -->
          </div>
          <div style="padding: 15px">
            <button @click="submit">我要交卷</button>
          </div>
        </div>
        <!-- 考试信息部分结束 -->
        <!-- 试卷导航部分开始 -->
        <div class="nav-panel" ref="nav">
          <div class="panel-heading" @click.self="show_nav">
            <button class="mobile" @click="out">退出</button>
            试卷导航
            <button class="mobile" @click="submit">交卷</button>
          </div>
          <div class="panel-body">
            <div style="padding: 10px 5px 0px">
              <p>
                <span>{{ examName }}</span>
              </p>
            </div>
            <div class="sele_btn">
              <button
                v-for="(item, index) in que_ban"
                :key="item.id"
                :class="{ marked: item.marked, 'que-answer': item.state }"
                @click="jump(index)"
              >
                {{ index + 1 }}
              </button>
            </div>
          </div>
        </div>
        <!-- 试卷导航部分结束 -->
      </div>
    </div>
  </div>
</template>

<script>
import senior from "@/quebank/senior";
import middle from "@/quebank/middle";
export default {
  name: "Exercise",

  props: {
    queIndex: {
      type: Number,
      default: 1,
    },
    quenum: {
      type: [Number, String],
      default: "all",
    },
    que: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      examName: "",
      que_ban: senior,
      min: 0,
      sec: 0,
      timer: () => {},
      end: false,
      isShowNav: true,
    };
  },

  computed: {
    questionCount() {
      return this.que_ban.length;
    },
  },

  methods: {
    // disorder() 将题库打乱顺序
    disorder(arr) {
      let ran_num = () => 0.5 - Math.random();
      arr.sort(ran_num);
    },

    // 点击选项的处理函数
    toggleState(id, e) {
      let currentQue;
      currentQue = this.que_ban.find((item) => item.id === id);
      if (currentQue.type === "多选题") {
        if (e.target.checked) {
          switch (e.target.value) {
            case "A":
              currentQue.checked += 1000;
              break;
            case "B":
              currentQue.checked += 100;
              break;
            case "C":
              currentQue.checked += 10;
              break;
            case "D":
              currentQue.checked += 1;
              break;
          }
        } else {
          switch (e.target.value) {
            case "A":
              currentQue.checked -= 1000;
              break;
            case "B":
              currentQue.checked -= 100;
              break;
            case "C":
              currentQue.checked -= 10;
              break;
            case "D":
              currentQue.checked -= 1;
              break;
          }
        }
        if (currentQue.checked > 0) {
          if (currentQue.checked == currentQue.answercode) {
            currentQue.state = 1;
          } else {
            currentQue.state = 2;
          }
        } else {
          currentQue.state = 0;
        }
      } else {
        currentQue.checked = e.target.value;
        if (e.target.value == currentQue.answercode) {
          currentQue.state = 1;
        } else {
          currentQue.state = 2;
        }
      }
    },

    // 标记题目处理函数
    toggleMarked(id, e) {
      let currentQue;
      currentQue = this.que_ban.find((item) => item.id === id);
      if (e.target.checked) {
        currentQue.marked = 1;
      } else {
        currentQue.marked = 0;
      }
    },

    // 交卷处理函数
    submit() {
      this.$emit("toggleCom", this.que_ban, this.examName);
    },

    out() {
      location.reload();
    },

    // 显示与隐藏移动端页面底部的“试卷导航”
    show_nav() {
      if (window.matchMedia("(max-width: 600px)").matches) {
        if (this.isShowNav) {
          this.$refs.nav.style.bottom = "-145px";
          this.$refs.content.style.marginBottom = "35px";
          this.isShowNav = !this.isShowNav;
        } else {
          this.$refs.nav.style.bottom = 0;
          this.$refs.content.style.marginBottom = "180px";
          this.isShowNav = !this.isShowNav;
        }
      }
    },

    // 点击试题导航按钮跳转到指定题目
    jump(index) {
      document
        .querySelector("#que_" + index)
        .scrollIntoView({ behavior: "smooth" });
    },

    // 设置定时器
    setTimer() {
      this.timer = setInterval(() => {
        this.sec++;
        if (this.sec === 60) {
          this.min++;
          this.sec = 0;
        }
        if (this.min === 180 && this.sec === 0) {
          this.end = true;
          clearInterval(this.timer);
          alert(
            "温馨提示：\n小主，您已经看了三小时了，休息一下吧~~\n\n没看完的题目可以导出下次再看哦！ (^o^)/"
          );
        }
      }, 1000);
    },
  },

  created() {
    switch (this.queIndex) {
      case 1:
        this.disorder(middle);
        if (this.quenum === "all") {
          this.que_ban = middle;
        } else {
          let tempArr = [];
          for (let i = 0; i < this.quenum; i++) {
            tempArr.push(middle[i]);
          }
          this.que_ban = tempArr;
        }
        this.examName = "催化裂化操作工-中级工";
        break;
      case 2:
        this.disorder(senior);
        if (this.quenum === "all") {
          this.que_ban = senior;
        } else {
          let tempArr = [];
          for (let i = 0; i < this.quenum; i++) {
            tempArr.push(senior[i]);
          }
          this.que_ban = tempArr;
        }
        this.examName = "催化裂化操作工-高级工";
        break;
      case 3:
        this.que_ban = this.que;
        this.examName = "催化裂化操作工-错题本";
        break;
    }
    this.que_ban.forEach((item) => {
      item.state = 0;
      item.marked = 0;
      item.checked = 0;
    });
    this.min = 0;
    this.sec = 0;
    this.end = false;
  },

  mounted() {
    setTimeout(() => {
      if (window.matchMedia("(max-width: 600px)").matches) {
        this.$refs.nav.style.bottom = "-145px";
        this.$refs.content.style.marginBottom = "35px";
        this.isShowNav = false;
      }
    }, 1000);
  },

  activated() {
    window.scrollTo({ top: 0 }); // 切换组件时跳转到最顶部
    if (!this.end) {
      this.setTimer();
    }
  },

  deactivated() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  // height: 108px;
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
  text-align: center;
  border-bottom: 6px solid #36a9e1;
  box-sizing: border-box;
  #examName {
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  i {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 30px;
    // float: right;
    // vertical-align: top;
    font-style: normal;
    // margin: 15px 40px;
  }
  span {
    display: block;
    // margin-top: 30px;
  }
}
.container {
  // display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .content {
    // width: 65%;
    font-size: 14px;
    // margin: 20px 15px 20px 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
    h3 {
      background-color: #f9fafd;
      font-size: 16px;
      font-weight: 400;
      color: inherit;
      padding: 10px 15px;
      margin: 0;
      border-bottom: 1px solid #ddd;
    }
    .body {
      padding: 10px 10px 5px;
      .topic {
        span {
          display: inline-block;
          // width: 85%;
          // padding-right: 15px;
          // padding-left: 15px;
        }
      }
      // .option {
      //   margin-top: 10px;
      //   padding-left: 20px;
      // }
    }
  }
  .right {
    // position: sticky;
    top: 20px;
    width: 31%;
    // margin: 20px 20px 0 15px;
    .information-panel {
      background: white;
      font-size: 14px;
      border: 1px solid #ddd;
      border-radius: 4px;
      .information {
        height: 150px;
        background-image: url("../watermark.png");
        background-repeat: no-repeat;
        background-position-y: 80%;
        background-position-x: center;
        padding: 10px 15px 5px 15px;
        p {
          margin: 0 0 10px;
        }
        h3 {
          font-size: 50px;
          color: #eee;
          text-align: center;
          line-height: 50px;
          margin-top: 35px;
        }
      }
      button {
        cursor: pointer;
        width: 100%;
        background-color: #337ab7;
        font-size: 14px;
        color: #fff;
        padding: 6px 12px;
        border: 1px solid #2e6da4;
        border-radius: 4px;
      }
    }
    .nav-panel {
      background: white;
      font-size: 14px;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-top: 20px;

      .panel-body {
        // height: 420px;
        overflow: auto;
        p {
          margin: 0 0 10px;
        }

        .que-answer {
          background-color: #99ee6a;
        }
        .marked {
          background-color: yellow;
        }
      }
      button {
        cursor: pointer;
        line-height: 20px;
        // padding: 6px 12px;
        margin: 2px 5px;
        border-radius: 4px;
        border: 1px solid transparent;
      }
    }
    .panel-heading {
      background-color: #f9fafd;
      font-weight: 400;
      color: inherit;
      padding: 4px 15px;
      text-align: center;
      margin: 0;
      border-bottom: 1px solid #ddd;
    }
  }
}

@media (max-width: 600px) {
  .header {
    height: 80px;
    i {
      display: none;
    }
    span {
      margin-top: 0;
    }
  }
  .container {
    display: block;
    .content {
      width: 99%;
      margin: 5px auto 180px;
      // margin: 5px auto 35px;

      .body {
        .topic {
          span {
            width: 82%;
            vertical-align: top;
            padding-right: 8px;
            padding-left: 0;
          }
        }
        .option {
          margin-top: 6px;
          padding-left: 10px;
        }
      }
    }
    .right {
      position: relative;
      margin: 0;
      .information-panel {
        display: none;
      }
      .nav-panel {
        // width: 100%;
        position: fixed;
        bottom: 0;
        // bottom: -145px;
        transition: all 0.5s;
        .panel-heading {
          position: relative;
          .mobile {
            display: inline;
            position: absolute;
            right: 20px;
            background-color: #337ab7;
            color: #fff;
            height: 28px;
            padding: 2px 6px;
            margin: -4px;
            border-color: #337ab7;
            outline: none;
          }
          .mobile:first-child {
            left: 20px;
          }
        }
        .panel-body {
          height: 145px;
          button {
            padding: 4px 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 601px) {
  .header {
    height: 115px;
    i {
      display: inline;
    }
    span {
      margin-top: 30px;
      // margin: 30px auto 0;
    }
  }
  .container {
    display: flex;
    .content {
      width: 65%;
      margin: 20px 15px 20px 20px;
      .body {
        .topic {
          span {
            width: 85%;
            vertical-align: unset;
            padding-right: 15px;
            padding-left: 15px;
          }
        }
        .option {
          margin-top: 10px;
          padding-left: 20px;
        }
      }
    }
    .right {
      position: sticky;
      margin: 20px 20px 0 15px;
      .information-panel {
        display: block;
      }
      .nav-panel {
        // width: 100%;
        position: unset;
        bottom: unset;
        .panel-heading {
          // position: relative;
          .mobile {
            display: none;
          }
        }
        .panel-body {
          height: 420px;
          button {
            padding: 6px 12px;
          }
        }
      }
    }
  }
}
</style>