/**
 * 把文件按照二进制进行读取
 * @param file
 * @returns
 */
export function readFile(file) {
    return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsText(file, 'GB2312');
        reader.onload = ev => {
            resolve(ev.target.result);
        }
    });
}