export default function jsonToExcel(data, head, fileName = "错题本") {
    let str = head ? head + "\n" : "";
    data.forEach((item) => {
        str = `${str + item.type},`;
        str = `${str + item.content},`;
        if (item.type === "判断题") {
            str = `${str + "对"},`;
            str = `${str + "错"},`;
            str = `${str},`;
            str = `${str},`;
        } else {
            str = `${str + item.optionA},`;
            str = `${str + item.optionB},`;
            str = `${str + item.optionC},`;
            str = `${str + item.optionD},`;
        }
        str = `${str + item.answer},`;
        str += "\n";
    });
    const url =
        "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
    // 通过创建a标签实现
    const link = document.createElement("a");
    link.href = url;
    // 对下载的文件命名
    link.download = `${fileName + ".csv"}`;
    link.click();
}