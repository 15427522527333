<template>
  <div id="app">
    <component :is="showHome ? 'Home' : ''" @toExercise="toExer"></component>
    <keep-alive>
      <component
        :is="!showHome ? comName : ''"
        @toggleCom="show"
        :queIndex="queIndex"
        :que="que_ban"
        :nameFromEx="examName"
        :quenum="quenum"
      ></component>
    </keep-alive>
  </div>
</template>

<script>
import Exercise from "@/components/Exercise";
import Review from "@/components/Review.vue";
import Home from "@/components/Home.vue";
export default {
  name: "App",
  components: {
    Exercise,
    Review,
    Home,
  },
  data() {
    return {
      comName: "Exercise",
      que_ban: [],
      showHome: true,
      queIndex: 2,
      examName: "",
      quenum: "all",
    };
  },
  methods: {
    show(que, examName) {
      this.examName = examName;
      this.que_ban = que;
      if (this.comName === "Exercise") {
        this.comName = "Review";
      } else {
        this.comName = "Exercise";
      }
    },
    toExer(index, quenum, que) {
      this.queIndex = index;
      this.quenum = quenum;
      this.que_ban = que;
      this.showHome = !this.showHome;
    },
  },
};
</script>

<style>
</style>