<template>
  <div>
    <div class="container">
      <h2>请选择练习题库</h2>
      <!-- <input type="text" id="quenum" v-model="quenum" /> -->
      <div class="quenum">
        <span>请选择题目数量：</span>
        <select v-model="quenum">
          <option :value="'all'">All</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
          <option :value="75">75</option>
          <option :value="100">100</option>
          <option :value="150">150</option>
          <option :value="200">200</option>
          <option :value="300">300</option>
          <option :value="400">400</option>
          <option :value="500">500</option>
        </select>
      </div>

      <div>
        <button @click="toMiddle">中级工</button>
      </div>
      <div>
        <button @click="toSenior">高级工</button>
      </div>
      <input type="file" id="ipt" @change="onChange($event)" />
      <div>
        <button @click="startExam">导入题库</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as xlsx from "xlsx/xlsx.mjs";
import { readFile } from "@/utile/xlsx";
export default {
  name: "Home",
  data() {
    return {
      question: [],
      quenum: "all",
    };
  },
  methods: {
    // 导入题库并读取
    async onChange(e) {
      let file = e.target.files[0];
      if (file.name.substr(file.name.length - 3) !== "csv") {
        return alert("请导入正确格式的文件\n只支持csv格式");
      }
      let dataBinary = await readFile(file);
      let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
      let workSheet = workBook.Sheets[workBook.SheetNames[0]];
      const data = xlsx.utils.sheet_to_json(workSheet);
      if (
        this.quenum === "all" ||
        (this.quenum !== "all" && data.length <= this.quenum)
      ) {
        this.dataHandle(data);
        this.question = data;
      } else {
        let tempArr = [];
        for (let i = 0; i < this.quenum; i++) {
          tempArr.push(data[i]);
        }
        this.dataHandle(tempArr);
        this.question = tempArr;
      }
      this.$emit("toExercise", 3, this.question.length, this.question);
    },

    //对导入的题库进行处理
    dataHandle(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].id = i + 1;
        data[i].checked = 0;
        data[i].marked = 0;
        data[i].state = 0;
        switch (data[i].answer) {
          case "A":
            data[i].answercode = 1000;
            break;
          case "B":
            data[i].answercode = 100;
            break;
          case "C":
            data[i].answercode = 10;
            break;
          case "D":
            data[i].answercode = 1;
            break;
          case "AB":
            data[i].answercode = 1100;
            break;
          case "AC":
            data[i].answercode = 1010;
            break;
          case "AD":
            data[i].answercode = 1001;
            break;
          case "BC":
            data[i].answercode = 110;
            break;
          case "BD":
            data[i].answercode = 101;
            break;
          case "CD":
            data[i].answercode = 11;
            break;
          case "ABC":
            data[i].answercode = 1110;
            break;
          case "ABD":
            data[i].answercode = 1101;
            break;
          case "ACD":
            data[i].answercode = 1011;
            break;
          case "BCD":
            data[i].answercode = 111;
            break;
          case "ABCD":
            data[i].answercode = 1111;
        }
      }
    },

    startExam() {
      document.querySelector("#ipt").click();
    },

    toMiddle() {
      this.$emit("toExercise", 1, this.quenum);
    },

    toSenior() {
      this.$emit("toExercise", 2, this.quenum);
    },
  },
};
</script>

<style lang="less" scoped>
@media (max-width: 375px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 376px) {
  .container {
    width: 375px;
  }
}
.container {
  // width: 550px;
  text-align: center;
  margin: 180px auto;
  h2 {
    margin-bottom: 60px;
  }
  .quenum {
    margin: 20px auto;
    select {
      width: 12%;
      text-align: center;
      margin-left: 15px;
      outline: none;
    }
  }
  button {
    cursor: pointer;
    width: 80%;
    background-color: #337ab7;
    color: white;
    line-height: 20px;
    padding: 6px 12px;
    margin: 20px 10px;
    border-radius: 5px;
    border: 1px solid #2e6da4;
    outline: none;
    box-sizing: border-box;
  }
  #ipt {
    display: none;
  }
}
</style>