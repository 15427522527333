<template>
  <div>
    <div class="title">考试详情<i @click="close">✖</i></div>
    <div class="header">
      <!-- 考试名称 -->
      <h4 id="examName">
        {{ examName }}
      </h4>
      <div>
        <label>
          <input type="checkbox" v-model="onlyShowWrong" />
          <span> 只看错题与标记题目 </span>
        </label>
        <span>共 {{ questionCount }} 题</span>
        <button @click="exportExam">导出</button>
      </div>
    </div>
    <div class="container">
      <!-- 题目内容部分 -->
      <div class="content" ref="content">
        <h3>{{ examName }}</h3>
        <div v-for="(item, index) in que_ban" :key="item.id">
          <!-- 显示全部题目 -->
          <div v-if="!onlyShowWrong">
            <!-- 判断题目开始 -->
            <div
              :id="'que_' + index"
              class="body"
              v-if="item.type === '判断题'"
            >
              <div class="topic">
                <span
                  >{{ index + 1 }}.({{ item.type }}) {{ item.content }}</span
                >
              </div>
              <div class="option">A.对</div>
              <div class="option" style="margin-bottom: 10px">B.错</div>
              <div class="answer">
                <b>参考答案：{{ item.answer === "A" ? "对" : "错" }}</b>
              </div>
              <div class="answer">
                <b>考生答案：</b>
                <b v-if="item.checked === '1000'">对</b>
                <b v-else-if="item.checked === '100'">错</b>
                <b v-else> </b>
              </div>
            </div>
            <!-- 判断题目结束 -->
            <!-- 单选题目开始 -->
            <div
              :id="'que_' + index"
              class="body"
              v-if="item.type === '单选题'"
            >
              <div class="topic">
                <span
                  >{{ index + 1 }} . ({{ item.type }}) {{ item.content }}</span
                >
              </div>
              <div class="option">A.{{ item.optionA }}</div>
              <div class="option">B.{{ item.optionB }}</div>
              <div class="option">C.{{ item.optionC }}</div>
              <div class="option" style="margin-bottom: 10px">
                D.{{ item.optionD }}
              </div>
              <div class="answer">
                <b>参考答案：{{ item.answer }}</b>
              </div>
              <div class="answer">
                <b>考生答案：</b>
                <b v-if="item.checked === '1000'">A</b>
                <b v-else-if="item.checked === '100'">B</b>
                <b v-else-if="item.checked === '10'">C</b>
                <b v-else-if="item.checked === '1'">D</b>
                <b v-else> </b>
              </div>
            </div>
            <!-- 单选题目结束 -->
            <!-- 多选题目开始 -->
            <div
              :id="'que_' + index"
              class="body"
              v-if="item.type === '多选题'"
            >
              <div class="topic">
                <span
                  >{{ index + 1 }} . ({{ item.type }}) {{ item.content }}</span
                >
              </div>
              <div class="option">A.{{ item.optionA }}</div>
              <div class="option">B.{{ item.optionB }}</div>
              <div class="option">C.{{ item.optionC }}</div>
              <div class="option" style="margin-bottom: 10px">
                D.{{ item.optionD }}
              </div>
              <div class="answer">
                <b>参考答案：{{ item.answer }}</b>
              </div>
              <div class="answer">
                <b>考生答案：</b>
                <b v-if="item.checked === 1111">ABCD</b>
                <b v-else-if="item.checked === 1110">ABC</b>
                <b v-else-if="item.checked === 1101">ABD</b>
                <b v-else-if="item.checked === 1011">ACD</b>
                <b v-else-if="item.checked === 111">BCD</b>
                <b v-else-if="item.checked === 1100">AB</b>
                <b v-else-if="item.checked === 1010">AC</b>
                <b v-else-if="item.checked === 1001">AD</b>
                <b v-else-if="item.checked === 110">BC</b>
                <b v-else-if="item.checked === 101">BD</b>
                <b v-else-if="item.checked === 11">CD</b>
                <b v-else> </b>
              </div>
            </div>
            <!-- 多选题目结束 -->
          </div>
          <!-- 只显示错题 -->
          <div v-else>
            <!-- 判断题目开始 -->
            <div
              :id="'que_' + index"
              class="body"
              v-if="(item.state !== 1 || item.marked) && item.type === '判断题'"
            >
              <div class="topic">
                <span
                  >{{ index + 1 }}.({{ item.type }}) {{ item.content }}</span
                >
              </div>
              <div class="option">A.对</div>
              <div class="option" style="margin-bottom: 10px">B.错</div>
              <div class="answer">
                <b>参考答案：{{ item.answer === "A" ? "对" : "错" }}</b>
              </div>
              <div class="answer">
                <b>考生答案：</b>
                <b v-if="item.checked === '1000'">对</b>
                <b v-else-if="item.checked === '100'">错</b>
                <b v-else> </b>
              </div>
            </div>
            <!-- 判断题目结束 -->
            <!-- 单选题目开始 -->
            <div
              :id="'que_' + index"
              class="body"
              v-if="(item.state !== 1 || item.marked) && item.type === '单选题'"
            >
              <div class="topic">
                <span
                  >{{ index + 1 }} . ({{ item.type }}) {{ item.content }}</span
                >
              </div>
              <div class="option">A.{{ item.optionA }}</div>
              <div class="option">B.{{ item.optionB }}</div>
              <div class="option">C.{{ item.optionC }}</div>
              <div class="option" style="margin-bottom: 10px">
                D.{{ item.optionD }}
              </div>
              <div class="answer">
                <b>参考答案：{{ item.answer }}</b>
              </div>
              <div class="answer">
                <b>考生答案：</b>
                <b v-if="item.checked === '1000'">A</b>
                <b v-else-if="item.checked === '100'">B</b>
                <b v-else-if="item.checked === '10'">C</b>
                <b v-else-if="item.checked === '1'">D</b>
                <b v-else> </b>
              </div>
            </div>
            <!-- 单选题目结束 -->
            <!-- 多选题目开始 -->
            <div
              :id="'que_' + index"
              class="body"
              v-if="(item.state !== 1 || item.marked) && item.type === '多选题'"
            >
              <div class="topic">
                <span
                  >{{ index + 1 }} . ({{ item.type }}) {{ item.content }}</span
                >
              </div>
              <div class="option">A.{{ item.optionA }}</div>
              <div class="option">B.{{ item.optionB }}</div>
              <div class="option">C.{{ item.optionC }}</div>
              <div class="option" style="margin-bottom: 10px">
                D.{{ item.optionD }}
              </div>
              <div class="answer">
                <b>参考答案：{{ item.answer }}</b>
              </div>
              <div class="answer">
                <b>考生答案：</b>
                <b v-if="item.checked === 1111">ABCD</b>
                <b v-else-if="item.checked === 1110">ABC</b>
                <b v-else-if="item.checked === 1101">ABD</b>
                <b v-else-if="item.checked === 1011">ACD</b>
                <b v-else-if="item.checked === 111">BCD</b>
                <b v-else-if="item.checked === 1100">AB</b>
                <b v-else-if="item.checked === 1010">AC</b>
                <b v-else-if="item.checked === 1001">AD</b>
                <b v-else-if="item.checked === 110">BC</b>
                <b v-else-if="item.checked === 101">BD</b>
                <b v-else-if="item.checked === 11">CD</b>
                <b v-else> </b>
              </div>
            </div>
            <!-- 多选题目结束 -->
          </div>
        </div>
      </div>
      <!-- 试卷导航部分开始 -->
      <div class="nav-panel" ref="nav">
        <div class="panel-heading" @click.self="show_nav">
          <button class="mobile" @click="close">退出</button>
          试卷导航
          <button class="mobile" @click="exportExam">导出</button>
        </div>
        <div class="panel-body">
          <div style="padding: 10px 5px 0px">
            <p>
              <span>{{ examName }}</span>
            </p>
          </div>
          <div class="sele_btn">
            <button
              v-for="(item, index) in que_ban"
              :key="item.id"
              :class="{
                marked: item.marked,
                right: item.state === 1,
                wrong: item.state === 2,
              }"
              @click="jump(index)"
            >
              {{ index + 1 }}
            </button>
          </div>
        </div>
      </div>
      <!-- 试卷导航部分结束 -->
    </div>
  </div>
</template>

<script>
import jsonToExcel from "@/utile/jsonToExcel";
export default {
  name: "Review",

  props: {
    que: {
      type: Array,
      default: [],
    },
    nameFromEx: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      examName: "",
      que_ban: [],
      onlyShowWrong: true,
      wrongQue: [],
      isShowNav: true,
    };
  },

  computed: {
    questionCount() {
      if (this.onlyShowWrong) {
        return this.que_ban.filter((item) => item.state !== 1 || item.marked)
          .length;
      } else {
        return this.que_ban.length;
      }
    },
  },

  methods: {
    jump(index) {
      document
        .querySelector("#que_" + index)
        .scrollIntoView({ behavior: "smooth" });
    },

    close() {
      this.$emit("toggleCom");
    },

    exportExam() {
      if (this.onlyShowWrong) {
        this.wrongQue = this.que_ban.filter(
          (item) => item.state !== 1 || item.marked
        );
      } else {
        this.wrongQue = this.que_ban;
      }
      jsonToExcel(
        this.wrongQue,
        "type,content,optionA,optionB,optionC,optionD,answer"
      );
    },

    // 显示与隐藏移动端页面底部的“试卷导航”
    show_nav() {
      if (window.matchMedia("(max-width: 600px)").matches) {
        if (this.isShowNav) {
          this.$refs.nav.style.bottom = "-145px";
          this.$refs.content.style.marginBottom = "35px";
          this.isShowNav = !this.isShowNav;
        } else {
          this.$refs.nav.style.bottom = 0;
          this.$refs.content.style.marginBottom = "180px";
          this.isShowNav = !this.isShowNav;
        }
      }
    },
  },

  created() {
    this.que_ban = this.que;
    this.examName = this.nameFromEx;
  },

  mounted() {
    setTimeout(() => {
      if (window.matchMedia("(max-width: 600px)").matches) {
        this.$refs.nav.style.bottom = "-145px";
        this.$refs.content.style.marginBottom = "35px";
        this.isShowNav = false;
      }
    }, 1000);
  },

  activated() {
    window.scrollTo({ top: 0 }); // 切换组件时跳转到最顶部
  },
};
</script>

<style lang="less" scoped>
.title {
  position: fixed;
  top: 0;
  width: 100%;
  height: 42px;
  background: #4476a7;
  color: #fff;
  font-size: 14px;
  line-height: 42px;
  padding: 0 80px 0 20px;
  // overflow: hidden;
  i {
    cursor: pointer;
    position: fixed;
    right: 40px;
    font-style: normal;
  }
}
.header {
  // height: 100px;
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
  text-align: center;
  border-bottom: 6px solid #36a9e1;
  box-sizing: border-box;
  margin-top: 50px;
  #examName {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 80%;
    font-size: 16px;
    // margin: 25px auto 0;
    button {
      background-color: #4476a7;
      color: #fff;
      padding: 6px 12px;
      margin: 0px 60px;
      border-radius: 4px;
      border: none;
    }
  }
}
.container {
  // display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.content {
  // width: 65%;
  font-size: 14px;
  // margin: 20px 15px 20px 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  h3 {
    background-color: #f9fafd;
    font-size: 16px;
    font-weight: 400;
    color: inherit;
    padding: 10px 15px;
    margin: 0;
    border-bottom: 1px solid #ddd;
  }
  .body {
    padding: 10px 10px 5px;
    .topic {
      span {
        display: inline-block;
        // width: 85%;
        // padding-right: 15px;
        // padding-left: 15px;
      }
    }
    .option {
      margin-top: 10px;
      padding-left: 40px;
    }
    .answer {
      padding: 5px 40px 0px;
    }
  }
}
.nav-panel {
  // position: sticky;
  // top: 50px;
  // width: 31%;
  background: white;
  font-size: 14px;
  // margin: 20px 20px 0 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  .panel-body {
    // height: 420px;
    overflow: auto;
  }
  p {
    margin: 0 0 10px;
  }
  button {
    cursor: pointer;
    line-height: 20px;
    // padding: 6px 12px;
    margin: 2px 5px;
    border-radius: 4px;
    border: 1px solid transparent;
  }
  .right {
    background-color: #99ee6a;
  }
  .marked {
    background-color: yellow;
  }
  .wrong {
    background-color: red;
  }
}
.panel-heading {
  background-color: #f9fafd;
  font-weight: 400;
  color: inherit;
  padding: 4px 15px;
  text-align: center;
  margin: 0;
  border-bottom: 1px solid #ddd;
  .mobile {
    display: none;
  }
}

@media (max-width: 600px) {
  div:first-child {
    overflow: hidden;
  }
  .title {
    i {
      display: none;
      // right: 20px;
    }
  }
  .header {
    height: 80px;
    div {
      width: 92%;
      margin: 0 auto;
      button {
        display: none;
      }
    }
  }
  .container {
    display: block;
    .content {
      width: 99%;
      margin: 5px auto 180px;
      // margin: 5px auto 35px;

      .body {
        .topic {
          span {
            // width: 100%;
            padding-right: 8px;
            padding-left: 0;
          }
        }
        .option {
          padding-left: 10px;
        }
        .answer {
          padding-left: 10px;
        }
      }
    }
    .nav-panel {
      position: fixed;
      // width: 100%;
      top: unset;
      bottom: 0;
      // bottom: -145px;
      margin: 0;
      transition: all 0.5s;
      .panel-heading .mobile {
        display: inline;
        position: absolute;
        right: 20px;
        background-color: #337ab7;
        color: #fff;
        height: 28px;
        padding: 2px 6px;
        margin: -4px;
        border-color: #337ab7;
        outline: none;
      }
      .mobile:first-child {
        left: 20px;
      }
      .panel-body {
        height: 145px;
        button {
          padding: 4px 10px;
        }
      }
    }
  }
}

@media (min-width: 601px) {
  .header {
    height: 100px;
    div {
      width: 80%;
      margin: 25px auto 0;
      button {
        display: inline;
      }
    }
  }
  .container {
    display: flex;
    .content {
      width: 65%;
      margin: 20px 15px 20px 20px;
      .body {
        .topic {
          span {
            width: 85%;
            vertical-align: unset;
            padding-right: 15px;
            padding-left: 15px;
          }
        }
        .option {
          padding-left: 40px;
        }
        .answer {
          padding-left: 40px;
        }
      }
    }
    .nav-panel {
      position: sticky;
      width: 31%;
      top: 50px;
      bottom: unset;
      margin: 20px 20px 0 15px;
      .panel-heading .mobile {
        display: none;
        // position: absolute;
        // right: 20px;
        // background-color: #337ab7;
        // color: #fff;
        // height: 28px;
        // padding: 2px 6px;
        // margin: -4px;
        // outline: none;
      }
      .panel-body {
        height: 420px;
        button {
          padding: 6px 12px;
        }
      }
    }
  }
}
</style>